import React, {useEffect, useRef} from "react"
import {connect} from "react-redux"
import {setProductToCompare, spliceFromComparingList} from "../../../store/main/mainActions";

const mapStateToProps = (state) => ({
  productsList: state.main.productsList,
  pharmaciesComparingList: state.main.pharmaciesComparingList
})

const mapDispatchToProps = ({
  setProductToCompare, spliceFromComparingList
})

const Table$ = (props) => {

  const products = props.productsList
  const comparingList = props.pharmaciesComparingList
  const wrapper = useRef(null)

  useEffect(() => {
    if (!wrapper.current) return
    wrapper.current.scrollLeft = wrapper.current.scrollWidth;
  }, [comparingList])

  const productModalHandler = (product) => {
    return () => {
      props.setProductToCompare(product)
    }
  }

  const spliceFromCompareHandler = (i) => {
    return () => props.spliceFromComparingList(i)
  }

  return (
    <div ref={wrapper} className="tableWrapper scrollBar">
      <table className="table pharmacyTable w-100p">
        <thead>
        <tr>
          <th className="voidCell"/>
          <th>!!!Code</th>
          <th>!!!Name</th>
          <th>!!!Details</th>
          {comparingList.map((item, i) => (
            <th onClick={spliceFromCompareHandler(i)} key={item?.name}>{item?.name}</th>
          ))}
          <th className="voidCell"/>
        </tr>
        </thead>
        <tbody>
        {products && products.map((product, i) => ([
          <tr key={product.artCode}>
            <td className="voidCell d-flex jc-fe">
              {!(i % 2) && <div className="voidBlockLeft"/>}
            </td>
            <td>{product.artCode}</td>
            <td>{product.nameSKU911}</td>
            <td>
              <button className="button buttonGreen" onClick={productModalHandler(product)}>!!!All pharmacies</button>
            </td>
            {comparingList.map(item => (
              <td key={item?.name}>
                {item?.list?.[i]?.price ? `${item?.list?.[i]?.price} грн` : null}
                <br/>
                {item?.list?.[i]?.partibilityFactor ? `${item?.list?.[i]?.partibilityFactor} шт.` : null}
              </td>
            ))}
            <td className="voidCell">
              {!(i % 2) && <div className="voidBlock"/>}
            </td>
          </tr>,
          <tr className="voidRow" key={`void${product.id}`}>
            <td colSpan={5}/>
          </tr>
        ]))}
        </tbody>
      </table>
    </div>
  )
}

const Table = connect(mapStateToProps, mapDispatchToProps)(Table$)

export default Table