import React from "react"
import {connect} from "react-redux"
import PharmaciesList from "./PharmaciesList/PharmaciesList";
import PharmacyInfo from "./PharmacyInfo/PharmacyInfo";
import './Pharmacies.css'

const mapStateToProps = (state) => ({
  selectedPharmacy: state.main.selectedPharmacy
})

const mapDispatchToProps = ({})

const Pharmacies$ = (props) => {

  return (
    <>
      { !props.selectedPharmacy && <PharmaciesList /> }
      { props.selectedPharmacy && <PharmacyInfo /> }
    </>
  )
}

const Pharmacies = connect(mapStateToProps, mapDispatchToProps)(Pharmacies$)

export default Pharmacies