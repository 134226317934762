import React, {useEffect, useState} from "react"
import {connect} from "react-redux"
import {setUser} from "../../store/main/mainActions";
import {getUserData, updateUserSettings} from "../../store/requests";
import ContentHeader from "../Content/ContentHeader/ContentHeader";

const mapStateToProps = (state) => ({
  user: state.main.user,
  settingsList: state.main.settingsList,
  lang: state.main.lang,
  langBase: state.main.langBase
})

const mapDispatchToProps = ({
  setUser
})

const Settings$ = (props) => {

  const s = (key) => {
    return props.langBase[key][props.lang]
  }

  const settings = props.settingsList
  const userData = props.user

  useEffect(() => {
    // console.log(userData)
    setUserSettings({
      languageId: userData.language.id,
      themeId: userData.theme.id,
      partnerNameRU: userData.user.partnerNameRU,
      partnerNameUA: userData.user.partnerNameUA,
      partnerCEO: userData.user.partnerCEO,
      bookingTimeInHours: userData.user.bookingTimeInHours,
      login: userData.user.login,
    })
  }, [])   // eslint-disable-line

  const [userSettings, setUserSettings] = useState({
    languageId: '',
    themeId: '',
    partnerNameRU: '',
    partnerNameUA: '',
    partnerCEO: '',
    login: '',
    bookingTimeInHours: '',
  })

  const [newPassword, setNewPassword] = useState({
    password1: '',
    password2: ''
  })

  const editHandler = (key) => {
    return (e) => {
      let obj = Object.assign({}, userSettings)
      obj[key] = e.target.value
      setUserSettings(obj)
    }
  }

  const passwordEditHandler = (key) => {
    return (e) => {
      let obj = Object.assign({}, newPassword)
      obj[key] = e.target.value
      setNewPassword(obj)
    }
  }

  const saveHandler = async () => {
    // console.log(userSettings)
    let params = Object.assign({}, userSettings)
    // params.login = userSettings.partnerCEOLogin
    // console.log(params)
    let result = await updateUserSettings(params)
    if (result.error) return
    props.setUser( await getUserData() )
    alert(s('Saved successfully'))
  }

  const savePasswordHandler = async () => {
    let { password1, password2 } = newPassword
    if (password1.length < 6) {
      alert(s('Password must be at least 6 characters'))
      return
    }
    if (password1 !== password2) {
      alert(s('Passwords do not match'))
      return
    }
    let result = await updateUserSettings({ passwordNew: password1 })
    if (result.error) return
    props.setUser( await getUserData() )
    alert(s('Saved successfully'))
    // console.log(newPassword)
  }

  // const logOutHandler = () => {
  //   localStorage.removeItem('marketplace911Token')
  //   props.setUser(null)
  // }

  return (
    <div className="wrapper d-flex fd-column">
      <ContentHeader>
        {s('Partner settings page')} {userData.user.partnerNameRU} {s('on the LIKI website')}
      </ContentHeader>

      {/*<div onClick={logOutHandler} className="button hover">{s('Log out')}</div>*/}

      <p className="title mt-12 ml-13">{s('User Settings')}</p>

      <p className="mt-12 ml-13">{s('Interface language')}</p>
      <select
        className="select ml-13"
        value={userSettings.languageId}
        onChange={editHandler('languageId')}
      >
        {settings.languages.map(item => (
          <option value={item.id} key={item.id}>{item.descr}</option>
        ))}
      </select>

      <p className="mt-12 ml-13">{s('Interface Theme')}</p>
      <select
        className="select ml-13"
        value={userSettings.themeId}
        onChange={editHandler('themeId')}
      >
        {settings.themes.map(item => (
          <option value={item.id} key={item.id}>{item.descr}</option>
        ))}
      </select>

      <p className="mt-12 ml-13">{s('Company name')} ({s('basic')})</p>
      <input
        autoComplete="off"
        className="input ml-13"
        type="text"
        value={userSettings.partnerNameRU || ''}
        onChange={editHandler('partnerNameRU')}
      />

      <p className="mt-12 ml-13">{s('Company name')} (UA)</p>
      <input
        autoComplete="off"
        className="input ml-13"
        type="text"
        value={userSettings.partnerNameUA || ''}
        onChange={editHandler('partnerNameUA')}
      />

      <p className="mt-12 ml-13">{s('Booking period')}</p>
      <input
        autoComplete="off"
        className="input ml-13"
        type="text"
        value={userSettings.bookingTimeInHours || ''}
        onChange={editHandler('bookingTimeInHours')}
      />

      <p className="mt-12 ml-13">CEO</p>
      <input
        autoComplete="off"
        className="input ml-13"
        type="text"
        value={userSettings.partnerCEO || ''}
        onChange={editHandler('partnerCEO')}
      />

      <p className="mt-12 ml-13">Login</p>
      <input
        autoComplete="off"
        className="input ml-13"
        type="text"
        value={userSettings.login || ''}
        onChange={editHandler('login')}
      />

      <div onClick={saveHandler} className="button hover mt-12 ml-13 bg-blue3">{s('Save')}</div>




      <p className="title mt-50 ml-13">{s('Password change')}</p>

      <p className="mt-12 ml-13">{s('Enter a new password')}</p>
      <input
        autoComplete="off"
        className="input ml-13"
        type="password"
        value={newPassword.password1}
        onChange={passwordEditHandler('password1')}
      />

      <p className="mt-12 ml-13">{s('Confirm new password')}</p>
      <input
        autoComplete="off"
        className="input ml-13"
        type="password"
        value={newPassword.password2}
        onChange={passwordEditHandler('password2')}
      />

      <div onClick={savePasswordHandler} className="button hover mt-12 ml-13 bg-blue3">{s('Change password')}</div>

    </div>
  )
}

const Settings = connect(mapStateToProps, mapDispatchToProps)(Settings$)

export default Settings