import React, {useEffect, useState} from "react"
import {connect} from "react-redux"
import {setPharmacy, updatePharmacy, setPreload} from "../../../store/main/mainActions";
import {getPharmaciesList, postNewPharmacy, updatePharmacyInfo} from "../../../store/requests";
import {getNewPharmacyTemplate, parseDataToSend} from "../../../store/helpers";
import {pharmacyKeys} from "../../../store/map";
import ContentHeader from "../../Content/ContentHeader/ContentHeader";
import Schedule from "./Schedule/Schedule";
import City from "./City/City";
import Brand from "./Brand/Brand";

const mapStateToProps = (state) => ({
  lang: state.main.lang,
  langBase: state.main.langBase,
  selectedPharmacy: state.main.selectedPharmacy,
  user: state.main.user
})

const mapDispatchToProps = ({
  setPharmacy, updatePharmacy, setPreload
})

const PharmacyInfo$ = (props) => {

  const s = (key) => {
    return props.langBase[key][props.lang]
  }

  const pharmacy = props.selectedPharmacy
  const [isNew, toggleNew] = useState(false)

  const onLoad = async () => {
    if (pharmacy === 'new') {
      toggleNew(true)
      props.setPharmacy(getNewPharmacyTemplate())
    }
    toggleLoading(false)
  }

  useEffect(() => {
    onLoad().then(null)
  }, [])   // eslint-disable-line

  const [isLoading, toggleLoading] = useState(true)

  const backHandler = () => {
    props.setPharmacy(null)
  }

  const inputHandler = (key) => {
    return (e) => props.updatePharmacy(key, e.target.value)
  }

  const saveHandler = async () => {
    let params = parseDataToSend(pharmacy)
    let response
    delete params.id
    delete params.partnerId

    if (+props.user.user.partnerId >= 216) {
      params.pharmacyId = `${props.user.user.partnerId}${params.pointId?.toString()?.padStart(6, '0')}`
    }


    // console.log(params)
    // return

    if (isNew) {
      response = await postNewPharmacy(params)
    } else {
      response = await updatePharmacyInfo(pharmacy.id, params)
    }
    let list = await getPharmaciesList()
    props.setPreload([list])
    if (response.error) alert('Saving error')
    else alert(s('Saved successfully'))
  }

  return (
    <div className="wrapper d-flex fd-column">

      <ContentHeader>
        {isNew && s('Add pharmacy')}
        {!isNew && `${s('Pharmacy settings')}: ${pharmacy.pharmacyNameRU}`}
        <div onClick={backHandler} className="button hover ml-20">{s('Back')}</div>
      </ContentHeader>

      {/*<div className="contentHeader jc-sb">*/}
      {/*  <p>!!!INFO {pharmacy.pharmacyNameRU}</p>*/}
      {/*</div>*/}

      <div className="contentWrapper pb-16">
        {isLoading && <p>loading</p>}
        {!isLoading && Object.keys(pharmacyKeys).map(key => {
          // if (key === '_default') return null
          if (key === '_default' || pharmacyKeys[key].inputType === 'time') return null
          return (
            <div
              className={`pharmacyEditBlock ${pharmacyKeys[key].isRequired ? "pharmacyEditRequired" : ""}`}
              key={key}
            >
              <p>{pharmacyKeys[key].title}</p>
              {
                pharmacyKeys[key].inputType === 'select'
                  ?
                  <select className="input" value={pharmacy[key]} onChange={inputHandler(key)}>
                    <option value="null">---</option>
                    <option value="0">false</option>
                    <option value="1">true</option>
                  </select>
                  :
                  pharmacyKeys[key].inputType === 'city'
                    ?
                    <City />
                  :
                  pharmacyKeys[key].inputType === 'brand'
                    ?
                    <Brand />
                    :
                    <input
                      placeholder="Enter a value"
                      className="input"
                      value={pharmacy[key] || ''}
                      disabled={(key === 'id' || key === 'pharmacyId')}
                      onChange={inputHandler(key)}
                      type={pharmacyKeys[key].inputType}
                    />
              }
            </div>
          )
        })}

          <Schedule />

          </div>

          <div className="infoBottomBar">
          <div onClick={saveHandler} className="button hover bg-blue3">{s('Save')}</div>
          </div>


          </div>
          )
        }

const PharmacyInfo = connect(mapStateToProps, mapDispatchToProps)(PharmacyInfo$)

export default PharmacyInfo