import { combineReducers } from "redux";
import { createStore } from "redux";
import mainReducer from "./main/mainReducer";
import bundleReducer from "./bundle/bundleReducer";

const store = createStore(combineReducers({
  main: mainReducer,
  bundle: bundleReducer,
}))
export default store
