import React from "react"
import { connect } from "react-redux"
import NavBar from "../NavBar/NavBar";
import { setOrderInfoMode } from "../../../store/main/mainActions";

const mapStateToProps = (state) => ({
  orderInfoMode: state.main.orderInfoMode,
  lang: state.main.lang,
  langBase: state.main.langBase
})

const mapDispatchToProps = ({
  setOrderInfoMode
})

const OrderInfoBar$ = (props) => {

  const s = (key) => {
    return props.langBase[key][props.lang]
  }

  const mode = props.orderInfoMode

  const getButtonClass = (button) => {
    if (mode === button) return 'navButtonActive'
    else return 'hover'
  }

  const selectHandler = (mode) => {
    return () => props.setOrderInfoMode(mode)
  }

  return(
    <NavBar>
      <button
        className={`navButton ${getButtonClass('details')}`}
        onClick={selectHandler('details')}
      >{s('Commodity items')}</button>
      <button
        className={`navButton ${getButtonClass('history')}`}
        onClick={selectHandler('history')}
      >{s('Operations history')}</button>
    </NavBar>
  )
}

const OrderInfoBar = connect(mapStateToProps, mapDispatchToProps)(OrderInfoBar$)

export default OrderInfoBar