import React, { useState } from "react"
import { connect } from "react-redux"
import './Login.css'
import { setUser, changeLang } from "../../store/main/mainActions";
import { getUserData, logInUser } from "../../store/requests";

const mapStateToProps = (state) => ({
  lang: state.main.lang,
  langBase: state.main.langBase
})

const mapDispatchToProps = ({
  setUser, changeLang
})

const Login$ = (props) => {

  const s = (key) => {
    return props.langBase[key][props.lang]
  }

  const [login, setLogin] = useState('')
  const [password, setPassword] = useState('')

  const loginHandler = (e) => {
    setLogin(e.target.value)
  }

  const passwordHandler = (e) => {
    setPassword(e.target.value)
  }

  const submitHandler = async () => {
    let response = await logInUser({ login, password })
    if (response.error) return
    if (!response.token) return
    let user = await getUserData(response.token)
    user.token = response.token
    // console.log(user)
    props.setUser( user )
  }

  const keyHandler = (e) => {
    if (e.which === 13) submitHandler().then()
  }

  return(
    <div className="globalWrapper d-flex fd-column jc-center ai-center">

      <div onClick={props.changeLang} className="langBlock hover">{props?.lang?.toUpperCase()}</div>

      <div className="loginContainer d-flex fd-column">
        <p className="loginHeader">{s('welcome')}</p>
        <p className="mt-12">{s('login')}</p>
        <input value={login} onChange={loginHandler} onKeyDown={keyHandler} type="text"/>
        <p className="mt-12">{s('password')}</p>
        <input value={password} onChange={passwordHandler} onKeyDown={keyHandler} type="password"/>
        <div onClick={submitHandler} className="loginButton hover ta-center mt-12">{s('enter')}</div>
      </div>
    </div>
  )
}

const Login = connect(mapStateToProps, mapDispatchToProps)(Login$)

export default Login