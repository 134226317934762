import React, {useEffect} from "react"
import { connect } from "react-redux"
import Orders from "../Orders/Orders";
import SideBar from "./SideBar/SideBar";
import Settings from "../Settings/Settings";
import Pharmacies from "../Pharmacies/Pharmacies";
import SiteData from "../SiteData/SiteData";
import { setSettingsList, setPreload } from "../../store/main/mainActions";
import { getOuterSystemsList, getPharmaciesList, getBrands, getCities } from "../../store/requests";
import Bundle from "../Bundle/Bundle";
import Faq from "../Faq/Faq";

const mapStateToProps = (state) => ({
  page: state.main.page,
  lang: state.main.lang,
  langBase: state.main.langBase
})

const mapDispatchToProps = ({
  setSettingsList, setPreload
})

const Content$ = (props) => {

  const onLoad = async () => {
    props.setPreload(await Promise.all([
      getPharmaciesList(),
      getOuterSystemsList(),
      getBrands(),
      getCities(),
    ]))
  }

  useEffect( () => {
    onLoad().then()
  }, [] )   // eslint-disable-line

  const s = (key) => {
    return props.langBase[key][props.lang]
  }

  return(
    <div className="globalWrapper d-flex">

      <SideBar />

      { props.page === 'pharmacies' && <Pharmacies /> }
      { props.page === 'dataImport' && <p className="pt-12 pl-12">{s('under development')}</p> }
      { props.page === 'bundle' && <Bundle /> }
      { props.page === 'siteData' && <SiteData /> }
      { props.page === 'reservation' && <Orders /> }
      { props.page === 'settings' && <Settings /> }
      { props.page === 'faq' && <Faq /> }

    </div>
  )
}

const Component = connect(mapStateToProps, mapDispatchToProps)(Content$)

export default Component