import {
  TOGGLE_LOADING,
  HANDLE_ERROR,

  SET_SETTINGS_LIST,
  SET_USER,

  SET_PRELOAD,
  SET_ORDERS_LIST,
  SET_ACTIVE_ORDER,
  SET_ORDER_INFO_MODE,
  SET_ORDER_DETAILS,
  SET_ORDER_HISTORY,
  SET_FILTER,
  SET_PAGE,
  CHANGE_LANG,

  SET_PHARMACY,
  UPDATE_PHARMACY,
  SET_BRANDS,
  SET_CITIES,

  SET_PRODUCTS_LIST,
  SET_PHARMACY_TO_COMPARE,
  UPDATE_COMPARING_LIST,
  SPLICE_FROM_COMPARING_LIST,
  SET_PRODUCT_TO_COMPARE,
} from "./mainConstants";
import { langBase } from "../langBase";

import {parseDate, getToday, parseDataToRender, parsePharmacySchedule} from "../helpers";

const initState = {
  lang: 'ua',
  langBase: langBase,
  isLoading: false,
  errors: [],
  page: 'pharmacies',
  cities: [],
  brands: [],
  pagesMap: {
    pharmacies: [],
    dataImport: [],
    goods: [
      { page: "bundle", key: "Bundle" },
      { page: "siteData", key: "Data to the site" },
    ],
    reservation: [],
    settings: [],
    faq: [],
  },

  settingsList: undefined,
  user: undefined,

  ordersList: undefined,
  activeOrderId: undefined,
  orderDetails: undefined,
  orderHistory: undefined,
  orderInfoMode: 'details',
  filters: {
    dtBegin: getToday(),
    dtEnd: getToday(),
    orderPhone: '',
    orderId: '',
    orderShipCity: '',
    extId: '',
  },
  pharmaciesList: [],
  outerSystemsList: [],
  selectedPharmacy: null,

  productsList: null,
  pharmacyToCompare: null,
  pharmaciesComparingList: [],
  productCompare: null,
}

const reducer = (state = initState, action) => {
  switch (action.type) {

    case TOGGLE_LOADING: {
      return {...state, isLoading: action.state}
    }

    case HANDLE_ERROR: {
      let errors = state.errors.slice()
      let error = action.error
      console.log(error)
      if (error?.message === 'jwt malformed') return state
      if (error?.status < 400) return state
      if (error === null) {
        errors.forEach(item => item.isClosed = true)
        return {...state, errors}
      }
      if (Number.isInteger(error)) {
        errors[error].isClosed = true
        return {...state, errors}
      }
      error.timestamp = new Date(Date.now()).toISOString()
      errors.push(error)
      return {...state, errors}
    }

    case SET_SETTINGS_LIST: {
      return { ...state, settingsList: action.list }
    }

    case SET_USER: {
      let lang = state.lang
      if (!action.user) return { ...state, user: null }
      if (action.user.error) return { ...state, user: null }
      if (action.user.token) localStorage.setItem('marketplace911Token', action.user.token)
      if (action.user.language.descr) lang = action.user.language.descr.toLowerCase()
      return { ...state, user: action.user, lang: lang }
    }

    case SET_PRELOAD: {
      if (!action.preload) return state
      let pharmacies = action.preload[0].slice()
      pharmacies = parsePharmacySchedule(pharmacies)
      if (!action.preload[1]) {
        return {...state, pharmaciesList: pharmacies}
      }
      let outerSystems = action.preload[1] || state.outerSystemsList
      let brands = action.preload[2]
      let cities = action.preload[3]
      cities.forEach(item => {
        item.code = +item.code
      })
      cities.sort((a,b) => {
        if (a.townUa > b.townUa) return 1
        if (a.townUa < b.townUa) return -1
        return 0
      })
      // console.log(cities)
      return { ...state, pharmaciesList: pharmacies, outerSystemsList: outerSystems, brands, cities }
    }

    case SET_ORDERS_LIST: {
      if (action.list) {
        action.list.forEach( item => {
          item.orderDt = parseDate(item.orderDt)
          item.createDate = parseDate(item.createDate)
          item.closeDate = parseDate(item.closeDate)
          item.orderStatusDate = parseDate(item.orderStatusDate)
          item.pharmAssemblyDate = parseDate(item.pharmAssemblyDate)

          item.pId = item.pharmacy?.id
          item.pPartnerId = item.pharmacy?.partnerId
          item.pPharmacyId = item.pharmacy?.pharmacyId
          item.pPharmacyNameRu = item.pharmacy?.pharmacyNameRu
          item.pPharmacyNameUa = item.pharmacy?.pharmacyNameUa
          item.pPointCode = item.pharmacy?.pointCode
          item.pPointId = item.pharmacy?.pointId

          delete item.pharmacy
        } )
      }
      return { ...state, ordersList: action.list }
    }

    case SET_ACTIVE_ORDER: {
      return { ...state, activeOrderId: action.id }
    }

    case SET_ORDER_INFO_MODE: {
      return { ...state, orderInfoMode: action.mode }
    }

    case SET_ORDER_DETAILS: {
      if (action.details) {
        action.details.forEach( item => {
          item.checkDate = parseDate(item.checkDate)
        } )
      }
      return { ...state, orderDetails: action.details }
    }

    case SET_ORDER_HISTORY: {
      if (action.history) {
        action.history.forEach( item => {
          item.insDate = parseDate(item.insDate)
          item.startDate = parseDate(item.startDate)
          item.endDate = parseDate(item.endDate)
        } )
      }
      return { ...state, orderHistory: action.history }
    }

    case SET_FILTER: {
      let filters = Object.assign({}, state.filters)
      filters[action.key] = action.value
      return { ...state, filters: filters }
    }

    case SET_PAGE: {
      return { ...state, page: action.page }
    }

    case CHANGE_LANG: {
      let list = state.settingsList.languages
      let langIndex = list.findIndex( x => x.descr.toLowerCase() === state.lang )
      let newIndex = langIndex + 1
      if (newIndex === list.length) newIndex = 0
      let newLang = list[newIndex].descr.toLowerCase()
      return { ...state, lang: newLang }
    }

    case SET_PHARMACY: {
      if (action.pharmacy === 'new') return { ...state, selectedPharmacy: 'new' }
      if (!action.pharmacy) return { ...state, selectedPharmacy: null }
      let pharmacy = parseDataToRender(action.pharmacy)
      return { ...state, selectedPharmacy: pharmacy }
    }

    case UPDATE_PHARMACY: {
      let pharmacy = Object.assign({}, state.selectedPharmacy)
      if (action.key !== null) {
        pharmacy[action.key] = action.value
      } else {
        pharmacy = Object.assign({}, pharmacy, action.values)
      }
      return { ...state, selectedPharmacy: pharmacy }
    }

    case SET_BRANDS: {
      return { ...state, brands: action.list }
    }

    case SET_CITIES: {
      return { ...state, cities: action.list }
    }

    case SET_PRODUCTS_LIST: {
      if (action.list?.error) return state
      return { ...state, productsList: action.list }
    }

    case SET_PHARMACY_TO_COMPARE: {
      return { ...state, pharmacyToCompare: action.pharmacy }
    }

    case SET_PRODUCT_TO_COMPARE: {
      return { ...state, productCompare: action.list }
    }

    case UPDATE_COMPARING_LIST: {
      let list = state.pharmaciesComparingList.slice()
      list.push(action.data)
      return { ...state, pharmaciesComparingList: list }
    }

    case SPLICE_FROM_COMPARING_LIST: {
      let list = state.pharmaciesComparingList.slice()
      list.splice(action.index, 1)
      return { ...state, pharmaciesComparingList: list }
    }



    default:
      return state
  }
}

export default reducer
