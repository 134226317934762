import React from "react"
import {connect} from "react-redux"
import {setPharmacy} from "../../../store/main/mainActions";
import ContentHeader from "../../Content/ContentHeader/ContentHeader";
import RadioButton from "../../Content/RadioButton/RadioButton";
import {getPartnerName} from "../../../store/helpers";
import {scheduleCell} from "../../../store/helpers";

const mapStateToProps = (state) => ({
  lang: state.main.lang,
  langBase: state.main.langBase,
  pharmaciesList: state.main.pharmaciesList,
  user: state.main.user,
})

const mapDispatchToProps = ({
  setPharmacy
})

const PharmaciesList$ = (props) => {

  const list = props.pharmaciesList
  const partnerName = getPartnerName(props.user, props.lang)

  const s = (key) => {
    return props.langBase[key][props.lang]
  }

  const selectHandler = (item) => {
    return () => props.setPharmacy(item)
  }

  const getStatusStyle = (item) => {
    let className = "pharmacyStatusIcon"
    if (item.isActive) className += " checkWhiteIcon pharmacyStatusGreen"
    else className += " closeWhiteIcon pharmacyStatusRed"
    return className
  }

  return (
    <div className="wrapper d-flex fd-column">

      <ContentHeader>
        {s('List of pharmacies')}
        <div onClick={selectHandler('new')} className="button hover ml-20">{s('Add')}</div>
      </ContentHeader>

      <div className="tableWrapper">
        <table className="table tableFixed pharmacyTable w-100p">
          <thead>
          <tr>
            {/*<th>#</th>*/}
            <th className="statusCell" />
            <th>ID</th>
            <th>{s("Pharmacy name")}</th>
            <th>{s("Address")}</th>
            {/*<th>!!!phone</th>*/}
            <th>{s("Schedule")}</th>
            <th className="activityCell" />
            <th className="voidCell" />
          </tr>
          </thead>
          <tbody>
          {list.map((item, index) => (
            [
              <tr key={item.id} onClick={selectHandler(item)}>
                <td className="statusCell">
                  <div className={getStatusStyle(item)} />
                </td>
                <td>{item.pointCode}</td>
                <td>
                  {item[`pharmacyName${props.lang.toUpperCase()}`] || item.pharmacyNameRU}
                  <p className="pharmacyTinyTitle">{partnerName}</p>
                </td>
                <td>{item.addressText}</td>
                {/*<td>{item.phones}</td>*/}
                <td className="scheduleCell">{item.schedule.map(item => (
                  <p key={item.dayEnd}>

                    {scheduleCell(item, s)}
                    {/*{s(item.dayStart)} - {s(item.dayEnd)}: {item.scheduleString || s('Day off')}*/}
                  </p>
                ))}</td>
                <td className="activityCell"><RadioButton status={item.isActive} /></td>
                <td className="voidCell">
                  {!(index % 2) && <div className="voidBlock" />}
                </td>
              </tr>,
              <tr className="voidRow" key={`void${item.id}`}><td colSpan={5} /></tr>
            ]
          ))}
          </tbody>
        </table>
      </div>

    </div>
  )
}

const PharmaciesList = connect(mapStateToProps, mapDispatchToProps)(PharmaciesList$)

export default PharmaciesList