import React from 'react';
// import {Modal} from "react-bootstrap";       // TODO Replace bootstrap
import {setModalMessage} from "../../store/bundle/bundleActions";
import {connect} from "react-redux";

const mapStateToProps = (state) => ({
  modalMessage: state.bundle.modalMessage,
})
const mapDispatchToProps = ({
  setModalMessage
})

const $ModalMessage = (props) => {

  if (!props.modalMessage) return null

  const closeHandler = () => {
    props.setModalMessage(null)
  }

  return (
    <div onMouseDown={closeHandler} className="modalWrapper">
      <div onMouseDown={e => e.stopPropagation()} className="modalContainer">
        <p>{props.modalMessage}</p>
      </div>
      {/*<Modal*/}
      {/*    // size="lg"*/}
      {/*    show={!!props.modalMessage}*/}
      {/*    onHide={() => props.setModalMessage('')}*/}
      {/*    aria-labelledby="example-custom-modal-styling-title">*/}
      {/*    <Modal.Body className='modalMessageText'>{props.modalMessage}</Modal.Body>*/}
      {/*</Modal>*/}
    </div>
  );
};

const ModalMessage = connect(mapStateToProps, mapDispatchToProps)($ModalMessage)

export default ModalMessage;