import React from "react"
import { connect } from "react-redux"
import './ContentHeader.css'

const mapStateToProps = (state) => ({
  lang: state.main.lang,
  langBase: state.main.langBase
})

const mapDispatchToProps = ({

})

const ContentHeader$ = (props) => {

  const s = (key) => {
    return props.langBase[key][props.lang]
  }

  const logOutHandler = () => {
    localStorage.removeItem('marketplace911Token')
    window.location.reload()
  }

  return(
    <div className="contentHeader d-flex fd-column w-100p">
      {/*<div className="contentHeaderSearchBlock d-flex jc-fe ai-center">*/}
      {/*  <input*/}
      {/*    placeholder={s("Search in the system")}*/}
      {/*    type="text"*/}
      {/*    className="contentHeaderSearchInput searchIcon w-100p"*/}
      {/*  />*/}
      {/*  <div className="contentHeaderCommonIcon menuIcon ml-14" />*/}
      {/*  <div className="contentHeaderCommonIcon bellIcon ml-14" />*/}
      {/*  <button onClick={logOutHandler} className="button ml-24">{s('Log out')}</button>*/}
      {/*  <div className="contentHeaderUserIcon ml-24" />*/}
      {/*</div>*/}
      <div className="contentHeaderTitle d-flex ai-center">
        {props.children}
        <button onClick={logOutHandler} className="button logOutButton">{s('Log out')}</button>
      </div>
    </div>
  )
}

const ContentHeader = connect(mapStateToProps, mapDispatchToProps)(ContentHeader$)

export default ContentHeader