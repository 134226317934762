export const chars = [
            { code: 128, value: 'Ђ'},
            { code: 129, value: 'Ѓ'},
            { code: 130, value: '‚'},
            { code: 131, value: 'ѓ'},
            { code: 132, value: '„'},
            { code: 133, value: '…'},
            { code: 134, value: '†'},
            { code: 135, value: '‡'},
            { code: 136, value: '€'},
            { code: 137, value: '‰'},
            { code: 138, value: 'Љ'},
            { code: 139, value: '‹'},
            { code: 140, value: 'Њ'},
            { code: 141, value: 'Ќ'},
            { code: 142, value: 'Ћ'},
            { code: 143, value: 'Џ'},
            { code: 144, value: 'Ђ'},
            { code: 145, value: '‘'},
            { code: 146, value: '’'},
            { code: 147, value: '“'},
            { code: 148, value: '”'},
            { code: 149, value: '•'},
            { code: 150, value: '–'},
            { code: 151, value: '—'},
            { code: 152, value: ''},
            { code: 153, value: '™'},
            { code: 154, value: 'љ'},
            { code: 155, value: '›'},
            { code: 156, value: 'њ'},
            { code: 157, value: 'ќ'},
            { code: 158, value: 'ћ'},
            { code: 159, value: 'џ'},
            { code: 160, value: ''},
            { code: 161, value: 'Ў'},
            { code: 162, value: 'ў'},
            { code: 163, value: 'Ј'},
            { code: 164, value: '¤'},
            { code: 165, value: 'Ґ'},
            { code: 166, value: '¦'},
            { code: 167, value: '§'},
            { code: 168, value: 'Ё'},
            { code: 169, value: '©'},
            { code: 170, value: 'Є'},
            { code: 171, value: '«'},
            { code: 172, value: '¬'},
            { code: 173, value: ''},
            { code: 174, value: '®'},
            { code: 175, value: 'Ї'},
            { code: 176, value: '°'},
            { code: 177, value: '±'},
            { code: 178, value: 'І'},
            { code: 179, value: 'і'},
            { code: 180, value: 'ґ'},
            { code: 181, value: 'µ'},
            { code: 182, value: '¶'},
            { code: 183, value: '·'},
            { code: 184, value: 'Ё'},
            { code: 185, value: '№'},
            { code: 186, value: 'Є'},
            { code: 187, value: '»'},
            { code: 188, value: 'ј'},
            { code: 189, value: 'Ѕ'},
            { code: 190, value: 'Ѕ'},
            { code: 191, value: 'Ї'},
            { code: 192, value: 'А'},
            { code: 193, value: 'Б'},
            { code: 194, value: 'В'},
            { code: 195, value: 'Г'},
            { code: 196, value: 'Д'},
            { code: 197, value: 'Е'},
            { code: 198, value: 'Ж'},
            { code: 199, value: 'З'},
            { code: 200, value: 'И'},
            { code: 201, value: 'Й'},
            { code: 202, value: 'К'},
            { code: 203, value: 'Л'},
            { code: 204, value: 'М'},
            { code: 205, value: 'Н'},
            { code: 206, value: 'О'},
            { code: 207, value: 'П'},
            { code: 208, value: 'Р'},
            { code: 209, value: 'С'},
            { code: 210, value: 'Т'},
            { code: 211, value: 'У'},
            { code: 212, value: 'Ф'},
            { code: 213, value: 'Х'},
            { code: 214, value: 'Ц'},
            { code: 215, value: 'Ч'},
            { code: 216, value: 'Ш'},
            { code: 217, value: 'Щ'},
            { code: 218, value: 'Ъ'},
            { code: 219, value: 'Ы'},
            { code: 220, value: 'Ь'},
            { code: 221, value: 'Э'},
            { code: 222, value: 'Ю'},
            { code: 223, value: 'Я'},
            { code: 224, value: 'а'},
            { code: 225, value: 'б'},
            { code: 226, value: 'в'},
            { code: 227, value: 'г'},
            { code: 228, value: 'д'},
            { code: 229, value: 'е'},
            { code: 230, value: 'ж'},
            { code: 231, value: 'з'},
            { code: 232, value: 'и'},
            { code: 233, value: 'й'},
            { code: 234, value: 'к'},
            { code: 235, value: 'л'},
            { code: 236, value: 'м'},
            { code: 237, value: 'н'},
            { code: 238, value: 'о'},
            { code: 239, value: 'п'},
            { code: 240, value: 'р'},
            { code: 241, value: 'с'},
            { code: 242, value: 'т'},
            { code: 243, value: 'у'},
            { code: 244, value: 'ф'},
            { code: 245, value: 'х'},
            { code: 246, value: 'ц'},
            { code: 247, value: 'ч'},
            { code: 248, value: 'ш'},
            { code: 249, value: 'щ'},
            { code: 250, value: 'ъ'},
            { code: 251, value: 'ы'},
            { code: 252, value: 'ь'},
            { code: 253, value: 'э'},
            { code: 254, value: 'ю'},
            { code: 255, value: 'я'},
]

export function toWin1251(s) {
    let result = '';
    if(!s) {
        return s;   //вдруг ноль, пускай ноль и возвращается
    }
    if (isNaN(s)) {
        var L = [];    
        for (let i=0; i<s.length; i++) {
            let ord = s.charCodeAt(i);
            if (ord > 127) {
                let charFind = chars.find(c => c.code === ord);
                L.push(charFind.value);
            } else {
                L.push(s[i]);
            }
            
        }
        result = L.join('');
    } else {
        result += s;
    }
    return result;
}
