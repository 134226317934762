import React, {useEffect, useState} from 'react';
import useInput from "./hooks/useInput";
import {fetchArt, fetchArtReceived, logInFailed, setModalMessage, setSelectedArt,
  setClearArt, setClearPartner,} from "../../store/bundle/bundleActions";
import {getRemains, fetchArtRequest} from "../../store/requests";
import {connect} from "react-redux";
import ModalMessage from "./modalMessage";
import Loader from "./Loader";
import {useSortableData} from "./hooks/useSortableData"

const mapStateToProps = (state) => ({
  loading: state.bundle.loading,
  newArts: state.bundle.newArts,
  selectArt: state.bundle.selectArt,
  clearArt: state.bundle.clearArt,
  clearPartner: state.bundle.clearPartner,
})

const mapDispatchToProps = ({
  fetchArt, fetchArtReceived, logInFailed, setModalMessage, setSelectedArt,
  setClearArt, setClearPartner,
})

const $CheckArt = (props) => {

  const inputName = useInput('')
  const inputArt = useInput('')
  const inputBarcode = useInput('')
  const inputProducer = useInput('')
  const inputBrend = useInput('')
  const [remains, setRemains] = useState([]);

  useEffect(() => {
    if (inputArt.value) {
      // console.log('inputArt.value: ', inputArt.value);
      inputName.onChange({target: {value: ''}});
      inputBarcode.onChange({target: {value: ''}});
      inputProducer.onChange({target: {value: ''}});
      inputBrend.onChange({target: {value: ''}});
    }
  }, [inputArt.value])// eslint-disable-line

  useEffect(() => {
    // console.log('юзэфект арт');
    if (props.clearArt) {                       //признак очистки верхней части стартовой страницы
      // console.log('юзэфект арт ВОШЛИ!!');
      props.setClearPartner(true);           //очищаем дальше, в средней части стартовой страницы
      props.setClearArt(false);              //Сбрасываем признак очистки
      cleanArt();                            //очищаем здесь(верхняя часть стартовой страницы)
    }
  }, [props.clearArt])// eslint-disable-line

  const checkedArt = async () => {
    if (inputName.value.length === 0 && inputArt.value.length === 0 && inputBarcode.value.length === 0) {
      return props.setModalMessage('нет данных для поиска')
    }
    if ((!inputBarcode.value && !inputArt.value) && inputName.value && inputName.value.length < 4) {
      return props.setModalMessage('введите название больше 3-х символов')
    }
    let param = {
      ArtCode: inputArt.value,
      Name: inputName.value,
      Barcode: inputBarcode.value,
      Producer: inputProducer.value,
      Brand: inputBrend.value
    }
    // console.log('Запрос на поиск товара: ', param);
    props.fetchArt()
    let result = await fetchArtRequest(param)
    if (result.error) {
      props.logInFailed(false)
      return
    }
    props.fetchArtReceived(result)
  }
  const cleanArt = () => {
    inputArt.onChange({target: {value: ''}})
    inputName.onChange({target: {value: ''}})
    inputBarcode.onChange({target: {value: ''}})
    inputProducer.onChange({target: {value: ''}})
    inputBrend.onChange({target: {value: ''}})
    props.fetchArtReceived([])
    setRemains([]);
  }
  const selectArt = (artCode) => {
    if (artCode === props.selectArt) {
      props.setSelectedArt(null)
    } else {
      props.setSelectedArt(artCode)
    }
  }

  const keyHandler = async (e) => {
    if (e.which === 13) {    //enter
      checkedArt().then(null)
    }
  }

  const getArtcodesRemains = async () => {
    let param = [];
    props.newArts.forEach(item => param.push(item.Artcode));
    let remainsL = await getRemains({Artcodes: param});
    // console.log('remainsL: ', remainsL);
    setRemains(remainsL);
  }

  const getValueRemains = (value) => {
    let ret = '';
    if (remains.length) {
      let f = remains.filter(item => item.art_code === value);
      // console.log('f : ', f, '   value = ', value);
      if (f.length) {
        ret = f[0].sumRemains;
      }
    }
    return ret;
  }

  const {items, requestSort, sortConfig} = useSortableData(props.newArts || []);
  const getClassNamesFor = (name) => {
    return sortConfig && sortConfig.key === name ? sortConfig.direction + ' ' + name : name;
  };

  return (
    <div className='wrapper_check_art'>
      <ModalMessage/>
      {props.loading ? <Loader/> : null}
      {/*<div className='wrapper_sub_check_art'>*/}
      {/*  <h4 className='wrapper_title_title'>Выберите арткод и наименование</h4>*/}
      {/*</div>*/}
      <div className='wrapper_sub_check_art fw-wrap jc-center'>

        <label htmlFor="artCode_" className="hmlabel">Арткод</label>
        <input id="artCode_"
               type='number'
               {...inputArt}
               className='input_art_code'
               onKeyDown={keyHandler}/>

        <label htmlFor="artName_" className="hmlabel">Наименование</label>
        <input id="artName_"
               type='text'
               {...inputName}
               className='input_art_code'
               onKeyDown={keyHandler}/>

        <label htmlFor="producerName_" className="hmlabel">Производитель</label>
        <input id="producerName_"
               type='text'
               {...inputProducer}
               className='input_art_code'
               onKeyDown={keyHandler}/>


        <label htmlFor="brendName_" className="hmlabel">Бренд</label>
        <input id="brendName_"
               type='text'
               {...inputBrend}
               className='input_art_code'
               onKeyDown={keyHandler}/>

        <label htmlFor="barCode_" className="hmlabel">Штрихкод</label>
        <input id="barCode_"
               type='text'
               {...inputBarcode}
               className='input_art_code'
               onKeyDown={keyHandler}/>
      </div>
      <div className='wrapper_sub_check_art'>
        <div>
          <button className='nice_button' onClick={getArtcodesRemains} disabled={!props.newArts.length}>Запросить
            остатки
          </button>
          <button className='nice_button' onClick={checkedArt}>Получить</button>
          <button className='nice_button' onClick={cleanArt}>Очистить</button>
          {/*<button className='nice_button' onClick={cleanAll}>Очистить всё</button>*/}
        </div>
      </div>
      <div className='table_wrapper'>
        <table className='table'>
          <thead>
          <tr>
            {/*<th className='table_art_name'>Арткод</th>*/}
            <th className='numpp'>№</th>
            <th onClick={() => requestSort('Artcode')}
                className={getClassNamesFor('Artcode')}>Арткод
            </th>
            {/*<th className='table_art_producer_title'>Название</th>*/}
            <th onClick={() => requestSort('Name')}
                className={getClassNamesFor('Name')}>Название
            </th>
            {/*<th className='table_art_prod_last'>Производитель</th>*/}
            <th onClick={() => requestSort('Producer')}
                className={getClassNamesFor('Producer')}>Производитель
            </th>
            <th onClick={() => requestSort('Brand')}
                className={getClassNamesFor('Brand')}>Бренд
            </th>
            {/*<th className='table_art_prod_last'>Кол-во в упаковке</th>*/}
            <th onClick={() => requestSort('amount_upak')}
                className={getClassNamesFor('amount_upak')}>Кол-во в упаковке
            </th>
            {/*<th className='table_art_prod_last'>Код морион</th>*/}
            <th onClick={() => requestSort('morione')}
                className={getClassNamesFor('morione')}>Код морион
            </th>
            {/*<th className='table_art_prod_last'>Заводской ш-код</th>*/}
            <th onClick={() => requestSort('BarCode')}
                className={getClassNamesFor('BarCode')}>Заводской ш-код
            </th>
            {/*<th className='table_art_prod_last'>Код Оптима</th>*/}
            <th onClick={() => requestSort('optima')}
                className={getClassNamesFor('optima')}>Код Оптима
            </th>
            {/*<th className='table_art_prod_last'>Код БАДМ</th>*/}
            <th onClick={() => requestSort('badm')}
                className={getClassNamesFor('badm')}>Код БАДМ
            </th>
            {/*<th className='table_art_prod_last'>Кратность</th>*/}
            <th onClick={() => requestSort('kratnost')}
                className={getClassNamesFor('kratnost')}>Кратность
            </th>
            {/*<th className='table_art_prod_last'>Цена</th>*/}
            <th onClick={() => requestSort('cena')}
                className={getClassNamesFor('cena')}>Цена
            </th>
            <th onClick={() => requestSort('sumRemains')}
                className={getClassNamesFor('sumRemains')}>Остаток
            </th>
            {/*<th className='table_art_prod_last'>Код мерчанта</th>*/}
          </tr>
          </thead>
          <tbody>
          {items.length > 0 && items.map((post, index) => {
              return (
                <tr key={index} className={props.selectArt === post.Artcode ? "selectedRow" : 'nonSelectedRow'}
                    onDoubleClick={(e) => selectArt(post.Artcode)}>
                  {/*<td className='textCentr'>{post.Artcode}</td>*/}
                  <td className='numpp'>{index + 1}</td>
                  <td className='Artcode'>{post.Artcode_t}</td>
                  <td className='Name left'>{post.Name_t}</td>
                  <td className="Producer left">{post.Producer_t}</td>
                  <td className="Brand left">{post.Brand}</td>
                  <td className="amount_upak">{post.amount_upak}</td>
                  <td className="morione">{post.morione}</td>
                  <td className="BarCode">{post.BarCode}</td>
                  <td className="optima">{post.optima}</td>
                  <td className="badm">{post.badm}</td>
                  <td className="kratnost">{post.kratnost}</td>
                  <td className="cena">{post.cena}</td>
                  <td className="sumRemains">{getValueRemains(post.Artcode)}</td>
                  {/*<td className="table_art_prod_last">{post.ProducerID}</td>*/}
                </tr>
              )
            }
          )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const CheckArt = connect(mapStateToProps, mapDispatchToProps)($CheckArt)

export default CheckArt;