import React from "react"
import {connect} from "react-redux"
import ContentHeader from "../Content/ContentHeader/ContentHeader";
import ProductsModal from "./ProductsModal/ProductsModal";
import Filters from "./Filters/Filters";
import Table from "./Table/Table";
import './SiteData.css'

const mapStateToProps = (state) => ({
  productsList: state.main.productsList,
  productCompare: state.main.productCompare,
  lang: state.main.lang,
  langBase: state.main.langBase,
})

const mapDispatchToProps = ({

})

const SiteData$ = (props) => {

  const s = (key) => {
    return props.langBase[key][props.lang]
  }

  const products = props.productsList
  const productCompare = props.productCompare

  return (
    <div className="wrapper d-flex fd-column">

      {productCompare && <ProductsModal/>}

      <ContentHeader>
        {s('Data to the site')}
      </ContentHeader>

      <div className="contentWrapper d-flex fd-column">
        <Filters/>
        {!products && <p className="ml-24 mt-50">!!!Use filters to find a product</p>}
        {products && <Table/>}
      </div>

    </div>
  )
}

const SiteData = connect(mapStateToProps, mapDispatchToProps)(SiteData$)

export default SiteData