import React, {useEffect, useState} from 'react'
import {setShowUndocking, setExtProducts, setModalMessage, setUpdatePartner} from "../../store/bundle/bundleActions"
import {fetchSearchExtProducts, deleteExtProducts} from "../../store/requests"
import {connect} from "react-redux"
import './style/undocking.css'
import ModalMessage from "./modalMessage"
import useInput from "./hooks/useInput"
import {parseDateOnly} from "../../store/helpers"

const mapStateToProps = (state) => ({
  ExtProducts: state.bundle.ExtProducts,
  idPartner: state.bundle.idPartner,
})
const mapDispatchToProps = ({
  setShowUndocking, setExtProducts, setModalMessage, setUpdatePartner,
})

const $Undocking = (props) => {

  const [idRow, setIdRow] = useState(0);
  const inputSKUID = useInput('')
  const inputArt = useInput('')
  const inputSKUName = useInput('')
  const inputProducer = useInput('')

  useEffect(() => {
    props.setExtProducts([]);
  }, [])  // eslint-disable-line

  const selectedRow = (id) => {
    setIdRow(id);
  }

  const undock = async () => {
    let row = await deleteExtProducts({id: idRow})
    props.setModalMessage(`Удален продукт: ${row[0].artName_t}\nПроизводитель: ${row[0].artProducer_t}`)
    props.setUpdatePartner(true)  //Обновить данные в средней части(партнеры, содержимое таблицы)
    props.setExtProducts(props.ExtProducts.filter(item => item.id !== row[0].id))
    setIdRow(0)
  }

  const closePage = () => {
    props.setShowUndocking(false);
  }

  const findProduct = async () => {
    let param = {
      // Partner_ID: props.idPartner,
      SKU_ID_Partner: inputSKUID.value,
      artCode_t: +inputArt.value,
      artName_t: inputSKUName.value,
      artProducer_t: inputProducer.value
    }
    // console.log('param', param)
    const data = await fetchSearchExtProducts(param)
    // console.log('data', data)
    props.setExtProducts(data);
  }

  return (
    <div className='modalUndock'>
      <ModalMessage/>
      <div className='divHeader'>
        <div className='divHeader__title'>Расстыковка</div>
      </div>
      {/*{props.showSetColumnImport ? <SelectColumnImport/> : null}*/}
      <div className='modalUndock__content'>


        <div className="blockSearch">

          <div className="ucodeSKUPartner divColumn">
            <label htmlFor="inputSKUID_" className="labelUndock">SKU ID</label>
            <input id="inputSKUID_"
                   type='number'
                   {...inputSKUID}
                   className='input_Undock'/>
          </div>

          <div className="uartCode divColumn">
            <label htmlFor="artCode_" className="labelUndock">Арткод</label>
            <input id="artCode_"
                   type='number'
                   {...inputArt}
                   className='input_Undock'/>
          </div>

          <div className="unameSKUPartner divColumn">
            <label htmlFor="inputSKUName_" className="labelUndock">Название</label>
            <input id="inputSKUName_"
                   type='text'
                   {...inputSKUName}
                   className='input_Undock'/>
          </div>

          <div className="uproducer divColumn">
            <label htmlFor="inputProducer_" className="labelUndock">Производитель</label>
            <input id="inputProducer_"
                   type='text'
                   {...inputProducer}
                   className='input_Undock'/>
          </div>

        </div>

        <div className='divButtonSearch'>
          <button className='nice_button'
                  disabled={!inputArt.value && !inputSKUID.value && !inputSKUName.value && !inputProducer.value}
                  onClick={findProduct}>Найти
          </button>
        </div>

        <div className='divBody'>
          <table className='tableExtProducts'>
            <thead>
            <tr className="rowtable">
              <th className='uPartner_ID center centerVertical'>ID Partner</th>
              <th className='ucodeSKUPartner center centerVertical'>SKU ID</th>
              <th className='uartCode center centerVertical'>ArtCode</th>
              <th className='unameSKUPartner center centerVertical'>SKU Name</th>
              <th className='uproducer center centerVertical'>Producer</th>
              <th className='udateIns center centerVertical'>Дата</th>
              <th className='uAptekaId center centerVertical'>Аптека</th>
            </tr>
            </thead>
            <tbody>
            {props.ExtProducts.length > 0 && props.ExtProducts.map((item, index) => (
              <tr className={idRow === item.id ? 'rowtable selected' : 'rowtable'} key={index}
                  onClick={() => selectedRow(item.id)}>
                <td className='uPartner_ID centerVertical'>{item.partner_id}</td>
                <td className='ucodeSKUPartner centerVertical'>{item.codeSKUPartner}</td>
                <td className='uartCode centerVertical'>{item.artCode_t}</td>
                <td className='unameSKUPartner centerVertical'>{item.artName_t}</td>
                <td className='uproducer centerVertical'>{item.artProducer_t}</td>
                <td className='udateIns centerVertical'>{parseDateOnly(item.insDate, '.')}</td>
                <td className='uAptekaId center centerVertical'>{item.apteka_id}</td>
              </tr>
            ))}
            </tbody>
          </table>
        </div>

        <div className='divFooter'>
          <button className='nice_button' onClick={undock} disabled={!idRow}>Расстыковать</button>
          <button className='nice_button' onClick={closePage}>Выход</button>
        </div>
      </div>
    </div>
  )
}

const Undocking = connect(mapStateToProps, mapDispatchToProps)($Undocking)

export default Undocking