import React, {useState} from 'react'
import {connect} from "react-redux"
import {setShowImportPage, setDataImport, setModalMessage, fetchProductReceived,
  fetchName, fetchNameReceived, getProductList, setShowSetColumnImport,
  logInFailed} from "../../store/bundle/bundleActions"
import {fetchMorione, fetchGetBarcode, insertForManual, getAllForCheck,
  truncateManual, truncateCheck, fetchOptima, fetchBadm, isExistsProduct,
  fetchNameRequest, fetchProductRequest} from "../../store/requests"
import ModalMessage from "./modalMessage";
import './style/importFromFile.css'

const mapStateToProps = (state) => ({
  dataImport: state.bundle.dataImport,
  importColumnSort: state.bundle.importColumnSort,
  newArts: state.bundle.newArts,
  showSetColumnImport: state.bundle.showSetColumnImport,
  idPartner: state.bundle.idPartner,
})
const mapDispatchToProps = ({
  setShowImportPage, setDataImport, setModalMessage, fetchProductReceived,
  fetchName, fetchNameReceived, getProductList, setShowSetColumnImport,
  logInFailed
})

const $ImportFromFile = (props) => {

  const [indexRow, setIndexRow] = useState(0);

  const rowCompareCompleted = (index) => {
    let localDataImport = props.dataImport.slice();
    localDataImport[index][0] = 21;                 //признак того, что строка обработана...
    props.setDataImport(localDataImport);
  }

  const errorProcessing = (index) => {
    let localDataImport = props.dataImport.slice();
    localDataImport[index][0] = 1;                 //признак того, что строка не обработана...
    props.setDataImport(localDataImport);
  }

  const sortColumnValue = (nameColumn) => {
    return props.importColumnSort.filter(item => item.name_column === nameColumn)[0].sort
  }

  const createObj = (newArt, index) => {
    // console.log('createObj newArt => ', newArt);
    let countColumn = props.dataImport[index].length;

    return {
      id: 0,
      Partner_ID: sortColumnValue('ID Partner') > countColumn ? 0 : props.dataImport[index][sortColumnValue('ID Partner')] || 0,
      SKU_ID_Partner: sortColumnValue('SKU ID Partner') > countColumn ? 0 : props.dataImport[index][sortColumnValue('SKU ID Partner')] || 0,
      SKU_ID_Morion: sortColumnValue('ID Morion') > countColumn ? 0 : props.dataImport[index][sortColumnValue('ID Morion')] || 0,
      SKU_ID_Optima: sortColumnValue('ID Optima') > countColumn ? 0 : props.dataImport[index][sortColumnValue('ID Optima')] || 0,
      SKU_ID_Badm: sortColumnValue('ID Badm') > countColumn ? 0 : props.dataImport[index][sortColumnValue('ID Badm')] || 0,
      SKU_Name: sortColumnValue('SKU Name') > countColumn ? '' : props.dataImport[index][sortColumnValue('SKU Name')] || '',
      producer: sortColumnValue('Name Producer') > countColumn ? '' : props.dataImport[index][sortColumnValue('Name Producer')] || '',
      vat: sortColumnValue('Vat') > countColumn ? 0 : props.dataImport[index][sortColumnValue('Vat')] || 0,
      Apteka_ID: sortColumnValue('ID Apteka') > countColumn ? 0 : props.dataImport[index][sortColumnValue('ID Apteka')] || 0,
      barcode: sortColumnValue('Barcode') > countColumn ? '' : props.dataImport[index][sortColumnValue('Barcode')] || '',
      unit_koef: sortColumnValue('Koef') > countColumn ? 0 : props.dataImport[index][sortColumnValue('Koef')] || 0,
      quantity: sortColumnValue('Quantity') > countColumn ? 0 : props.dataImport[index][sortColumnValue('Quantity')] || 0,
      price: sortColumnValue('Price') > countColumn ? 0 : props.dataImport[index][sortColumnValue('Price')] || 0,
      partibilityFactor: sortColumnValue('Partibility Factor') > countColumn ? 0 : props.dataImport[index][sortColumnValue('Partibility Factor')] || 1,

      artCode: newArt.length > 0 ? newArt[0].artCode : 0,
      artName: newArt.length > 0 ? newArt[0].nameSKU911 : '',
      artProducer: newArt.length > 0 ? newArt[0].Producer : '',

      artCode_t: newArt.length > 0 ? newArt[0].artCode_t : 0,
      artName_t: newArt.length > 0 ? newArt[0].artName_t : '',
      artProducer_t: newArt.length > 0 ? newArt[0].artProducer_t : ''
    }
  }

  const selectedRow = (index) => {
    // setSelectedIndex(index);
    setIndexRow(index);
    // console.log('selectedRow: ', index);
  }

  const compareMorion = async () => {

    let divElement = document.getElementsByClassName('divBody')[0];

    if (!indexRow) {
      props.setModalMessage('Выделите строку, с которой начнем обработку.');
      return;
    }
    // console.log('очистим все таблицы');
    await truncateManual({partner_id: props.idPartner});    //очистим forManual
    await truncateCheck({partner_id: props.idPartner});     //очистим forCheck

    // setIsRun(true);        //для юзэфекта флаг, что можно обрабатывать изменения в props.newArts
    let indexElement = props.importColumnSort.filter(item => item.name_column === 'ID Morion');
    let indexElement1 = props.importColumnSort.filter(item => item.name_column === 'Barcode');
    let indexElement2 = props.importColumnSort.filter(item => item.name_column === 'ID Optima');
    let indexElement3 = props.importColumnSort.filter(item => item.name_column === 'ID Badm');
    // console.log('indexElement: ', indexElement);
    let indexCodeMorion = indexElement[0].sort;                      //столбец код Мориона
    let indexBarcode = indexElement1[0].sort;                        //столбец штрихкод
    let indexOptima = indexElement2[0].sort;                         //столбец код оптима
    let indexBadm = indexElement3[0].sort;                           //столбец код бадм
    let countExistsProducts = 0

    for (let i = indexRow; i < props.dataImport.length; i++) {       //Пройдем по всем строкам от выделенной и до конца...
      // console.log('мы на строке с индексом : ', i)
      setIndexRow(i);
      let exists = await isExistsCurrentProduct(i)
      // console.log('exists', exists)
      if (exists) {                            //Такая запись уже есть в Products, переходим к следующей строчке
        countExistsProducts++;
        continue;
      }
      let newArt = [];
      // console.log("CodeMorione: props.dataImport[i][indexCodeMorion] => : ", props.dataImport[i][indexCodeMorion]);
      if (props.dataImport[i][indexCodeMorion]) {      //Пытаемся по коду морионе найти товар
        // console.log("ЕСТЬ! CodeMorione: props.dataImport[i][indexCodeMorion] => : ", props.dataImport[i][indexCodeMorion]);
        let js = {
          CodeMorione: props.dataImport[i][indexCodeMorion] + ''
        };
        // console.log('js : ', js);
        newArt = await fetchMorione(js);
        // console.log('получили морионе: ', newArt);
      }
      // console.log("Barcode: props.dataImport[i][indexBarcode] => : ", props.dataImport[i][indexBarcode]);
      if (!newArt.length && props.dataImport[i][indexBarcode]) {   //по морионе не нашли товар, штрихкод присутствует. Поищем по нему...
        let js = {
          BarCode: props.dataImport[i][indexBarcode] + ''
        }
        // console.log('js : ', js);
        newArt = await fetchGetBarcode(js);
        // console.log('получили штрихкод: ', newArt);
      }
      // console.log("Optima: props.dataImport[i][indexOptima] => : ", props.dataImport[i][indexOptima]);
      if (!newArt.length && props.dataImport[i][indexOptima]) {   //по штрихкоду не нашли товар, код оптимы присутствует. Поищем по нему...
        let js = {
          CodeOptima: props.dataImport[i][indexOptima] + ''
        }
        // console.log('js : ', js);
        newArt = await fetchOptima(js);
        // console.log('получили код оптимы: ', newArt);
      }
      // console.log("Badm: props.dataImport[i][indexBadm] => : ", props.dataImport[i][indexBadm]);
      if (!newArt.length && props.dataImport[i][indexBadm]) {   //по коду оптимы не нашли товар, код бадм присутствует. Поищем по нему...
        let js = {
          CodeBadm: props.dataImport[i][indexBadm] + ''
        }
        // console.log('js : ', js);
        newArt = await fetchBadm(js);
        // console.log('получили код бадм: ', newArt);
      }
      const obj = createObj(newArt, i);
      // console.log('obj: ', obj);

      if (newArt.length === 1) {
        //товар найден, можно сопоставлять

        let result = await fetchProductRequest(obj)
        props.fetchProductReceived(result)

        rowCompareCompleted(i);                       //пометим обработанную строку...
      } else {                                          //не найден товар, не с чем сопоставлять...
        await insertForManual(obj);
        errorProcessing(i);                           //пометим НЕобработанную строку...
      }
      divElement.scrollBy(0, 25);
    }
    let matched = props.dataImport.filter(item => item[0] === 21);  //сопоставленные записи
    let notMatched = props.dataImport.filter(item => item[0] === 1);  //НЕсопоставленные записи

    let message = 'Сопоставлено записей: ' + matched.length + ',\nНе найдено сопоставлений: ' + notMatched.length + ',\nПовторное сопоставление: ' + countExistsProducts
    await props.setModalMessage(message);
    let dataForCheck = await getAllForCheck({partner_id: props.idPartner});        //все записи из Check
    await props.getProductList(dataForCheck);

    props.fetchName() //все записи из Manual
    let result = await fetchNameRequest({partner_id: props.idPartner})
    if (result.error) {
      props.logInFailed(false)
      return
    }
    props.fetchNameReceived(result)

    closePage();
  }

  const closePage = () => {
    props.setDataImport([]);
    props.setShowImportPage(false);
  }

  const colorRow = (index, item) => {
    let className = '';
    if (indexRow === index) {
      className = 'selected';
    } else if (item[0] === 1) {
      className = 'errorProcessing';
    } else if (item[0] === 21) {
      className = 'successfull';
    } else if (indexRow > index) {
      className = 'yellow';
    }
    return className;
  }

  const setVisibleSelect = () => {
    props.setShowSetColumnImport(true);
  }

  const isExistsCurrentProduct = async (currentIndex) => {
    let param = {
      Partner_ID: props.dataImport[currentIndex][sortColumnValue('ID Partner')] || 0,
      SKU_ID_Partner: props.dataImport[currentIndex][sortColumnValue('SKU ID Partner')] || 0,
      Apteka_ID: props.dataImport[currentIndex][sortColumnValue('ID Apteka')] || 0,
    }
    // console.log('param: ', param);
    // console.log('result = ', result);
    return await isExistsProduct(param)
  }

  return (
    <div className='modalImport'>
      <ModalMessage/>
      {/*{props.showSetColumnImport ? <SelectColumnImport/> : null}*/}
      <div className='modalImport__content'>
        <div className='divHeader'>
          <div className='divHeader__title'>Импорт данных{props.showSetColumnImport}</div>
        </div>

        {props.importColumnSort.length !== 0 && <div className='divBody'>
          <table className='tableImport'>
            <thead>
            <tr>
              <th className='nPP centerVertical' />

              <th className={'col' + props.importColumnSort[0].id + ' center dataCell centerVertical'}
                  onClick={() => setVisibleSelect()}>
                {props.importColumnSort[0].name_column}
              </th>

              <th className={'col' + props.importColumnSort[1].id + ' center dataCell centerVertical'}
                  onClick={() => setVisibleSelect()}>
                {props.importColumnSort[1].name_column}
              </th>

              <th className={'col' + props.importColumnSort[2].id + ' center dataCell centerVertical'}
                  onClick={() => setVisibleSelect()}>
                {props.importColumnSort[2].name_column}
              </th>

              <th className={'col' + props.importColumnSort[3].id + ' center dataCell centerVertical'}
                  onClick={() => setVisibleSelect()}>
                {props.importColumnSort[3].name_column}
              </th>

              <th className={'col' + props.importColumnSort[4].id + ' center dataCell centerVertical'}
                  onClick={() => setVisibleSelect()}>
                {props.importColumnSort[4].name_column}
              </th>

              <th className={'col' + props.importColumnSort[5].id + ' center dataCell centerVertical'}
                  onClick={() => setVisibleSelect()}>
                {props.importColumnSort[5].name_column}
              </th>

              <th className={'col' + props.importColumnSort[6].id + ' center dataCell centerVertical'}
                  onClick={() => setVisibleSelect()}>
                {props.importColumnSort[6].name_column}
              </th>

              <th className={'col' + props.importColumnSort[7].id + ' center dataCell centerVertical'}
                  onClick={() => setVisibleSelect()}>
                {props.importColumnSort[7].name_column}
              </th>

              <th className={'col' + props.importColumnSort[8].id + ' center dataCell centerVertical'}
                  onClick={() => setVisibleSelect()}>
                {props.importColumnSort[8].name_column}
              </th>

              <th className={'col' + props.importColumnSort[9].id + ' center dataCell centerVertical'}
                  onClick={() => setVisibleSelect()}>
                {props.importColumnSort[9].name_column}
              </th>

              <th className={'col' + props.importColumnSort[10].id + ' center dataCell centerVertical'}
                  onClick={() => setVisibleSelect()}>
                {props.importColumnSort[10].name_column}
              </th>

              <th className={'col' + props.importColumnSort[11].id + ' center dataCell centerVertical'}
                  onClick={() => setVisibleSelect()}>
                {props.importColumnSort[11].name_column}
              </th>

              <th className={'col' + props.importColumnSort[12].id + ' center dataCell centerVertical'}
                  onClick={() => setVisibleSelect()}>
                {props.importColumnSort[12].name_column}
              </th>

              <th className={'col' + props.importColumnSort[13].id + ' center dataCell centerVertical'}
                  onClick={() => setVisibleSelect()}>
                {props.importColumnSort[13].name_column}
              </th>

            </tr>
            </thead>
            <tbody>
            {props.dataImport && props.dataImport.map((item, index) => (
                <tr className={colorRow(index, item)} key={index} onClick={() => selectedRow(index)}>
                  <td className='nPP centerVertical'>{index + 1}</td>
                  <td className={'col' + props.importColumnSort[0].id + ' centerVertical'}>{item[1]}</td>
                  <td className={'col' + props.importColumnSort[1].id + ' centerVertical'}>{item[2]}</td>
                  <td className={'col' + props.importColumnSort[2].id + ' centerVertical'}>{item[3]}</td>
                  <td className={'col' + props.importColumnSort[3].id + ' centerVertical'}>{item[4]}</td>
                  <td className={'col' + props.importColumnSort[4].id + ' centerVertical'}>{item[5]}</td>
                  <td className={'col' + props.importColumnSort[5].id + ' centerVertical'}>{item[6]}</td>
                  <td className={'col' + props.importColumnSort[6].id + ' centerVertical'}>{item[7]}</td>
                  <td className={'col' + props.importColumnSort[7].id + ' centerVertical'}>{item[8]}</td>
                  <td className={'col' + props.importColumnSort[8].id + ' centerVertical'}>{item[9]}</td>
                  <td className={'col' + props.importColumnSort[9].id + ' centerVertical'}>{item[10]}</td>
                  <td className={'col' + props.importColumnSort[10].id + ' centerVertical'}>{item[11]}</td>
                  <td className={'col' + props.importColumnSort[11].id + ' centerVertical'}>{item[12]}</td>
                  <td className={'col' + props.importColumnSort[12].id + ' centerVertical'}>{item[13]}</td>
                  <td className={'col' + props.importColumnSort[13].id + ' centerVertical'}>{item[14]}</td>
                </tr>
              )
            )
            }
            </tbody>
          </table>
        </div>}

        <div className='divFooter'>
          {/*<input id="inputFile" className="inputFile" type="file" onChange={uploadFile}/>*/}
          {/*<label htmlFor="inputFile" className="labelFile">Выбрать файл</label>*/}

          <button className='nice_button' onClick={() => setVisibleSelect(1)}>Колонки</button>
          <button className='nice_button' onClick={compareMorion} disabled={!props.dataImport?.length}>Сопоставить
          </button>
          <button className='nice_button' onClick={closePage}>Выход</button>
        </div>
      </div>
    </div>
  );
};

const ImportFromFile = connect(mapStateToProps, mapDispatchToProps)($ImportFromFile)

export default ImportFromFile;