import React, {useState} from "react"
import {connect} from "react-redux"
import {getProductsByCodesArray, getProductsByFilter} from "../../../store/requests";
import {setProductsList, setPharmacyToCompare, updateComparingList} from "../../../store/main/mainActions";
import SearchInput from "../../SearchInput/SearchInput";

const mapStateToProps = (state) => ({
  pharmaciesList: state.main.pharmaciesList,
  pharmacyToCompare: state.main.pharmacyToCompare,
  productsList: state.main.productsList,
  pharmaciesComparingList: state.main.pharmaciesComparingList,
  lang: state.main.lang,
  langBase: state.main.langBase,
})

const mapDispatchToProps = ({
  setProductsList, setPharmacyToCompare, updateComparingList
})

const Filters$ = (props) => {

  const s = (key) => {
    return props.langBase[key][props.lang]
  }

  const products = props.productsList
  const pharmacies = props.pharmaciesList
  const comparePharmacy = props.pharmacyToCompare
  // const comparingList = props.pharmaciesComparingList

  const [filterInput, setFilterInput] = useState('')
  const filterInputHandler = (e) => {
    setFilterInput(e.target.value)
  }

  const getProductsHandler = async () => {
    let isArtCode = filterInput.replace(/\D+/, '').length === filterInput.length
    let params = {}
    if (isArtCode) params.artCode = filterInput
    else params.name = filterInput
    props.setProductsList(await getProductsByFilter(params))
  }

  const toCompareHandler = (item) => {
    return () => props.setPharmacyToCompare(item)
  }

  const updateCompareHandler = async () => {
    let params = {
      artCodes: products.map(item => item.artCode),
      pharmacyId: comparePharmacy.pharmacyId - 2020000000
    }
    let result = await getProductsByCodesArray(params)
    let newList = products.map(item => {
      return result.find(x => x.artCode === item.artCode) || {
        artCode: item.artCode,
        extPointsId: null,
        packFactor: null,
        partibilityFactor: null,
        price: null,
        reservedPrice: null,
        balance: null,
      }
    })
    props.updateComparingList({
      name: comparePharmacy.addressText,
      list: newList
    })
  }

  // const checkAddButton = (key) => {
  //
  // }

  return (
    <div className="siteDataFilters mt-12 d-flex ai-fe">
      <div className="d-flex fd-column">
        <p className="tinyTitle">!!!Products search</p>
        <input
          value={filterInput}
          onChange={filterInputHandler}
          placeholder="!!!Type product name"
          className="input mt-5"
          type="text"
        />
      </div>
      <button
        className="button buttonGreen ml-5"
        onClick={getProductsHandler}
      >!!!Find
      </button>

      {products &&
      <div className="d-flex fd-column ml-24">
        <p className="tinyTitle">!!!Add to equal</p>

        <SearchInput
          selected={comparePharmacy}
          list={pharmacies}
          itemSetter={toCompareHandler}
          itemKey={'addressText'}
        />

      </div>
      }

      {comparePharmacy &&
      <button onClick={updateCompareHandler} className="button buttonGreen ml-5">{s('Add')}</button>
      }

    </div>
  )
}

const Filters = connect(mapStateToProps, mapDispatchToProps)(Filters$)

export default Filters