import React, {useEffect, useState} from "react"
import {connect} from "react-redux"
import {updatePharmacy, setBrands} from "../../../../store/main/mainActions";
import {getBrands, patchBrand, postBrand} from "../../../../store/requests";

const mapStateToProps = (state) => ({
  lang: state.main.lang,
  langBase: state.main.langBase,
  selectedPharmacy: state.main.selectedPharmacy,
  brands: state.main.brands,
})

const mapDispatchToProps = ({
  updatePharmacy, setBrands
})

const Brand$ = (props) => {

  const s = (key) => {
    return props.langBase[key][props.lang]
  }

  const [isSelectModal, toggleSelectModal] = useState(false)
  const [filter, setFilter] = useState('')
  const [list, setList] = useState([])
  const [brand, setBrand] = useState(null)
  const [modalBrand, setModalBrand] = useState(null)
  const pharmacy = props.selectedPharmacy
  const brandName = props.brands?.find(item => item.id === pharmacy.brandId)?.nameUa

  const onLoad = () => {
    let current = props.brands.find(item => item.id === pharmacy.brandId)
    if (current) setBrand(current)
  }

  useEffect(() => {
    if (brand) {
      let updated = props.brands.find(item => item.id === brand.id)
      setBrand(updated)
    }
  }, [JSON.stringify(props.brands)])    // eslint-disable-line

  useEffect(() => {
    let temp = props.brands?.filter(item => item.nameUa?.toLowerCase()?.includes(filter.toLowerCase()))
    temp = temp.slice(0, 10)
    setList(temp)
  }, [filter])    // eslint-disable-line

  const filterHandler = (e) => {
    setFilter(e.target.value)
  }

  const openModal = () => {
    onLoad()
    toggleSelectModal(true)
  }

  const closeModal = () => {
    toggleSelectModal(false)
    setFilter('')
    setList([])
    setBrand(null)
  }

  const openBrandModal = () => {
    if (brand) {
      setModalBrand(brand)
      return
    }
    setModalBrand({
      id: null,
      nameUa: '',
      nameRu: '',
      nameUz: '',
    })
  }

  const closeBrandModal = () => {
    setModalBrand(null)
  }

  const selectHandler = (item) => {
    return () => setBrand(item)
  }

  const brandModalHandler = (key) => {
    return (e) => {
      let temp = Object.assign({}, modalBrand)
      temp[key] = e.target.value
      setModalBrand(temp)
    }
  }

  const acceptBrandHandler = async () => {
    let brand = Object.assign({}, modalBrand)
    let id = brand.id
    if (!brand.nameUa || !brand.nameRu || !brand.nameUz) {
      alert('Заповніть всі поля перед збереженням')
    }
    delete brand.id
    if (!id) {
      let result = await postBrand(modalBrand)
      if (result?.id) {
        alert('Успішно збережено')
        closeBrandModal()
      } else {
        alert('Помилка API')
      }
    } else {
      delete brand.partnerId
      let result = await patchBrand(id, modalBrand)
      if (result[0] === 1) {
        alert('Успішно збережено')
        closeBrandModal()
      } else {
        alert('Помилка API')
      }
    }
    props.setBrands( await getBrands() )
    onLoad()
  }

  const acceptHandler = () => {
    if (brand) props.updatePharmacy('brandId', brand.id)
    closeModal()
  }

  return (
    <>

      {isSelectModal &&
      <div onMouseDown={closeModal} className="modalWrapper">
        <div onMouseDown={e => e.stopPropagation()} className="modalContainer d-flex fd-column">
          <h3>{s('Choose a brand')}</h3>
          {brand &&
          <div className="searchContainer mt-12">
            <div className="input">{brand.nameUa}</div>
            <div onClick={selectHandler(null)} className="resetSearchButton" title={s('Reset')} />
            <div onClick={openBrandModal} className="editSearchButton" title={s('Edit brand')} />
          </div>
          }
          {!brand &&
          <div className="searchContainer mt-12">
            <input placeholder={s('Start typing your brand name')} value={filter} onChange={filterHandler} className="input" type="text"/>
            <div className="searchList">
              {list.length > 0 && list.map(item => (
                <p className="searchListItem" key={item.id} onClick={selectHandler(item)}>{item.nameUa}</p>
              ))}
              {list.length === 0 && <p className="fw-500 p-15">{s('Not found')}</p>}
              <p onClick={openBrandModal} className="searchListItem mt-50 fw-500">{s('Add a new brand')}</p>
            </div>
          </div>
          }

          <div className="d-flex mt-12 jc-sa">
            <button onClick={acceptHandler} className="button hover bg-blue3 mt-5">{s('Apply')}</button>
            <button onClick={closeModal} className="button hover bg-blue3 mt-5">{s('Cancel')}</button>
          </div>
        </div>
      </div>
      }

      {modalBrand &&
      <div onMouseDown={closeBrandModal} className="modalWrapper">
        <div onMouseDown={e => e.stopPropagation()} className="modalContainer d-flex fd-column">
          <h3>{modalBrand.id ? s('Edit brand') : s('Add a brand')}</h3>
          <p className="mt-12 fw-500">{s('Title in Ukrainian')}</p>
          <input
            value={modalBrand['nameUa']}
            onChange={brandModalHandler('nameUa')}
            autoComplete="off"
            className="input"
            type="text"
          />
          <p className="mt-12 fw-500">{s('Title in russian')}</p>
          <input
            value={modalBrand['nameRu']}
            onChange={brandModalHandler('nameRu')}
            autoComplete="off"
            className="input"
            type="text"
          />
          <p className="mt-12 fw-500">{s('Title in Uzbek')}</p>
          <input
            value={modalBrand['nameUz']}
            onChange={brandModalHandler('nameUz')}
            autoComplete="off"
            className="input"
            type="text"
          />
          <div className="d-flex mt-12 jc-sa">
            <button onClick={acceptBrandHandler} className="button hover bg-blue3 mt-5">{modalBrand.id ? s('Save') : s('Add')}</button>
            <button onClick={closeBrandModal} className="button hover bg-blue3 mt-5">{s('Cancel')}</button>
          </div>
        </div>
      </div>
      }

      <div onClick={openModal} className="input cursor-pointer">{brandName || '---'}</div>

    </>
  )
}

const Brand = connect(mapStateToProps, mapDispatchToProps)(Brand$)

export default Brand