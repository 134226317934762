import React, {useEffect, useState} from 'react'
import {connect} from "react-redux"
import ContentHeader from "../Content/ContentHeader/ContentHeader"
import './Faq.css'
import listJson from './Faq.json'

const mapStateToProps = (state) => ({
  lang: state.main.lang,
  langBase: state.main.langBase,
})

const mapDispatchToProps = ({})

const Faq$ = (props) => {

  const [list] = useState(listJson)

  // const s = (key) => {
  //   return props.langBase[key][props.lang]
  // }

  const onLoad = async () => {

  }

  useEffect(() => {
    onLoad().then(null)
  }, [])  // eslint-disable-line


  return (
    <div className="wrapper d-flex fd-column">
      <ContentHeader>
        FAQ
      </ContentHeader>
      <div className='wrapper_main faqWrapper'>
        {list.map(item => (
          <div key={item.question} className="faqBlock">
            <p className="faqQuestion">{item.question}</p>
            <p className="faqAnswer">{item.answer}</p>
          </div>
        ))}
      </div>
    </div>
  )

}

const Faq = connect(mapStateToProps, mapDispatchToProps)(Faq$)

export default Faq