import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import Login from "./Login/Login";
import Content from "./Content/Content";
import { setUser, setSettingsList } from "../store/main/mainActions";
import {getSettingsList, getUserData} from "../store/requests";
import Errors from "./Errors/Errors";

const mapStateToProps = (state) => ({
  user: state.main.user,
  settingsList: state.main.settingsList
})

const mapDispatchToProps = ({
  setUser, setSettingsList
})

const App$ = (props) => {

  const onLoad = async () => {
    props.setUser( await getUserData() )
    props.setSettingsList( await getSettingsList() )
  }

  useEffect( () => {
    onLoad().then(null)
  }, [] )   // eslint-disable-line

  return(
    <div className={props?.user?.theme?.descr}>
      <Errors/>
      { props.user === undefined && <p>loading</p> }
      { props.user === null && <Login /> }
      { props.user && props.settingsList && <Content /> }
    </div>
  )
}

const App = connect(mapStateToProps, mapDispatchToProps)(App$)

export default App
