import React from 'react'
import {connect} from "react-redux"
import ArtCodePartner from "./ArtCodePartner"
import {setLensArtCodePartner} from "../../store/bundle/bundleActions"
import './style/lens.css'

const mapStateToProps = (state) => ({
  lensArtCodePartner: state.bundle.lensArtCodePartner,
})

const mapDispatchToProps = ({
  setLensArtCodePartner,
})

const $Lens = (props) => {
  return (
    <div className='wrapperLens'>
      {/*<CheckArt/>*/}
      {props.lensArtCodePartner && <ArtCodePartner/>}
      {/*<ProductsforCheck/>*/}
    </div>
  )
}

const Lens = connect(mapStateToProps, mapDispatchToProps)($Lens)

export default Lens