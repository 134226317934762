import React, { useEffect } from "react"
import { connect } from "react-redux"
import { setOrderDetails } from "../../../../store/main/mainActions";
import { getOrderDetails } from "../../../../store/requests";
import { orderDetailsTitles } from "../../../../store/dictionaries";

const mapStateToProps = (state) => ({
  activeOrderId: state.main.activeOrderId,
  orderDetails: state.main.orderDetails,
  lang: state.main.lang,
  langBase: state.main.langBase
})

const mapDispatchToProps = ({
  setOrderDetails
})

const OrderDetails$ = (props) => {

  const s = (key) => {
    return props.langBase[key][props.lang]
  }

  const orderId = props.activeOrderId
  const details = props.orderDetails

  const refreshHandler = async (orderId) => {
    props.setOrderDetails(null)
    props.setOrderDetails( await getOrderDetails(orderId) )
  }

  useEffect( () => {
    if (orderId) {
      refreshHandler(orderId).then(null)
    }
  }, [orderId] )    // eslint-disable-line

  if (!details || details?.length === 0) return(
    <div className="tableWrapper pl-12 pt-12">
      {
        details === undefined
        ?
        <p>{s('Entry not selected')}</p>
        :
        details === null
        ?
        <p>{s('Loading')}</p>
        :
        details?.length === 0
        ?
        <p>{s('No items found for this entry')}</p>
        :
        <p>{s('API error')}</p>
      }
    </div>
  )

  return(
    <div className="tableWrapper">
      <table className="table">
        <thead>
        <tr>
          { orderDetailsTitles.map( title => (
            <th key={title.key}>{title.name || title.key}</th>
          ) ) }
        </tr>
        </thead>
        <tbody>
        { details.map( order => (
          <tr key={order.itemId}>
            { orderDetailsTitles.map( title => (
              <td key={title.key}>{order[title.key]}</td>
            ) ) }
          </tr>
        ) ) }
        </tbody>
      </table>
    </div>
  )
}

const OrderDetails = connect(mapStateToProps, mapDispatchToProps)(OrderDetails$)

export default OrderDetails