import {
  TOGGLE_LOADING,
  HANDLE_ERROR,

  SET_SETTINGS_LIST,
  SET_USER,

  SET_PRELOAD,
  SET_ORDERS_LIST,
  SET_ACTIVE_ORDER,
  SET_ORDER_INFO_MODE,
  SET_ORDER_DETAILS,
  SET_ORDER_HISTORY,
  SET_FILTER,
  SET_PAGE,
  CHANGE_LANG,

  SET_PHARMACY,
  UPDATE_PHARMACY,
  SET_BRANDS,
  SET_CITIES,

  SET_PRODUCTS_LIST,
  SET_PHARMACY_TO_COMPARE,
  UPDATE_COMPARING_LIST,
  SPLICE_FROM_COMPARING_LIST,
  SET_PRODUCT_TO_COMPARE,
} from "./mainConstants";

export const toggleLoading = (state) => ({
  type: TOGGLE_LOADING, state
})
export const handleError = (error) => ({
  type: HANDLE_ERROR, error
})
export const setSettingsList = (list) => ({
  type: SET_SETTINGS_LIST, list
})
export const setUser = (user) => ({
  type: SET_USER, user
})
export const setPreload = (preload) => ({
  type: SET_PRELOAD, preload
})
export const setOrdersList = (list) => ({
  type: SET_ORDERS_LIST, list
})
export const setActiveOrder = (id) => ({
  type: SET_ACTIVE_ORDER, id
})
export const setOrderInfoMode = (mode) => ({
  type: SET_ORDER_INFO_MODE, mode
})
export const setOrderDetails = (details) => ({
  type: SET_ORDER_DETAILS, details
})
export const setOrderHistory = (history) => ({
  type: SET_ORDER_HISTORY, history
})
export const setFilter = (key, value) => ({
  type: SET_FILTER, key, value
})
export const setPage = (page) => ({
  type: SET_PAGE, page
})
export const changeLang = (lang) => ({
  type: CHANGE_LANG, lang
})
export const setPharmacy = (pharmacy) => ({
  type: SET_PHARMACY, pharmacy
})
export const updatePharmacy = (key, value, values) => ({
  type: UPDATE_PHARMACY, key, value, values
})
export const setBrands = (list) => ({
  type: SET_BRANDS, list
})
export const setCities = (list) => ({
  type: SET_CITIES, list
})
export const setProductsList = (list) => ({
  type: SET_PRODUCTS_LIST, list
})
export const setPharmacyToCompare = (pharmacy) => ({
  type: SET_PHARMACY_TO_COMPARE, pharmacy
})
export const updateComparingList = (data) => ({
  type: UPDATE_COMPARING_LIST, data
})
export const spliceFromComparingList = (index) => ({
  type: SPLICE_FROM_COMPARING_LIST, index
})
export const setProductToCompare = (list) => ({
  type: SET_PRODUCT_TO_COMPARE, list
})