import React, {useEffect, useState} from "react"
import {connect} from "react-redux"
import {pharmacyKeys} from "../../../../store/map";
import {parsePharmacySchedule, scheduleCell} from "../../../../store/helpers";
import { updatePharmacy } from "../../../../store/main/mainActions";

const mapStateToProps = (state) => ({
  selectedPharmacy: state.main.selectedPharmacy,
  lang: state.main.lang,
  langBase: state.main.langBase,
})

const mapDispatchToProps = ({
  updatePharmacy
})

const Schedule$ = (props) => {

  const [isModal, toggleModal] = useState(false)
  const [schedule, setSchedule] = useState()
  const [mode, setMode] = useState('handle')
  const pharmacy = props.selectedPharmacy

  const onLoad = () => {
    let temp = {}
    if (!pharmacy) return
    Object.entries(pharmacy).forEach(([key, value]) => {
      if (pharmacyKeys[key]?.inputType === 'time') {
        let day = key.substr(0, 3)
        if (!temp[day]) temp[day] = [value]
        else temp[day].push(value)
      }
    })
    setSchedule(temp)
  }

  useEffect(() => {
    onLoad()
  }, [])    // eslint-disable-line

  const s = (key) => {
    return props.langBase[key][props.lang]
  }

  const openHandler = () => {
    toggleModal(true)
  }

  const closeHandler = () => {
    toggleModal(false)
    setMode('handle')
    onLoad()
  }

  const inputHandler = (key, index) => {
    if (mode === 'everyday') return (e) => {
      let temp = Object.assign({}, schedule)
      Object.entries(temp).forEach(([key, value]) => {
        value[index] = e.target.value
      })
      setSchedule(temp)
    }
    return (e) => {
      let temp = Object.assign({}, schedule)
      temp[key][index] = e.target.value
      setSchedule(temp)
    }
  }

  const selectHandler = (key) => {
    if (mode !== 'handle') return null
    return () => {
      let temp = Object.assign({}, schedule)
      schedule[key][0] = schedule[key][0] === null ? '07:00' : null
      schedule[key][1] = schedule[key][1] === null ? '20:00' : null
      setSchedule(temp)
    }
  }

  const modeHandler = (mode) => {
    return () => {
      let temp = Object.assign({}, schedule)
      if (mode === '24-7') {
        Object.entries(temp).forEach(([key, value]) => {
          value[0] = '00:00'
          value[1] = '23:59'
        })
      }
      if (mode === 'everyday') {
        Object.entries(temp).forEach(([key, value]) => {
          value[0] = '07:00'
          value[1] = '20:00'
        })
      }
      setMode(mode)
    }
  }

  const acceptHandler = () => {
    let temp = {}
    Object.entries(schedule).forEach(([key, value]) => {
      temp[`${key}1`] = value[0]
      temp[`${key}2`] = value[1]
    })
    let parsed = parsePharmacySchedule([temp])
    parsed = parsed[0]
    props.updatePharmacy(null, null, parsed)
    closeHandler()
  }

  return (
    <div className="pharmacyEditBlock">
      {isModal &&
      <div onMouseDown={closeHandler} className="modalWrapper">
        <div onMouseDown={e => e.stopPropagation()} className="modalContainer">
          <h3>{s('Work schedule')}</h3>
          <div className="d-flex">
            <div className="d-flex fd-column">
              <button
                onClick={modeHandler('24-7')}
                disabled={mode === '24-7'}
                className="button scheduleButton mt-12"
              >{s('Around the clock')}
              </button>
              <button
                onClick={modeHandler('everyday')}
                disabled={mode === 'everyday'}
                className="button scheduleButton mt-12"
              >{s('Daily')}
              </button>
              <button
                onClick={modeHandler('handle')}
                disabled={mode === 'handle'}
                className="button scheduleButton mt-12"
              >{s('Another')}
              </button>
            </div>
            <div className="d-flex fd-column ml-12">
              {Object.entries(schedule).map(([key, value]) => (
                <div className="d-flex jc-fe mt-12 ai-center" key={key}>
                  <div
                    onClick={selectHandler(key)}
                    className={`d-flex dayBlock ${mode !== 'handle' ? 'dayBlockDisabled' : ''}`}
                  >
                    <input
                      onChange={() => void 0}
                      checked={mode === '24-7' || mode === 'everyday' || value[0] !== null}
                      type="checkbox"/>
                    <p className="ml-5">{s(key)}</p>
                  </div>
                  <input
                    disabled={mode === '24-7' || value[0] === null}
                    onChange={inputHandler(key, 0)}
                    className="input inputSchedule max-w-180 ml-10"
                    value={value[0] || ''}
                    type="time"
                  />
                  <input
                    disabled={mode === '24-7' || value[1] === null}
                    onChange={inputHandler(key, 1)}
                    className="input inputSchedule max-w-180 ml-10"
                    value={value[1] || ''}
                    type="time"
                  />
                </div>
              ))}
            </div>
          </div>

          <div className="d-flex mt-12 jc-sa">
            <button onClick={acceptHandler} className="button hover bg-blue3 mt-5">{s('Apply')}</button>
            <button onClick={closeHandler} className="button hover bg-blue3 mt-5">{s('Cancel')}</button>
          </div>

        </div>
      </div>
      }
      <p>{s('Work schedule')}</p>
      <div>
        {pharmacy?.schedule && pharmacy.schedule.map(item => (
          <p key={item.dayEnd} className="fw-500">
            {scheduleCell(item, s)}
          </p>
        ))}
      </div>
      <button onClick={openHandler} className="button hover bg-blue3 mt-5">{s('Edit schedule')}</button>
    </div>
  )
}

const Schedule = connect(mapStateToProps, mapDispatchToProps)(Schedule$)

export default Schedule