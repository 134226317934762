import React from 'react'
import './style/loader.css'

const Loader = () => {
  return (
    <div className='loaderWrapper'>
      <div className="loadingio-spinner-bars-kggn2zyokxs">
        <div className="ldio-q7hhewioal">
          <div /><div /><div /><div />
        </div>
      </div>
    </div>
  )
}

export default Loader