import store from './store'

export const getToday = () => {
  return new Date(Date.now()).toISOString().split('T')[0]
}

export const getYesterday = () => {
  return new Date(Date.now() - 1000 * 60 * 60 * 24).toISOString().split('T')[0]
}

export const request = async (url, options, isBool) => {
  const handleError = (e, status) => {
    let error = {
      url: url,
      body: options.body,
      method: options.method,
      message: e?.message || 'No error message',
      stack: e?.stack || 'No error stack',
      status: status,
    }
    console.error(e)
    store.dispatch({type: 'HANDLE_ERROR', error})
    // fetch(
    //   'https://tmc.lll.org.ua/error_bot/error',
    //   {
    //     method: 'POST',
    //     headers: {'Content-Type': 'application/json'},
    //     body: JSON.stringify(error)
    //   }
    // )
    return {error}
  }
  let status
  let response = await fetch(url, options)
    .then(res => {
      status = res.status
      if (isBool) return res.status === 200
      if (res.status === 204) return []
      return res.json().catch(e => handleError(e, status))
    })
    .catch(e => handleError(e, status))
  if (response.error) handleError(response.error, status)
  // console.log('\n', path, '\n', options, '\n', response, '\n', '\n')
  return new Promise(resolve => resolve(response))
}

export const generateOptions = (method, body, token) => {
  const TOKEN = localStorage.getItem('marketplace911Token')
  return {
    method: method,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token || TOKEN
    },
    body: JSON.stringify(body)
  }
}

export const generateURL = (query, params) => {
  let keys = Object.keys(params)
  if (keys.length === 0) return query
  let url = query
  url += '?'
  for (let i = 0; i < keys.length; i++) {
    if (!params[keys[i]]) continue
    url += `${keys[i]}=${params[keys[i]]}&`
  }
  url = url.substr(0, url.length - 1)
  return url
}

// export const getSearchParams = () => {
//   let path = window.location.search
//   if (!path) return false
//   let string = path.split('?')[1]
//   let options = string.split('&')
//   let result = {}
//   for (let i = 0; i < options.length; i++) {
//     let pairs = options[i].split('=')
//     result[pairs[0]] = pairs[1]
//   }
//   return result
// }

export const parseDate = (dateString, isDateOnly, isTimeOnly) => {
  if (dateString) {
    let splitted = dateString.split('T')
    let date = `${splitted[0].split('-')[2]}.${splitted[0].split('-')[1]}.${splitted[0].split('-')[0]}`
    let time = splitted[1].split('.')[0]
    if (isDateOnly) return date
    if (isTimeOnly) return time
    return date + ' ' + time
  } else {
    return '---'
  }
}

export const parseDateOnly = (dateString, delimiter) => {
  if (dateString) {
    let splitted = dateString.split('T');
    let splittedDate = splitted[0].split('-');
    return `${splittedDate[2]}${delimiter}${splittedDate[1]}${delimiter}${splittedDate[0]}`;
  } else {
    return ''
  }
}

export const parseDataToRender = (data) => {
  let obj = Object.assign({}, data)
  Object.keys(obj).forEach(key => {
    let isBool = key.substr(0, 2) === 'is'
    if (isBool) {
      if (obj[key] === 0 || obj[key] === false) obj[key] = '0'
      if (obj[key] === 1 || obj[key] === true) obj[key] = '1'
      if (obj[key] === null) obj[key] = 'null'
    } else {
      if (obj[key] === null) obj[key] = ''
    }
  })
  return obj
}

export const parseDataToSend = (data) => {
  let obj = Object.assign({}, data)
  Object.keys(obj).forEach(key => {
    let isBool = key.substr(0, 2) === 'is'
    if (isBool) {
      if (obj[key] === '0') obj[key] = 0
      if (obj[key] === '1') obj[key] = 1
      if (obj[key] === 'null') obj[key] = null
    } else {
      if (obj[key] === '') obj[key] = null
    }
    if (key === 'lat' || key === 'lng') {
      obj[key] = +obj[key]
    }
  })

  return obj
}

export const parsePharmacySchedule = (list) => {
  let scheduleData = ['mon', 'tue', 'wen', 'thu', 'fri', 'sat', 'sun']
  let pharmacies = list.slice()
  pharmacies.forEach(pharmacy => {
    let schedule = []
    scheduleData.forEach((day) => {
      let scheduleString = `${pharmacy[day + '1']}-${pharmacy[day + '2']}`
      if (!pharmacy[day + '1']) scheduleString = null
      let daySchedule = {dayStart: day, dayEnd: day, scheduleString}
      schedule.push(daySchedule)
    })
    let filtered = []
    schedule.forEach((item, index) => {
      if (index === 0) {
        filtered.push(item)
        return null
      }
      let lastFiltered = filtered[filtered.length - 1]
      if (lastFiltered?.scheduleString === item.scheduleString) {
        lastFiltered.dayEnd = item.dayEnd
        return null
      }
      filtered.push(item)
    })
    pharmacy.schedule = filtered
  })
  return pharmacies
}

// export const getPharmaciesList = async () => {
//   let path = 'https://tmc.lll.org.ua/call_center_reviews_api/pharmacy'
//   let response = await request(path)
//   return new Promise(resolve => resolve(response))
// }

// export const excelExport = (list) => {
//   let data = []
//   for (let i = 0; i < list.length; i++) {
//     let obj = {}
//     obj['Ключ'] = list[i].key
//     data.push(obj)
//   }
//   const wb = XLSX.utils.book_new()
//   const ws = XLSX.utils.json_to_sheet(data)
//   ws['!cols'] = calcColumnWidth(data)
//   XLSX.utils.book_append_sheet(wb, ws, "Sales")
//   XLSX.writeFile(wb, 'Sales.xlsx')
// }

export const getPartnerName = (userData, lang) => {
  // console.log(userData)
  lang = lang.toUpperCase()
  let user = userData.user
  let partner = user?.[`partnerName${lang}`]
  partner = partner || user?.['partnerNameRU']
  return partner
}

export const getNewPharmacyTemplate = () => {
  return {
    addressRegion: null,
    addressAreaId: null,
    addressCity: null,
    addressCityId: null,
    addressDistrict: null,
    addressDistrictId: null,
    addressHouse: null,
    addressRU: null,
    addressStreet: null,
    addressStreetId: null,
    addressText: null,
    addressUA: null,
    aliasName: null,
    brandId: null,
    brandNameRU: null,
    brandNameUA: null,
    descr: null,
    // id: 2,
    mon1: null,
    mon2: null,
    thu1: null,
    thu2: null,
    tue1: null,
    tue2: null,
    fri1: null,
    fri2: null,
    wen1: null,
    wen2: null,
    sat1: null,
    sat2: null,
    sun1: null,
    sun2: null,
    insDate: null,
    isActive: null,
    isBikeParking: null,
    isDeliveryCourier: null,
    isDeliveryMeest: null,
    isDeliveryNP: null,
    isDeliveryUP: null,
    isOrderAvailable: null,
    isParking: null,
    isPaySystems: null,
    isPostmate: null,
    isTakeOrders: null,
    lat: null,
    lng: null,
    location: null,
    operatingMode: null,
    orgId: null,
    partnerId: null,
    pharmacyId: null,
    pharmacyNameRU: null,
    pharmacyNameUA: null,
    phones: null,
    pointCode: null,
    pointId: null,
    postCode: null,
    regionId: null,
    remainsUpdatedAt: null,
    sessionId: null,
    siteLikiId: null,
    updDate: null,
    userId: null,
    workMode: null,
  }
}

export const scheduleCell = (item, s) => {
  let day1 = item.dayStart
  let day2 = item.dayEnd
  let time = item.scheduleString
  if (!time) time = s('Day off')
  let days = ''
  if (day1 === day2) days = s(day1)
  else days = `${s(day1)}-${s(day2)}`
  return `${days}: ${time}`
}