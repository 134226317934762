import React, {useEffect, useRef, useState} from 'react';
import ModalMessage from "./modalMessage";
import Loader from "./Loader";
import {
  fetchName, resetFilter, setFilter,
  setSelectedName, setClearCheck, setClearPartner,
  setNameProduct, setNameProductTwo, getProductList, setClearEnd,
  setClearArt, setNamePartner, setNoShowBlockedPosition, setLensArtCodePartner,
  fetchNameReceived, setSelectAndOr, setUpdatePartner, logInFailed
} from "../../store/bundle/bundleActions";
import {connect} from "react-redux";
import {useSortableData} from "./hooks/useSortableData"
import {
  transferDataFromManual, transferDataFromProducts, transferDataPartnerFromManual,
  getAllForCheck, updateManualSetHideRow, fetchNameRequest
} from "../../store/requests";

const mapStateToProps = (state) => ({
  loading: state.bundle.loading,
  NewNames: state.bundle.NewNames,
  selectName: state.bundle.selectName,
  clearCheck: state.bundle.clearCheck,
  clearEnd: state.bundle.clearEnd,
  clearPartner: state.bundle.clearPartner,
  // listPartners: state.bundle.listPartners,
  idPartner: state.bundle.idPartner,
  nameProduct: state.bundle.nameProduct,
  nameProductTwo: state.bundle.nameProductTwo,
  namePartner: state.bundle.namePartner,
  noShowBlockedPosition: state.bundle.noShowBlockedPosition,
  lensArtCodePartner: state.bundle.lensArtCodePartner,
  selectAndOr: state.bundle.selectAndOr,
  updatePartner: state.bundle.updatePartner,
})
const mapDispatchToProps = ({
  fetchName, resetFilter, setFilter,
  setSelectedName, setClearCheck, setClearPartner,
  setNameProduct, setNameProductTwo, getProductList, setClearEnd,
  setClearArt, setNamePartner, setNoShowBlockedPosition, setLensArtCodePartner,
  fetchNameReceived, setSelectAndOr, setUpdatePartner, logInFailed
})

const $ArtCodePartner = (props) => {

  const inputName = useRef(null);
  const inputNameTwo = useRef(null);
  const [clearLocal, setClearLocal] = useState(false);
  const {items, requestSort, sortConfig} = useSortableData(props.NewNames || []);

  useEffect(() => {
    if (props.clearPartner) { //признак очистки средней части стартовой страницы
      // console.log('юзэфект партнер ВОШЛИ!!!')
      if (!clearLocal) {  //при локальной инициализации очистки, ничего в этом разделе не трогать
        //очищаем здесь фильтры(средняя часть стартовой страницы), данные таблицы очистим в нижней части
        cleanName()
      }
      props.setSelectedName(null) //Очищаем в любом случае выделенную для сопоставления запись
      props.setClearCheck(true) //очищаем дальше, в нижней части стартовой страницы
      props.setClearPartner(false)  //Сбрасываем признак очистки
    }
  }, [props.clearPartner])  // eslint-disable-line

  useEffect(() => {
    // console.log('юзэфект локальная очистка')
    if (clearLocal && props.clearEnd) { //признаки, что очистка окончена и что была запущена локально
      // console.log('юзэфект локальная очистка  ВОШЛИ!!!')
      selectFromTable().then(null)
      setClearLocal(false)
    }
  }, [props.clearEnd])// eslint-disable-line

  useEffect(() => {
    // console.log('юзэфект локальная очистка')
    if (props.updatePartner) {  //признаки, что нужно обновить список партнеров и данные из таблицы
      updateData().then(null)
      setClearLocal(false)
    }
  }, [props.updatePartner]) // eslint-disable-line

  const updateData = async () => {
    // let idPartners = await getPartnerId()
    // let findPartner = props.listPartners.filter(item => item.partner_ID === props.idPartner)
    // console.log('idPartners: ', idPartners)
    // console.log('findPartner: ', findPartner)
    // console.log('props.idPartner : ', props.idPartner)
    // // если к-во строк в таблице изменилось, изменится название партнера
    // props.setNamePartner(findPartner.partnerNameUA)
    // props.setListPartners(idPartners)
    //После сопоставления обновить список партнеров и данные в таблице
    await getDataFromManual(props.noShowBlockedPosition)
    //закончить обновление данных в средней части(партнеры, содержимое таблицы)
    props.setUpdatePartner(false)
  }

  const cleanName = () => {
    props.resetFilter()
    inputName.current.value = ''
    inputNameTwo.current.value = ''
    props.setNamePartner('')
    props.setNameProduct('')
    props.setNameProductTwo('')
    // props.setClearArt(true);
  }

  const cleanNameLocal = () => {
    props.setClearPartner(true)
  }

  const selectName = (id) => {
    if (id === props.selectName) {
      props.setSelectedName(null)
    } else {
      props.setSelectedName(id)
    }
  }

  const filterInfo = () => {
    if (inputName.current.value.length < 3) {
      inputNameTwo.current.value = ''
      props.resetFilter()
      props.setNameProductTwo('')
    }
    if (inputName.current.value.length > 2) {
      props.setFilter(inputName.current.value)
      props.setNameProduct(inputName.current.value)
    }

  }

  const filterInfoTwo = () => {
    if (inputName.current.value.length < 3) return
    if (inputNameTwo.current.value.length > 2) {
      props.setNameProductTwo(inputNameTwo.current.value)
    }

  }

  const runSelectFromTable = async () => {
    //признак окончания очистки
    props.setClearEnd(false)
    //Начало очистки со средней части страницы. По окончанию очистки отловим признак и продолжим
    props.setClearPartner(true)
    //Признак того, что очистка запущена именно в среднем разделе. Для отлова окончания очистки
    setClearLocal(true)
  }

  const selectFromTable = async () => {  //вызывается после очистки главной страницы из useEffect
    await getDataFromManual(props.noShowBlockedPosition)
    if (props.nameProduct) {  //поиск по нажатию кнопки, т.е. и партнер есть и название товара
      await getDataFromProducts()
    }
  }

  // заполнение ext_Products_forManualImport | параметр потому,
  // что не успевает инициализироваться при переключении чекбокса
  const getDataFromManual = async (flagShowBlocked) => {
    if (props.nameProduct) {  //поиск по нажатию кнопки, т.е. и партнер есть и название товара
      let param = {
        idPartner: props.idPartner,
        nameProduct: props.nameProduct,
        nameProductTwo: props.nameProductTwo,
        andOr: props.selectAndOr,
        showBlocked: flagShowBlocked ? 0 : 1
      };
      let countRows = await transferDataFromManual(param)
      if (countRows.rowsAffected[0]) {

        props.fetchName() //все записи из Manual
        let result = await fetchNameRequest({partner_id: props.idPartner})
        if (result.error) {
          props.logInFailed(false)
          return
        }
        props.fetchNameReceived(result)


      }
    } else {  //поиск после выбора партнера, т.е. 200 записей из табл мануал этого партнера
      let param1 = {
        idPartner: props.idPartner,
        showBlocked: flagShowBlocked ? 0 : 1
      };
      let countRows3 = await transferDataPartnerFromManual(param1)
      if (countRows3.rowsAffected[0]) {
        props.fetchName() //все записи из Manual
        let result = await fetchNameRequest({partner_id: props.idPartner})
        if (result.error) {
          props.logInFailed(false)
          return
        }
        props.fetchNameReceived(result)
      }
    }
  }

  const getDataFromProducts = async () => {     //вызывается после очистки главной страницы из useEffect(В нижний блок)
    // console.log('IdPartner: ', props.idPartner);
    // console.log('nameProduct: ', props.nameProduct);
    let param = {
      idPartner: props.idPartner,
      nameProduct: props.nameProduct,
      nameProductTwo: props.nameProductTwo,
      andOr: props.selectAndOr
    };
    let countRows1 = await transferDataFromProducts(param);
    // console.log('countRows1.rowsAffected[0]: ', countRows1.rowsAffected[0]);
    if (countRows1.rowsAffected[0]) {
      let dataForCheck = await getAllForCheck({partner_id: props.idPartner});        //все записи из Check
      // console.log('dataForCheck: ', dataForCheck);
      props.getProductList(dataForCheck);
    }
  }

  const getClassNamesFor = (name) => {
    return sortConfig && sortConfig.key === name ? sortConfig.direction + ' ' + name : name;
  };

  const checkedShowBlockedPosition = async (e) => {
    // console.log('e.target.checked: ', e.target.checked);
    await props.setNoShowBlockedPosition(e.target.checked);
    await getDataFromManual(e.target.checked);
  }

  const showLens = () => {
    props.setLensArtCodePartner(!props.lensArtCodePartner);
  }

  const checkHide = async (e, index) => {
    let flag = e.target.checked;
    // let arrLocal = props.NewNames.slice();
    // arrLocal[index].dontNeedToMatch = flag ? 1 : 0;
    // fetchNameReceived(arrLocal);
    // console.log('flag: ', flag, '  index = ', index);
    let param = {
      Partner_ID: props.NewNames[index].Partner_ID,
      SKU_ID_Partner: props.NewNames[index].SKU_ID_Partner + '',
      SKU_Name: props.NewNames[index].SKU_Name,
      producer: props.NewNames[index].producer,
      Apteka_ID: props.NewNames[index].Apteka_ID + '',
      id: props.NewNames[index].id,
      isShow: flag ? 1 : 0
    };
    // console.log('checkHide param ', param);
    // let retData =
    await updateManualSetHideRow(param);

    // console.log('проапдейтили? ', retData);
    // console.log('проапдейтили? retData.rowsAffected[0] ', retData.rowsAffected[0]);
    // if(retData.rowsAffected[0]) {
    await getDataFromManual(props.noShowBlockedPosition);
    // }
  }


  const changeSelectAndOr = () => {
    // console.log('props.selectAndOr = ', props.selectAndOr);
    if (props.selectAndOr === 'and') {
      props.setSelectAndOr('or');
    } else {
      props.setSelectAndOr('and');
    }
  }

  const reselectFromTable = async () => {
    await getDataFromManual(props.noShowBlockedPosition);
  }

  // console.log('render')

  return (
    <div className='wrapper_partner_art'>
      <ModalMessage/>
      {props.loading ? <Loader/> : null}
      <div className='wrapper_sub_partner_art wrapper_name'>
        <h4 className='wrapper_title_title'>Выберите наименование</h4>
      </div>
      <div className='wrapper_sub_partner_art'>
        <label htmlFor="artName_" className="hmlabel">Название препарата</label>
        <input id="artName_"
               type='text'
               ref={inputName}
               onChange={filterInfo}
               className='input_art_code'/>

        <label className="hmlabelTwo" onClick={changeSelectAndOr}>{props.selectAndOr === 'and' ? 'и' : 'или'}</label>
        <input type='text'
               ref={inputNameTwo}
               onChange={filterInfoTwo}
               className='input_art_code' disabled={inputName.current?.value.length < 3}/>

        {/*<label htmlFor="partnerId_" className="hmlabel">Партнер</label>*/}
        {/*<select id="climeTypeName" onChange={filterPartner}*/}
        {/*        className='input_partner'*/}
        {/*        value={props.namePartner}>*/}
        {/*    { props.listPartners.map(item => {*/}
        {/*        return (*/}
        {/*            <option key={item.partner_ID} id={item.partner_ID} value={item.partnerNameUA}>*/}
        {/*                /!*{item.partner_ID === 0 ? '' : item.partnerNameUA + ' <' + item.partner_ID + '>  (' + item.amount + ')'}*!/*/}
        {/*                {item.partnerNameUA}*/}
        {/*            </option>*/}
        {/*        )*/}
        {/*    })*/}
        {/*    }*/}
        {/*</select>*/}
      </div>
      <div className='wrapper_sub_partner_art'>
        <button className='nice_button' onClick={reselectFromTable}>Обновить</button>
        <button className='nice_button' onClick={cleanNameLocal}>Очистить</button>
        <button className='nice_button' disabled={inputName.current?.value.length < 3}
                onClick={runSelectFromTable}>Найти
        </button>
        <input id="noShowBlocked" type="checkbox" className="checkNoShowBlocked" checked={props.noShowBlockedPosition}
               onChange={checkedShowBlockedPosition}/>
        <label htmlFor="noShowBlocked" className="labelNoShowBlocked">Не отображать заблокированные позиции</label>
        <div className={props.lensArtCodePartner ? 'decrease' : 'increase'} onClick={showLens} />
      </div>
      <div className='table_wrapper'>
        <table className='table table_name'>
          <thead>
          <tr>
            <th className='numpp'>№</th>
            <th className='hideCheckBox'>hide</th>
            {/*<th className='table_art_name'>Арткод</th>*/}
            <th onClick={() => requestSort('SKU_ID_Partner')}
                className={getClassNamesFor('SKU_ID_Partner')}>Код
            </th>
            {/*<th className='table_art_producer_title'>Название</th>*/}
            <th onClick={() => requestSort('SKU_Name')}
                className={getClassNamesFor('SKU_Name')}>Название
            </th>
            {/*<th className='table_art_prod_last'>Производитель</th>*/}
            <th onClick={() => requestSort('producer')}
                className={getClassNamesFor('producer')}>Производитель
            </th>
            {/*<th className='table_art_prod_last'>Кол-во в упаковке</th>*/}
            <th onClick={() => requestSort('quantity')}
                className={getClassNamesFor('quantity')}>Кол-во в упаковке
            </th>
            {/*<th className='table_art_prod_last'>Код морион</th>*/}
            <th onClick={() => requestSort('SKU_ID_Morion')}
                className={getClassNamesFor('SKU_ID_Morion')}>Код морион
            </th>
            {/*<th className='table_art_prod_last'>Заводской ш-код</th>*/}
            <th onClick={() => requestSort('barcode')}
                className={getClassNamesFor('barcode')}>Заводской ш-код
            </th>
            {/*<th className='table_art_prod_last'>Код Оптима</th>*/}
            <th onClick={() => requestSort('SKU_ID_Optima')}
                className={getClassNamesFor('SKU_ID_Optima')}>Код Оптима
            </th>
            {/*<th className='table_art_prod_last'>Код БАДМ</th>*/}
            <th onClick={() => requestSort('SKU_ID_Badm')}
                className={getClassNamesFor('SKU_ID_Badm')}>Код БАДМ
            </th>
            {/*<th className='table_art_prod_last'>Кратность</th>*/}
            <th onClick={() => requestSort('unit_koef')}
                className={getClassNamesFor('unit_koef')}>Кратность
            </th>
            {/*<th className='table_art_prod_last'>Цена</th>*/}
            <th onClick={() => requestSort('price')}
                className={getClassNamesFor('price')}>Цена
            </th>
            {/*<th className='table_art_prod_last'>Код мерчанта</th>*/}
            <th onClick={() => requestSort('Partner_ID')}
                className={getClassNamesFor('Partner_ID')}>Код мерчанта
            </th>
            <th className='partibilityFactor'>Ч.</th>
          </tr>
          </thead>
          <tbody>
          {items.length > 0 && items.map((post, index) => {
              // if (post.isFilter && post.isFilterProduct && (!post.dontNeedToMatch || post.dontNeedToMatch === !props.noShowBlockedPosition)) {
              if (post.isFilter && post.isFilterProduct) {
                return (
                  <tr key={post.id} className={post.id === props.selectName ? "selectedRow" : 'nonSelectedRow'}
                      onDoubleClick={(e) => selectName(post.id)}>
                    {/*<td className='textCentr'>{post.Artcode}</td>*/}
                    {/*<td className='table_art_prod_last'>{post.Name}</td>*/}
                    <td className='numpp'>{index + 1}</td>
                    <td className='hideCheckBox'><input checked={post.dontNeedToMatch}
                                                        onChange={(e) => checkHide(e, index)} type="checkbox"/></td>
                    <td className='SKU_ID_Partner'>{post.SKU_ID_Partner}</td>
                    <td className='SKU_Name left'>{post.SKU_Name}</td>
                    <td className="producer left">{post.producer || ''}</td>
                    <td className="quantity">{post.quantity}</td>
                    <td className="SKU_ID_Morion">{post.SKU_ID_Morion}</td>
                    <td className="barcode">{post.barcode}</td>
                    <td className="SKU_ID_Optima">{post.SKU_ID_Optima}</td>
                    <td className="SKU_ID_Badm">{post.SKU_ID_Badm}</td>
                    <td className="unit_koef">{post.unit_koef}</td>
                    <td className="price">{post.price ? post.price.toFixed(2) : ''}</td>
                    <td className="Partner_ID">{post.Partner_ID}</td>
                    <td className="partibilityFactor">{post.partibilityFactor}</td>
                  </tr>
                )
              } else {
                return null
              }
            }
          )}
          </tbody>
        </table>
      </div>
      <div className="footerBuffer" />
    </div>
  );
};

const ArtCodePartner = connect(mapStateToProps, mapDispatchToProps)($ArtCodePartner)

export default ArtCodePartner;