import React from "react"
import { connect } from "react-redux"
import FiltersBar from "./FiltersBar/FiltersBar";
import ButtonsBar from "./ButtonsBar/ButtonsBar";
import OrdersTable from "./OrdersTable/OrdersTable";
import OrderInfoBar from "./OrderInfoBar/OrderInfoBar";
import OrderInfo from "./OrderInfo/OrderInfo";
import { setPreload } from "../../store/main/mainActions";
import ContentHeader from "../Content/ContentHeader/ContentHeader";

const mapStateToProps = (state) => ({
  ordersList: state.main.ordersList,
  lang: state.main.lang,
  langBase: state.main.langBase
})

const mapDispatchToProps = ({
  setPreload
})

const Orders$ = (props) => {

  const s = (key) => {
    return props.langBase[key][props.lang]
  }

  return(
    <div className="wrapper d-flex fd-column">

      <ContentHeader>
        {s('Reservation')}
      </ContentHeader>
      {/*<div className="contentHeader jc-sb"><p></p></div>*/}
      <FiltersBar />
      <ButtonsBar />
      <OrdersTable />
      <OrderInfoBar />
      <OrderInfo />

    </div>
  )
}

const Orders = connect(mapStateToProps, mapDispatchToProps)(Orders$)

export default Orders