import React, { useEffect } from "react"
import { connect } from "react-redux"
import { setOrderHistory } from "../../../../store/main/mainActions";
import { getOrderHistory } from "../../../../store/requests";
import { orderHistoryTitles } from "../../../../store/dictionaries";

const mapStateToProps = (state) => ({
  activeOrderId: state.main.activeOrderId,
  orderHistory: state.main.orderHistory,
  lang: state.main.lang,
  langBase: state.main.langBase
})

const mapDispatchToProps = ({
  setOrderHistory
})

const OrderHistory$ = (props) => {

  const s = (key) => {
    return props.langBase[key][props.lang]
  }

  const orderId = props.activeOrderId
  const history = props.orderHistory

  const refreshHandler = async (orderId) => {
    props.setOrderHistory(null)
    props.setOrderHistory( await getOrderHistory(orderId) )
  }

  useEffect( () => {
    if (orderId) {
      refreshHandler(orderId).then(null)
    }
  }, [orderId] )    // eslint-disable-line

  if (!history || history?.length === 0) return(
    <div className="tableWrapper pl-12 pt-12">
      {
        history === undefined
        ?
        <p>{s('Entry not selected')}</p>
        :
        history === null
        ?
        <p>{s('Loading')}</p>
        :
        history?.length === 0
        ?
        <p>{s('No items found for this entry')}</p>
        :
        <p>{s('API error')}</p>
      }
    </div>
  )

  return(
    <div className="tableWrapper">
      <table className="table">
        <thead>
        <tr>
          { orderHistoryTitles.map( title => (
            <th key={title.key}>{title.name || title.key}</th>
          ) ) }
        </tr>
        </thead>
        <tbody>
        { history.map( (order, oIndex) => {
          if (oIndex < 100) return(
            <tr key={oIndex}>
              { orderHistoryTitles.map( title => (
                <td key={title.key}>{order[title.key]}</td>
              ) ) }
            </tr>
          )
          return null
        } ) }
        </tbody>
      </table>
    </div>
  )
}

const OrderHistory = connect(mapStateToProps, mapDispatchToProps)(OrderHistory$)

export default OrderHistory