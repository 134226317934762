import React from "react"
import { connect } from "react-redux"
import NavBar from "../NavBar/NavBar";
import { setOrdersList } from "../../../store/main/mainActions";
import { getOrdersList } from "../../../store/requests";

const mapStateToProps = (state) => ({
  filters: state.main.filters,
  lang: state.main.lang,
  langBase: state.main.langBase
})

const mapDispatchToProps = ({
  setOrdersList
})

const ButtonsBar$ = (props) => {

  const s = (key) => {
    return props.langBase[key][props.lang]
  }

  const filters = props.filters

  const getOrdersHandler = async () => {
    props.setOrdersList(null)
    props.setOrdersList( await getOrdersList(filters) )
  }

  return(
    <NavBar className="ai-center">
      <button className="navIconButton iconRefresh hover" onClick={getOrdersHandler}>{s('Refresh')}</button>
    </NavBar>
  )
}

const ButtonsBar = connect(mapStateToProps, mapDispatchToProps)(ButtonsBar$)

export default ButtonsBar