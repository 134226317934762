import React from "react"
import { connect } from "react-redux"
import { setFilter, setOrdersList } from "../../../../../store/main/mainActions";
import {getOrdersList} from "../../../../../store/requests";

const mapStateToProps = (state) => ({
  filters: state.main.filters
})

const mapDispatchToProps = ({
  setFilter, setOrdersList
})

const FilterInput$ = (props) => {

  const inputHandler = (e) => {
    props.setFilter(props.filterKey, e.target.value)
  }

  const filters = props.filters

  const getOrdersHandler = async (e) => {
    if (e.key !== 'Enter') return
    props.setOrdersList(null)
    props.setOrdersList( await getOrdersList(filters) )
  }

  return(
    <input
      onKeyDown={getOrdersHandler}
      className="filterInput"
      type={props.inputType}
      disabled={!props.filterKey}
      value={props.filters[props.filterKey] || ''}
      onChange={inputHandler}
    />
  )
}

const FilterInput = connect(mapStateToProps, mapDispatchToProps)(FilterInput$)

export default FilterInput