import React from "react"
import { connect } from "react-redux"
import './SideBar.css'
import SideBarButton from "./SideBarButton/SideBarButton";
// import {getPartnerName} from "../../../store/helpers";

const mapStateToProps = (state) => ({
  user: state.main.user,
  lang: state.main.lang,
  langBase: state.main.langBase,
  pharmaciesList: state.main.pharmaciesList,
})

const mapDispatchToProps = ({

})

const SideBar$ = (props) => {

  const s = (key) => {
    return props.langBase[key][props.lang]
  }

  const companyName = props?.user?.user?.companyName

  return(
    <div className="contentSideBar">

      <div className="sideBarHeader d-flex ai-center">
        <div className="sideBarHeaderIcon">{companyName?.substr(0,1)}</div>
        <div className="d-flex fd-column jc-center ml-12">
          <p>{companyName}</p>
          <p className="sideBarSmallTitle mt-5">{props.pharmaciesList.length} {s('trading points')}</p>
        </div>
      </div>

      <div className="sideBarButtonsContainer">
        <SideBarButton page="pharmacies" title={s('List of pharmacies')} />
        <SideBarButton page="dataImport" title={s('Data import')} />
        <SideBarButton page="goods" title={s('Goods')} />
        <SideBarButton page="reservation" title={s('Reservation')} />
        <SideBarButton page="settings" title={s('Settings')} />
        <SideBarButton page="faq" title="FAQ" />
      </div>

      {/*<div className="sideBarMessagesContainer d-flex fd-column">*/}
      {/*  <p className="sideBarMessagesTitle">{s("New incoming messages")}</p>*/}
      {/*  <p className="sideBarMessagesCount">5422</p>*/}
      {/*  <div className="sideBarProgressBarContainer">*/}
      {/*    <div style={{width: `${40}%`}} className="sideBarProgressBar" />*/}
      {/*  </div>*/}
      {/*</div>*/}

      {/*<div className="sideBarUserContainer w-100p d-flex jc-sb ai-center">*/}
      {/*  <div className="d-flex ai-center">*/}
      {/*    <div className="sideBarUserIcon" />*/}
      {/*    <p className="ml-12">{partnerName}</p>*/}
      {/*  </div>*/}
      {/*  <div className="sideBarMenuIcon">...</div>*/}
      {/*</div>*/}

    </div>
  )
}

const SideBar = connect(mapStateToProps, mapDispatchToProps)(SideBar$)

export default SideBar