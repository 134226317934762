export const langBase = {
  "welcome": {
    en: 'Welcome',
    ru: 'Добро пожаловать',
    ua: 'Ласкаво просимо',
    uz: 'Xush kelibsiz',
  },
  "login": {
    en: 'Login',
    ru: 'Логин',
    ua: 'Логін',
    uz: 'Kirish',
  },
  "password": {
    en: 'Password',
    ru: 'Пароль',
    ua: 'Пароль',
    uz: 'Parol',
  },
  "enter": {
    en: 'Enter',
    ru: 'Войти',
    ua: 'Увійти',
    uz: 'Kirish uchun',
  },
  "List of pharmacies": {
    en: 'List of pharmacies',
    ru: 'Перечень аптек',
    ua: 'Перелік аптек',
    uz: 'Dorixonalar ro\'yxati',
  },
  "Data import": {
    en: 'Data import',
    ru: 'Импорт данных',
    ua: 'Імпорт даних',
    uz: 'Ma\'lumotlarni import qilish',
  },
  "Goods": {
    en: 'Goods',
    ru: 'Товары',
    ua: 'Товари',
    uz: 'Tovarlar',
  },
  "Reservation": {
    en: 'Reservation',
    ru: 'Резервирование',
    ua: 'Резервування',
    uz: 'Rezervasyon',
  },
  "Settings": {
    en: 'Settings',
    ru: 'Настройка',
    ua: 'Налаштування',
    uz: 'Sozlamalar',
  },
  "Refresh": {
    en: 'Refresh',
    ru: 'Обновить',
    ua: 'Оновити',
    uz: 'Yangilash',
  },
  "Reservation order": {
    en: 'Reservation/order',
    ru: 'Бронь/заказ',
    ua: 'Бронь/замовлення',
    uz: 'Rezervasyon/buyurtma',
  },
  "Phone": {
    en: 'Phone',
    ru: 'Телефон',
    ua: 'Телефон',
    uz: 'Telefon',
  },
  "Order Status BP Stage": {
    en: 'Order Status/BP Stage',
    ru: 'Статус заказа/Этап БП',
    ua: 'Статус замовлення/Етап БП',
    uz: 'Buyurtma holati/BP bosqichi',
  },
  "Art code": {
    en: 'Art code',
    ru: 'Арт-код',
    ua: 'Арт-код',
    uz: 'San\'at kodi',
  },
  "Period from": {
    en: 'Period from',
    ru: 'Период с',
    ua: 'Період з',
    uz: 'Dan davr',
  },
  "Period by": {
    en: 'Period by',
    ru: 'Период по',
    ua: 'Період по',
    uz: 'Davr tomonidan',
  },
  "State": {
    en: 'State',
    ru: 'Состояние',
    ua: 'Стан',
    uz: 'Davlat',
  },
  "Pharmacy warehouse": {
    en: 'Pharmacy/warehouse',
    ru: 'Аптека/склад',
    ua: 'Аптека/склад',
    uz: 'Dorixona/omborxona',
  },
  "Delivery type": {
    en: 'Delivery type',
    ru: 'Вид доставки',
    ua: 'Вид доставки',
    uz: 'Yetkazib berish turi',
  },
  "Client": {
    en: 'Client',
    ru: 'Клиент',
    ua: 'Клієнт',
    uz: 'Mijoz',
  },
  "Address": {
    en: 'Address',
    ru: 'Адрес',
    ua: 'Адреса',
    uz: 'Manzil',
  },
  "Entry not selected": {
    en: 'Entry not selected',
    ru: 'Запись не выбрана',
    ua: 'Запис не вибрано',
    uz: 'Kirish tanlanmagan',
  },
  "No entries selected": {
    en: 'No entries selected',
    ru: 'Записи не выбраны',
    ua: 'Записи не вибрані',
    uz: 'Hech qanday yozuv tanlanmagan',
  },
  "Loading": {
    en: 'Loading',
    ru: 'Загрузка',
    ua: 'Завантаження',
    uz: 'Yuklanmoqda',
  },
  "No items found for this entry": {
    en: 'No items found for this entry',
    ru: 'Не найдено позиций по данной записи',
    ua: 'Не знайдено позицій щодо цього запису',
    uz: 'Ushbu yozuv uchun hech qanday element topilmadi',
  },
  "API error": {
    en: 'API error',
    ru: 'Ошибка API',
    ua: 'Помилка API',
    uz: 'API xatosi',
  },
  "Commodity items": {
    en: 'Commodity items',
    ru: 'Товарные позиции',
    ua: 'Товарні позиції',
    uz: 'Tovar buyumlari',
  },
  "Operations history": {
    en: 'Operations history',
    ru: 'История операций',
    ua: 'Історія операцій',
    uz: 'Operatsiyalar tarixi',
  },
  "No records found for the specified filters": {
    en: 'No records found for the specified filters',
    ru: 'Не найдено записей по заданным фильтрам',
    ua: 'Не знайдено записів за заданими фільтрами',
    uz: 'Belgilangan filtrlar uchun hech qanday yozuv topilmadi',
  },
  "Saved successfully": {
    en: 'Saved successfully',
    ru: 'Успешно сохранено',
    ua: 'Успішно збережено',
    uz: 'Muvaffaqiyatli saqlangan',
  },
  "User Settings": {
    en: 'User Settings',
    ru: 'Настройки пользователя',
    ua: 'Налаштування користувача',
    uz: 'Foydalanuvchi sozlamalari',
  },
  "Interface language": {
    en: 'Interface language',
    ru: 'Язык интерфейса',
    ua: 'Мова інтерфейсу',
    uz: 'Interfeys tili',
  },
  "Interface Theme": {
    en: 'Interface Theme',
    ru: 'Тема интерфейса',
    ua: 'Тема інтерфейсу',
    uz: 'Interfeys mavzusi',
  },
  "Save": {
    en: 'Save',
    ru: 'Сохранить',
    ua: 'Зберегти',
    uz: 'Saqlash',
  },
  "under development": {
    en: 'under development',
    ru: 'в стадии разработки',
    ua: 'у стадії розробки',
    uz: 'ishlab chiqilmoqda',
  },
  "Partner settings page": {
    en: 'Partner settings page',
    ru: 'Страница настроек партнера',
    ua: 'Сторінка налаштувань партнера',
    uz: 'Hamkor sozlamalar isahifasi',
  },
  "on the LIKI website": {
    en: 'on the LIKI website',
    ru: 'на сайте LIKI',
    ua: 'на сайті LIKI',
    uz: 'Onlayn LIKI',
  },
  "Company name": {
    en: 'Company name',
    ru: 'Название компании',
    ua: 'Назва компанії',
    uz: 'Shirkat Nomi',
  },
  "basic": {
    en: 'basic',
    ru: 'основное',
    ua: 'основне',
    uz: 'asosiy',
  },
  "Password change": {
    en: 'Password change',
    ru: 'Смена пароля',
    ua: 'Зміна пароля',
    uz: 'Parolni o\'zgartirish',
  },
  "Enter a new password": {
    en: 'Enter a new password',
    ru: 'Введите новый пароль',
    ua: 'Введіть новий пароль',
    uz: 'Yangi parolni kiriting',
  },
  "Confirm new password": {
    en: 'Confirm new password',
    ru: 'Подтвердите новый пароль',
    ua: 'Підтвердіть новий пароль',
    uz: 'Yangi parolni tasdiqlang',
  },
  "Log out": {
    en: 'Log out',
    ru: 'Выйти',
    ua: 'Вийти',
    uz: 'Chiqish',
  },
  "Password must be at least 6 characters": {
    en: 'Password must be at least 6 characters',
    ru: 'Пароль должен быть не короче 6 символов',
    ua: 'Пароль має бути не коротшим 6 символів',
    uz: 'Parol kamida 6 ta belgidan iborat bo\'lishi kerak',
  },
  "Passwords do not match": {
    en: 'Passwords do not match',
    ru: 'Пароли не совпадают',
    ua: 'Паролі не співпадають',
    uz: 'Parollar mos kelmayapti',
  },
  "Change password": {
    en: 'Change password',
    ru: 'Сменить пароль',
    ua: 'Змінити пароль',
    uz: 'Kalit so\'zni o\'zgartirish',
  },
  "mon": {
    en: 'Mon',
    ru: 'Пн',
    ua: 'Пн',
    uz: 'Dushanba',
  },
  "tue": {
    en: 'Tue',
    ru: 'Вт',
    ua: 'Вт',
    uz: 'Seshanba',
  },
  "wen": {
    en: 'Wed',
    ru: 'Ср',
    ua: 'Ср',
    uz: 'Chorshanba',
  },
  "thu": {
    en: 'Thu',
    ru: 'Чт',
    ua: 'Чт',
    uz: 'Payshanba',
  },
  "fri": {
    en: 'Fri',
    ru: 'Пт',
    ua: 'Пт',
    uz: 'Juma',
  },
  "sat": {
    en: 'Sut',
    ru: 'Сб',
    ua: 'Сб',
    uz: 'Shanba',
  },
  "sun": {
    en: 'Sun',
    ru: 'Вс',
    ua: 'Нд',
    uz: 'Yakshanba',
  },
  "Day off": {
    en: 'Day off',
    ru: 'Выходной',
    ua: 'Вихідний',
    uz: 'Dam olish kuni',
  },
  "Search in the system": {
    en: 'Search in the system',
    ru: 'Поиск в системе',
    ua: 'Пошук у системі',
    uz: 'Tizimda qidirish',
  },
  "Pharmacy name": {
    en: 'Pharmacy name',
    ru: 'Наименование аптеки',
    ua: 'Найменування аптеки',
    uz: 'Dorixona nomi',
  },
  "Schedule": {
    en: 'Schedule',
    ru: 'График',
    ua: 'Графік',
    uz: 'Jadval',
  },
  "Bundle": {
    en: 'Bundle',
    ru: 'Связка',
    ua: 'Зв\'язка',
    uz: 'To\'plam',
  },
  "Data to the site": {
    en: 'Data to the site',
    ru: 'Данные на сайт',
    ua: 'Дані на сайт',
    uz: 'Saytga ma\'lumotlar',
  },
  "New incoming messages": {
    en: 'New incoming messages',
    ru: 'Новых входящих сообщений',
    ua: 'Нових вхідних повідомлень',
    uz: 'Yangi kiruvchi xabarlar',
  },
  "Add pharmacy": {
    en: 'Add pharmacy',
    ru: 'Добавить аптеку',
    ua: 'Додати аптеку',
    uz: 'Dorixona qo\'shing',
  },
  "Pharmacy settings": {
    en: 'Pharmacy settings',
    ru: 'Настройки аптеки',
    ua: 'Налаштування аптеки',
    uz: 'Dorixona sozlamalari',
  },
  "Add": {
    en: 'Add',
    ru: 'Добавить',
    ua: 'Додати',
    uz: 'Qo\'shmoq',
  },
  "Back": {
    en: 'Back',
    ru: 'Назад',
    ua: 'Назад',
    uz: 'Batsk',
  },
  "City": {
    en: 'City',
    ru: 'Город',
    ua: 'Місто',
    uz: 'Shahar',
  },
  "trading points": {
    en: 'trading points',
    ru: 'торговых точек',
    ua: 'торгівельних точок',
    uz: 'savdo nuqtalari',
  },
  "Work schedule": {
    en: 'Work schedule',
    ru: 'График работы',
    ua: 'Графік роботи',
    uz: 'Ish tartibi',
  },
  "Edit schedule": {
    en: 'Edit schedule',
    ru: 'Редактировать график',
    ua: 'Редагувати графік',
    uz: 'Jadvalni tahrirlash',
  },
  "Choose a city": {
    en: 'Choose a city',
    ru: 'Выберите город',
    ua: 'Оберіть місто',
    uz: 'Shaharni tanlang',
  },
  "Apply": {
    en: 'Apply',
    ru: 'Применить',
    ua: 'Примінити',
    uz: 'Murojaat qiling',
  },
  "Cancel": {
    en: 'Cancel',
    ru: 'Отменить',
    ua: 'Скасувати',
    uz: 'Bekor qilish',
  },
  "Reset": {
    en: 'Reset',
    ru: 'Сбросить',
    ua: 'Скинути',
    uz: 'Qayta o\'rnatish',
  },
  "Not found": {
    en: 'Not found',
    ru: 'Не найдено',
    ua: 'Не знайдено',
    uz: 'Topilmadi',
  },
  "Start typing the name of the city": {
    en: 'Start typing the name of the city',
    ru: 'Начните вводить название города',
    ua: 'Почніть вводити назву міста',
    uz: 'Shahar nomini yozishni boshlang',
  },
  "Around the clock": {
    en: 'Around the clock',
    ru: 'Круглосуточно',
    ua: 'Цілодобово',
    uz: 'Kunduzi',
  },
  "Daily": {
    en: 'Daily',
    ru: 'Каждый день',
    ua: 'Щодня',
    uz: 'Kundalik',
  },
  "Another": {
    en: 'Another',
    ru: 'Другой',
    ua: 'Інший',
    uz: 'Boshqa',
  },
  "Choose a brand": {
    en: 'Choose a brand',
    ru: 'Выберите бренд',
    ua: 'Оберіть бренд',
    uz: 'Brendni tanlang',
  },
  "Start typing your brand name": {
    en: 'Start typing your brand name',
    ru: 'Начните вводить название бренда',
    ua: 'Почніть вводити назву бренду',
    uz: 'Brend nomini yozishni boshlang',
  },
  "Add a new brand": {
    en: 'Add a new brand',
    ru: 'Добавить новый бренд',
    ua: 'Додати новий бренд',
    uz: 'Yangi brend qo\'shing',
  },
  "Edit brand": {
    en: 'Edit brand',
    ru: 'Редактировать бренд',
    ua: 'Редагувати бренд',
    uz: 'Brendni tahrirlash',
  },
  "Add a brand": {
    en: 'Add a brand',
    ru: 'Добавить бренд',
    ua: 'Додати бренд',
    uz: 'Brend qo\'shing',
  },
  "Title in Ukrainian": {
    en: 'Title in Ukrainian',
    ru: 'Название на украинском языке',
    ua: 'Назва українською мовою',
    uz: 'Sarlavha ukrain tilida',
  },
  "Title in russian": {
    en: 'Title in russian',
    ru: 'Название на русском языке',
    ua: 'Назва російською мовою',
    uz: 'Sarlavha rus tilida',
  },
  "Title in Uzbek": {
    en: 'Title in Uzbek',
    ru: 'Название на узбекском языке',
    ua: 'Назва узбекською мовою',
    uz: 'Nomi o\'zbekcha',
  },
  "Booking period": {
    en: 'Booking period',
    ru: 'Срок бронирования',
    ua: 'Термін бронювання',
    uz: 'Rezervasyon muddati',
  },
  "": {
    en: '',
    ru: '',
    ua: '',
    uz: '',
  },
}