import React, {useEffect, useState} from "react"
import {connect} from "react-redux"
import {updatePharmacy} from "../../../../store/main/mainActions";

const mapStateToProps = (state) => ({
  lang: state.main.lang,
  langBase: state.main.langBase,
  selectedPharmacy: state.main.selectedPharmacy,
  cities: state.main.cities,
})

const mapDispatchToProps = ({
  updatePharmacy
})

const City$ = (props) => {

  const s = (key) => {
    return props.langBase[key][props.lang]
  }

  const [isModal, toggleModal] = useState(false)
  const [filter, setFilter] = useState('')
  const [list, setList] = useState([])
  const [city, setCity] = useState(null)
  const pharmacy = props.selectedPharmacy
  const cityName = props.cities?.find(item => item.code === pharmacy.regionId)?.townUa

  const onLoad = () => {
    let current = props.cities.find(item => item.code === pharmacy.regionId)
    if (current) setCity(current)
  }

  useEffect(() => {
    if (filter.length === 0) return
    let temp = props.cities?.filter(item => item.townUa?.toLowerCase()?.includes(filter.toLowerCase()))
    temp = temp.slice(0, 10)
    setList(temp)
  }, [filter])    // eslint-disable-line

  const filterHandler = (e) => {
    if (e.target.value.length === 0) {
      setList([])
    }
    setFilter(e.target.value)
  }

  const openModal = () => {
    onLoad()
    toggleModal(true)
  }

  const closeModal = () => {
    toggleModal(false)
    setFilter('')
    setList([])
    setCity(null)
  }

  const selectHandler = (item) => {
    return () => setCity(item)
  }

  const acceptHandler = () => {
    if (city) props.updatePharmacy('regionId', city.code)
    if (city) props.updatePharmacy('coordinateX', city.coordinateX)
    if (city) props.updatePharmacy('coordinateY', city.coordinateY)
    if (city) props.updatePharmacy('addressCityRu', city.townRu)
    if (city) props.updatePharmacy('addressCityRu', city.townRu)
    closeModal()
  }

  return (
    <>

      {isModal &&
      <div onMouseDown={closeModal} className="modalWrapper">
        <div onMouseDown={e => e.stopPropagation()} className="modalContainer d-flex fd-column">
          <h3>{s('Choose a city')}</h3>
          {city &&
          <div className="searchContainer mt-12">
            <div className="input">{city.townUa} {city.coordinateX ? `(${city.coordinateX}, ${city.coordinateY})` : ''}</div>
            <div onClick={selectHandler(null)} className="resetSearchButton" title={s('Reset')} />
          </div>
          }
          {!city &&
          <div className="searchContainer mt-12">
            <input placeholder={s('Start typing the name of the city')} value={filter} onChange={filterHandler} className="input" type="text"/>
            <div className="searchList">
              {list.length > 0 && list.map(item => (
                <div className="searchListItem d-flex fd-column" onClick={selectHandler(item)} key={item.code}>
                  <p>{item.townUa}</p>
                  <p className="region">{item.regionUa} обл.  {item.coordinateX ? `(${item.coordinateX}, ${item.coordinateY})` : ''}</p>
                </div>
              ))}
              {list.length === 0 && <p className="fw-500 p-15">{s('Not found')}</p>}
            </div>
          </div>
          }

          <div className="d-flex mt-12 jc-sa">
            <button onClick={acceptHandler} className="button hover bg-blue3 mt-5">{s('Apply')}</button>
            <button onClick={closeModal} className="button hover bg-blue3 mt-5">{s('Cancel')}</button>
          </div>
        </div>
      </div>
      }

      <div onClick={openModal} className="input cursor-pointer">{cityName || '---'}</div>

    </>
  )
}

const City = connect(mapStateToProps, mapDispatchToProps)(City$)

export default City