export const FETCH_ART = 'FETCH_ART'
export const FETCH_ART_RECEIVED = 'FETCH_ART_RECEIVED'
export const FETCH_NAME = 'FETCH_NAME'
export const FETCH_NAME_RECEIVED = 'FETCH_NAME_RECEIVED'
export const SET_FILTER = 'SET_FILTER'
export const SET_FILTER_ID = 'SET_FILTER_ID'
export const RESET_FILTER = 'RESET_FILTER'
export const SET_MODAL_MESSAGE = 'SET_MODAL_MESSAGE'
export const LOG_IN_FAILED = 'LOG_IN_FAILED'
export const SET_SELECTED_NAME = 'SET_SELECTED_NAME'
export const SET_SELECTED_ART = 'SET_SELECTED_ART'
export const FETCH_PRODUCT = 'FETCH_PRODUCT'
export const FETCH_PRODUCT_RECEIVED = 'FETCH_PRODUCT_RECEIVED'
export const CLOSE_MODAL_PAGE = 'CLOSE_MODAL_PAGE'
export const OPEN_MODAL_PAGE = 'OPEN_MODAL_PAGE'
export const CLEAR_CHECKED_PRODUCT = 'CLEAR_CHECKED_PRODUCT'
export const CLEAR_ALL_CHECKED_PRODUCT = 'CLEAR_ALL_CHECKED_PRODUCT'
export const SET_SHOW_IMPORT_PAGE = 'SET_SHOW_IMPORT_PAGE'
export const SET_DATA_IMPORT = 'SET_DATA_IMPORT'
export const SET_IMPORT_COLUMN_SORT = 'SET_IMPORT_COLUMN_SORT'
export const GET_PRODUCT_LIST = 'GET_PRODUCT_LIST'
export const ART_CLEAR = 'ART_CLEAR'
export const SET_SHOW_SET_COLUMN_IMPORT = 'SET_SHOW_SET_COLUMN_IMPORT'
export const SET_IS_MOVING = 'SET_IS_MOVING'
export const SET_CONTAINER_POS = 'SET_CONTAINER_POS'
export const SET_SHOW_UNDOCKING = 'SET_SHOW_UNDOCKING'
export const SET_EXT_PRODUCTS = 'SET_EXT_PRODUCTS'
export const SET_CLEAR_ART = 'SET_CLEAR_ART'
export const SET_CLEAR_PARTNER = 'SET_CLEAR_PARTNER'
export const SET_CLEAR_CHECK = 'SET_CLEAR_CHECK'
export const SET_CLEAR_END = 'SET_CLEAR_END'
export const SET_ID_PARTNER = 'SET_ID_PARTNER'
export const SET_NAME_PRODUCT = 'SET_NAME_PRODUCT'
export const SET_NAME_PRODUCT_TWO = 'SET_NAME_PRODUCT_TWO'
export const SET_NAME_PARTNER = 'SET_NAME_PARTNER'
export const SET_NO_SHOW_BLOCKED_POSITION = 'SET_NO_SHOW_BLOCKED_POSITION'
export const SET_LENS_ARTCODE_PARTNER = 'SET_LENS_ARTCODE_PARTNER'
export const SET_SELECT_AND_OR = 'SET_SELECT_AND_OR'
export const SET_UPDATE_PARTNER = 'SET_UPDATE_PARTNER'
export const TOGGLE_LOADING = 'TOGGLE_LOADING'