export const orderTitles = [
  {name: 'Внешний номер', key: 'extId'},
  // {name: 'ID заказа', key: 'orderId'},
  {name: 'Сумма заказа', key: 'orderAmount'},
  // {name: 'Телефон клиента', key: 'orderPhone'},
  {name: 'Адрес', key: 'orderShipStreet'},
  {name: 'Дата заказа', key: 'orderDt'},
  {name: 'ID аптеки', key: 'aptekaId'},
  {name: 'Статус заказа', key: 'orderStatusText'},
  // {name: 'ID статуса заказа', key: 'orderStatus'},
  {name: 'Город', key: 'orderShipCity'},
  // {name: 'Валюта', key: 'orderCurrency'},
  // {name: 'Вид доставки', key: 'orderShipping'},
  // {name: 'Вид оплаты', key: 'orderPayment'},
  // {name: 'Email', key: 'orderEmail'},
  // {name: 'Клиент', key: 'orderShipName'},
  // {name: '', key: 'idIpaDhRes'},
  // {name: 'Дата создания', key: 'createDate'},
  // {name: '', key: 'nUser'},
  // {name: '', key: 'stateArmour'},
  // {name: '', key: 'idDocBell'},
  // {name: '', key: 'signBell'},
  // {name: '', key: 'bellDate'},
  // {name: '', key: 'smsDate'},
  // {name: 'Дата оплаты', key: 'payDate'},
  // {name: '', key: 'assemblingDate'},
  // {name: '', key: 'dispatchDeclaration'},
  // {name: '', key: 'note'},
  // {name: 'Дата закрытия', key: 'closeDate'},
  // {name: '', key: 'stateConnection'},
  // {name: 'Дата статуса', key: 'orderStatusDate'},
  // {name: 'Комментарий', key: 'orderComment'},
  // {name: '', key: 'signNew'},
  // {name: '', key: 'geoGroupPharm'},
  // {name: '', key: 'export1CDate'},
  // {name: '', key: 'orderAmountShipping'},
  // {name: '', key: 'npostStateId'},
  // {name: '', key: 'npostStateDate'},
  // {name: '', key: 'parentOrderId'},
  // {name: '', key: 'blackListDate'},
  // {name: '', key: 'dateReceived'},
  // {name: '', key: 'childDateReceived'},
  // {name: '', key: 'stockDateBegin'},
  // {name: '', key: 'pharmAssemblyDate'},
  // {name: '', key: 'pharmAssemblyUser'},
  // {name: '', key: 'orderAmountCod'},
  // {name: '', key: 'orderAdds'},
  // {name: '', key: 'coolantSum'},
  // {name: '', key: 'declarationReturn'},
  // {name: '', key: 'extSystem'},
  // {name: '', key: 'orderName'},
  // {name: '', key: 'statusPay'},
  // {name: '', key: 'statusApp'},
  // {name: '', key: 'statusShip'},
  // {name: '', key: 'srcSystem'},
  // {name: '', key: 'srcId'},
  // {name: '', key: 'updateDate'},
  // {name: '', key: 'currHistoryId'},
  // {name: '', key: 'execSign'},
  // {name: '', key: 'bpId'},
  // {name: '', key: 'mPhone'},
  // {name: '', key: 'refId'},
  // {name: '', key: 'operatorId'},
  // {name: '', key: 'promoCode'},
  // {name: '', key: 'pharmAssemblyStatus'},
  // {name: '', key: 'categoryId'},
  // {name: '', key: 'originPharmacyId'},
  // {name: '', key: 'orderAmountDiscount'},
  // {name: '', key: 'deliveryType'},
  // {name: '', key: 'promoActionId'},
  // {name: '', key: 'request'},
  // {name: '', key: 'shippingId'},
  // {name: '', key: 'paymentId'},
  // {name: '', key: 'referSystem'},
  // {name: '', key: 'referCode'},
  // {name: '', key: 'docUrl'},
  // {name: '', key: 'extPointId'},
  // {name: '', key: 'bookingBeforeDate'},
  // {name: '', key: 'customerId'},
  // {name: '', key: 'estimatedDeliveryDateStr'},
  // {name: '', key: 'chck'},
  // {name: '', key: 'pId'},
  // {name: '', key: 'pPartnerId'},
  // {name: '', key: 'pPharmacyId'},
  // {name: '', key: 'pPharmacyNameRu'},
  // {name: '', key: 'pPharmacyNameUa'},
  // {name: '', key: 'pPointCode'},
  // {name: '', key: 'pPointId'},
]

export const orderDetailsTitles = [
  {name: 'Код', key: 'itemCode'},
  {name: 'Наименование', key: 'itemName'},
  {name: 'Количество', key: 'itemQuantity'},
  {name: 'Цена', key: 'itemPrice'},
  {name: 'ID аптеки', key: 'aptekaId'},
  {name: '', key: 'koefOpt'},
  {name: '', key: 'netPrice'},
  {name: '', key: 'originPrice'},
  // {name: 'ID позиции', key: 'itemId'},
  // {name: 'ID заказа', key: 'orderId'},
  // {name: 'Дата ?', key: 'armourDate'},
  // {name: '', key: 'idIpaDhRes'},
  // {name: '', key: 'idIpaDtRes'},
  // {name: '', key: 'armourDateClose'},
  // {name: 'Дата чека', key: 'checkDate'},
  // {name: '', key: 'itemCountInPresence'},
  // {name: '', key: 'typeNote'},
  // {name: '', key: 'pricePharmacy'},
  // {name: '', key: 'dateActionPickPharmacy'},
  // {name: '', key: 'nUser'},
  // {name: '', key: 'signDistribute'},
  // {name: '', key: 'signArmorTerm'},
  // {name: '', key: 'itemRemnTerm'},
  // {name: '', key: 'stateArmour'},
  // {name: '', key: 'signMeas'},
  // {name: '', key: 'signModeReserve'},
  // {name: '', key: 'idIpaJMoves'},
  // {name: '', key: 'idIpaRTovar'},
  // {name: 'Внешний номер', key: 'extId'},
  // {name: '', key: 'itemArmourQuantity'},
  // {name: '', key: 'chkId'},
  // {name: '', key: 'signAdd'},
  // {name: '', key: 'whReqId'},
  // {name: '', key: 'cancelDate'},
  // {name: '', key: 'cancelDateCnt'},
  // {name: '', key: 'jSpec'},
  // {name: '', key: 'itemDiscount'},
  // {name: '', key: 'basePrice'},
  // {name: '', key: 'tradeSet'},
  // {name: '', key: 'qtyScan'},
  // {name: '', key: 'qtyNotFound'},
  // {name: '', key: 'productCode'},
  // {name: '', key: 'codeType'},
  // {name: '', key: 'statusId'},
  // {name: 'Комментарий', key: 'comment'},
  // {name: '', key: 'pid'},
  // {name: '', key: 'promoActionId'},
  // {name: '', key: 'itemProcess'},
  // {name: '', key: 'isStarProduct'},
  // {name: '', key: 'originArtCode'},
  // {name: '', key: 'originFactor'},
  // {name: '', key: 'originMeasure'},
  // {name: '', key: 'originId'},
  // {name: '', key: 'originQty'},
]

export const orderHistoryTitles = [
  {name: 'Внешний Статус', key: 'SrcStatusId'},
  {name: 'Внутренний статус', key: 'ResStatusId'},
  {name: 'Статус', key: 'Name'},
  {name: 'Дата', key: 'InsDate'},
  {name: 'ID пользователя', key: 'UserIns'},
  {name: 'Пользователь', key: 'Name_User'},
  {name: '', key: 'SrcBasisId'},
  {name: 'Комментарий', key: 'Comments'},
  {name: '', key: 'SyncBody'},
  {name: '', key: 'SyncBodyMax'},
  {name: '', key: 'SrcId'},
  // {name: 'ID', key: 'id'},
  // {name: 'ID заказа', key: 'orderId'},
  // {name: '', key: 'actionCode'},
  // {name: '', key: 'startDate'},
  // {name: '', key: 'endDate'},
  // {name: '', key: 'userUpd'},
  // {name: '', key: 'signAuto'},
  // {name: '', key: 'iErr'},
  // {name: '', key: 'execMsg'},
  // {name: '', key: 'resBasisId'},
  // {name: '', key: 'syncSign'},
  // {name: '', key: 'srcType'},
  // {name: '', key: 'sentSmsType'},
  // {name: '', key: 'syncResponseCode'},
]