export const TOGGLE_LOADING = 'TOGGLE_LOADING'
export const HANDLE_ERROR = 'HANDLE_ERROR'

export const SET_SETTINGS_LIST = 'SET_SETTINGS_LIST'
export const SET_USER = 'SET_USER'

export const SET_PRELOAD = 'SET_PRELOAD'
export const SET_ORDERS_LIST = 'SET_ORDERS_LIST'
export const SET_ACTIVE_ORDER = 'SET_ACTIVE_ORDER'
export const SET_ORDER_INFO_MODE = 'SET_ORDER_INFO_MODE'
export const SET_ORDER_DETAILS = 'SET_ORDER_DETAILS'
export const SET_ORDER_HISTORY = 'SET_ORDER_HISTORY'
export const SET_FILTER = 'SET_FILTER'
export const SET_PAGE = 'SET_PAGE'
export const CHANGE_LANG = 'CHANGE_LANG'

export const SET_PHARMACY = 'SET_PHARMACY'
export const UPDATE_PHARMACY = 'UPDATE_PHARMACY'
export const SET_BRANDS = 'SET_BRANDS'
export const SET_CITIES = 'SET_CITIES'

export const SET_PRODUCTS_LIST = 'SET_PRODUCTS_LIST'
export const SET_PHARMACY_TO_COMPARE = 'SET_PHARMACY_TO_COMPARE'
export const UPDATE_COMPARING_LIST = 'UPDATE_COMPARING_LIST'
export const SPLICE_FROM_COMPARING_LIST = 'SPLICE_FROM_COMPARING_LIST'
export const SET_PRODUCT_TO_COMPARE = 'SET_PRODUCT_TO_COMPARE'