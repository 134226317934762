import React, {useEffect} from 'react';
import {connect} from "react-redux";
import {clearALLCheckedProduct, clearCheckedProduct, fetchProductReceived, setModalMessage,
  setShowImportPage, setDataImport, getProductList, fetchName, fetchNameReceived,
  logInFailed, setShowUndocking, setClearCheck, setClearArt, setClearPartner,
  setClearEnd, setUpdatePartner, toggleLoading} from "../../store/bundle/bundleActions";
import ImportFromFile from './ImportFromFile';
import Undocking from './Undocking';
import * as XLSX from "xlsx";
import {toWin1251} from "../../store/helpers/charset";
import {useSortableData} from "./hooks/useSortableData"
import {
  getAllForCheck,
  truncateCheck,
  truncateManual,
  deleteFromCheckTable,
  pushNewProduct,
  fetchNameRequest, fetchProductRequest,
} from "../../store/requests";

const mapStateToProps = (state) => ({
  loading: state.bundle.loading,
  selectName: state.bundle.selectName,
  selectArt: state.bundle.selectArt,
  NewProduct: state.bundle.NewProduct,
  showImportPage: state.bundle.showImportPage,
  NewNames: state.bundle.NewNames,
  newArts: state.bundle.newArts,
  showUndocking: state.bundle.showUndocking,
  clearCheck: state.bundle.clearCheck,
  idPartner: state.bundle.idPartner,
  updatePartner: state.bundle.updatePartner,
})
const mapDispatchToProps = ({
  clearALLCheckedProduct, clearCheckedProduct, fetchProductReceived, setModalMessage,
  setShowImportPage, setDataImport, getProductList, fetchName, fetchNameReceived,
  logInFailed, setShowUndocking, setClearCheck, setClearArt, setClearPartner,
  setClearEnd, setUpdatePartner, toggleLoading
})

const $ProductsForCheck = (props) => {

  // console.log('props.idPartner forCheck -- ', props.idPartner);
  useEffect(() => {
    // console.log('последний юзэфект - чек');
    if (props.clearCheck) {                    //признак очистки нижней части стартовой страницы
      // console.log('последний юзэфект - чек ВОШЛИ!!!');
      clearTwoTable().then(null)
    }
  }, [props.clearCheck])// eslint-disable-line

  const createObj = () => {
    const indexName = props.NewNames.findIndex(item => item.id === props.selectName)
    const indexArt = props.newArts.findIndex(item => item.Artcode === props.selectArt)
    return {
      id: props.NewNames[indexName].id || 0,
      Partner_ID: props.NewNames[indexName].Partner_ID || 0,
      SKU_ID_Partner: props.NewNames[indexName].SKU_ID_Partner || 0,
      SKU_ID_Morion: props.NewNames[indexName].SKU_ID_Morion || 0,
      SKU_ID_Optima: props.NewNames[indexName].SKU_ID_Optima || 0,
      SKU_ID_Badm: props.NewNames[indexName].SKU_ID_Badm || 0,
      SKU_Name: props.NewNames[indexName].SKU_Name || '',
      producer: props.NewNames[indexName].producer || '',
      vat: props.NewNames[indexName].vat || 0,
      Apteka_ID: props.NewNames[indexName].Apteka_ID || 0,
      barcode: props.NewNames[indexName].barcode || '',
      unit_koef: props.NewNames[indexName].unit_koef || 0,
      quantity: props.NewNames[indexName].quantity || 0,
      price: props.NewNames[indexName].price || 0,
      partibilityFactor: props.NewNames[indexName].partibilityFactor || 0,

      artCode: props.newArts[indexArt].Artcode,
      artName: props.newArts[indexArt].Name,
      artProducer: props.newArts[indexArt].Producer,
      artCode_t: props.newArts[indexArt].Artcode_t,
      artName_t: props.newArts[indexArt].Name_t,
      artProducer_t: props.newArts[indexArt].Producer_t
    }
  }


  const checkProduct = async () => {
    // console.log('props.selectName  ', props.selectName)
    // console.log('props.selectArt  ', props.selectArt)
    if (!props.selectName || !props.selectArt) {
      return props.setModalMessage('выберите позиции для сопоставления')
    }
    let obj = createObj();
    // console.log('obj посмотрим... :', obj);
    if (props.NewProduct.find(item => item.artCode === obj.artCode) || props.NewProduct.find(item => item.SKU_Name === obj.SKU_Name)) {
      return props.setModalMessage('такая позиция уже добавлена в таблицу сопоставления')
    }

    let fpResult = await fetchProductRequest(obj)
    props.fetchProductReceived(fpResult) //создание записи в таблице check

    props.clearCheckedProduct();                                            //очистка признака выделения строк для сопоставления
    let dataForCheck = await getAllForCheck({partner_id: props.idPartner});        //все записи из Check
    // console.log('Что там в таблице check? ', dataForCheck);
    props.getProductList(dataForCheck);

    props.fetchName() //все записи из Manual
    let result = await fetchNameRequest({partner_id: props.idPartner})
    if (result.error) {
      props.logInFailed(false)
      return
    }
    props.fetchNameReceived(result)

    // props.setClearPartner(true);                                         //Начало очистки со средней части страницы. По окончанию очистки отловим признак и продолжим
    await refreshTable();
  }

  const refreshTable = async () => {
    let dataForCheck = await getAllForCheck({partner_id: props.idPartner});        //все записи из Check
    // console.log('dataForCheck: ', dataForCheck);
    props.getProductList(dataForCheck);
    props.clearALLCheckedProduct();

    props.fetchName() //все записи из Manual
    let result = await fetchNameRequest({partner_id: props.idPartner})
    if (result.error) {
      props.logInFailed(false)
      return
    }
    props.fetchNameReceived(result)

  }

  const clearTwoTable = async () => {
    await truncateManual({partner_id: props.idPartner});             //очистим forManual
    await truncateCheck({partner_id: props.idPartner});              //очистим forCheck
    await refreshTable();               //выведем содержимое таблиц на главной странице
    props.setClearCheck(false);         //Сбрасываем признак очистки третьего раздела
    props.setClearEnd(true);            //признак окончания общей очистки
  }

  const newCheckProduct = async () => {
    if (props.NewProduct.length < 1) {
      return props.setModalMessage('нет сопоставленных данных')
    }

    for (let i = 0; i < props.NewProduct.length; i++) {
      // console.log('props.NewProduct[i] -> ', props.NewProduct[i]);
      let param = {
        id: props.NewProduct[i].id,
        Partner_ID: props.NewProduct[i].Partner_ID,
        SKU_ID_Partner: props.NewProduct[i].SKU_ID_Partner + '',
        SKU_Name: props.NewProduct[i].SKU_Name,
        producer: props.NewProduct[i].producer,
        vat: props.NewProduct[i].vat,
        Apteka_ID: props.NewProduct[i].Apteka_ID + '',
        artCode: props.NewProduct[i].artCode,
        deleteFromManual: props.idPartner,
        artCode_t: props.NewProduct[i].artCode_t,
        artName_t: props.NewProduct[i].artName_t,
        artProducer_t: props.NewProduct[i].artProducer_t
      }
      // console.log('param => ', param);
      // await pushNewProduct(param);
      // let createNewProduct =
      await pushNewProduct(param);
      // console.log('createNewProduct => ', createNewProduct);
      // await pushNewProduct(param);
      // console.log('Сохранили запись');
    }
    await clearTwoTable();
    // await refreshTable();
    props.setUpdatePartner(true);               //Обновить данные в средней части(партнеры, содержимое таблицы)
    props.setModalMessage('данные записаны в основную таблицу');

  }

  const deleteCollum = async (post) => {
    // let result =
    await deleteFromCheckTable({id: post.id});
    // console.log('итог удаления: ', result);
    let dataForCheck = await getAllForCheck({partner_id: props.idPartner});        //все записи из Check
    props.getProductList(dataForCheck);

    props.fetchName() //все записи из Manual
    let result = await fetchNameRequest({partner_id: props.idPartner})
    if (result.error) {
      props.logInFailed(false)
      return
    }
    props.fetchNameReceived(result)


    // props.fetchName({partner_id: props.idPartner});                                     //все записи из Manual
    props.toggleLoading(false)
  }

  // const showPageImport = () => {
  //     props.setShowImportPage(true);
  //     console.log('props.showImportPage : ', props.showImportPage);
  // }

  const uploadFile = async (event) => {
    event.preventDefault();
    // console.log('event.target.id   : ', event.target.id);
    if (event.target.files[0]) {
      await clearTwoTable();                        //очистить таблицы
      let file = event.target.files[0];             // взяли файл с инпута
      let reader = new FileReader();                // вызвали новый файл-ридер с конструктора
      reader.onload = async function (e) {  // накидываем обработчик
        let data = e.target.result;               // тут у нас сырые данные

        let rData = XLSX.read(data, {type: 'binary'});    // прогоняем через XLSX библиотеку

        // console.log(rData);

        let wsName = rData.SheetNames[0];          // взяли наименование первого листа(например Лист1)
        let ws = rData.Sheets[wsName];             // взяли данные по странице
        let json = XLSX.utils.sheet_to_json(ws, {header: 1});    // возврат данных в виде массива json-ов (header: 1 - экспорт массива массивов)
        // console.log('json: ', json);

        //--конвертирование текста в 1251 (для файлов txt и csv)
        if (file.name.indexOf('.csv') !== -1 || file.name.indexOf('.txt') !== -1) {
          let tmpArray = [];
          for (let i = 0; i < json.length; i++) {
            let tmpRow = [];
            // console.log('строка из файла: ', json[i]);
            tmpRow.push(0);                               //Первый элемент будет признаком обработки
            for (let j = 0; j < json[i].length; j++) {
              // console.log('UnicodeToWin1251(itemWord): ', toWin1251(json[i][j] + ''));
              tmpRow.push(toWin1251(json[i][j]));
            }
            tmpArray.push(tmpRow);
          }
          // console.log('tmpArray после : ', tmpArray);

          json = tmpArray;
        }
        //--конвертирование в 1251--END

        props.setDataImport(json);
        props.setShowImportPage(true);
        // let array = getFirstColumnArray(json)       // забрали инфу из первого столбца
        // console.log(array);
      }
      reader.readAsBinaryString(file);
      event.target.value = '';

    }
  }

  const {items, requestSort, sortConfig} = useSortableData(props.NewProduct || []);

  const getClassNamesFor = (name) => {
    return sortConfig && sortConfig.key === name ? sortConfig.direction + ' ' + name : name
  }

  const showUndock = () => {
    props.setShowUndocking(true);
  }

  const clearAllData = () => {
    // console.log('Начинаем сброс. арт в тру');
    props.setClearArt(true);
  }

  return (
    <div className='wrapper_products'>
      {props.showImportPage ? <ImportFromFile/> : null}
      {props.showUndocking ? <Undocking/> : null}
      <div className='button_block'>
        <button className='nice_button_absolute' onClick={clearAllData}>Очистить всё</button>
        <button className='nice_button nice_button_end' onClick={checkProduct}>Сопоставить</button>
        <button className='nice_button nice_button_end' onClick={newCheckProduct}>Подтвердить</button>
        {/*<button className='nice_button nice_button_end' onClick={showPageImport}>Импорт</button>*/}
        <input id="inputFile" className="inputFile" type="file" onChange={uploadFile}/>
        <label htmlFor="inputFile" className="labelFile">Импорт из файла</label>
        <button className='nice_button nice_button_end' onClick={showUndock}>Расстыковка</button>
      </div>
      <div className="table_wrapper_end">
        <table className='table'>
          <thead>
          <tr>
            <th onClick={() => requestSort('ficonDelete')}
                className={getClassNamesFor('ficonDelete')} />
            <th className='numpp'>№</th>
            <th onClick={() => requestSort('fArtcode')}
                className={getClassNamesFor('fArtcode')}>Арткод
            </th>
            <th onClick={() => requestSort('fName')}
                className={getClassNamesFor('fName')}>Название 911
            </th>
            <th onClick={() => requestSort('fProducer')}
                className={getClassNamesFor('fProducer')}>Производитель 911
            </th>
            <th onClick={() => requestSort('fSKU_ID_Partner')}
                className={getClassNamesFor('fSKU_ID_Partner')}>Код
            </th>
            <th onClick={() => requestSort('fSKU_Name')}
                className={getClassNamesFor('fSKU_Name')}>Название
            </th>
            <th onClick={() => requestSort('fproducer')}
                className={getClassNamesFor('fproducer')}>Производитель
            </th>
            <th onClick={() => requestSort('fquantity')}
                className={getClassNamesFor('fquantity')}>К-во в уп.
            </th>
            <th onClick={() => requestSort('fSKU_ID_Morion')}
                className={getClassNamesFor('fSKU_ID_Morion')}>Код морион
            </th>
            <th onClick={() => requestSort('fbarcode')}
                className={getClassNamesFor('fbarcode')}>Заводской ш-код
            </th>
            <th onClick={() => requestSort('fSKU_ID_Optima')}
                className={getClassNamesFor('fSKU_ID_Optima')}>Код Оптима
            </th>
            <th onClick={() => requestSort('fSKU_ID_Badm')}
                className={getClassNamesFor('fSKU_ID_Badm')}>Код БАДМ
            </th>
            <th onClick={() => requestSort('funit_koef')}
                className={getClassNamesFor('funit_koef')}>Крат ность
            </th>
            <th onClick={() => requestSort('fprice')}
                className={getClassNamesFor('fprice')}>Цена
            </th>
            <th onClick={() => requestSort('fPartner_ID')}
                className={getClassNamesFor('fPartner_ID')}>Код мерч.
            </th>
            <th className='partibilityFactor'>Ч.</th>
          </tr>
          </thead>
          <tbody>
          {items.length > 0 && items.map((post, index) => {
              return (
                <tr key={index} className={post.isSelectedArt ? "selectedRow" : 'nonSelectedRow'}>
                  <td className="ficonDelete"
                      title="удалить"
                      onClick={() => deleteCollum(post)}
                  />
                  <td className='numpp'>{index + 1}</td>
                  <td className='fArtcode'>{post.artCode_t || post.artCode}</td>
                  <td className='fName left'>{post.artName_t || post.artName}</td>
                  <td className="fProducer left">{post.artProducer_t || post.artProducer}</td>
                  <td className='fSKU_ID_Partner'>{post.SKU_ID_Partner}</td>
                  <td className='fSKU_Name left'>{post.SKU_Name}</td>
                  <td className="fproducer left">{post.producer || ''}</td>
                  <td className="fquantity">{post.quantity}</td>
                  <td className="fSKU_ID_Morion">{post.SKU_ID_Morion}</td>
                  <td className="fbarcode">{post.barcode}</td>
                  <td className="fSKU_ID_Optima">{post.SKU_ID_Optima}</td>
                  <td className="fSKU_ID_Badm">{post.SKU_ID_Badm}</td>
                  <td className="funit_koef">{post.unit_koef}</td>
                  <td className="fprice">{post.price ? post.price.toFixed(2) : ''}</td>
                  <td className="fPartner_ID">{post.Partner_ID}</td>
                  <td className="partibilityFactor">{post.partibilityFactor}</td>
                </tr>
              )
            }
          )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const ProductsForCheck = connect(mapStateToProps, mapDispatchToProps)($ProductsForCheck)

export default ProductsForCheck