import {request, generateOptions, generateURL} from "./helpers";
const API = process.env.REACT_APP_API_PATH

export const getOrdersList = async (params) => {
  let path = generateURL(`${API}/order/get/by/filter`, params)
  let options = generateOptions('GET')
  let response = await request(path, options)
  if (Array.isArray(response)) {
    response.forEach(item => {
      item.orderStatusText = item?.status?.name
    })
  }
  return new Promise(resolve => resolve(response))
}

export const getOrderDetails = async (id) => {
  let path = `${API}/order/product/get/order_id/${id}`
  let options = generateOptions('GET')
  let response = await request(path, options)
  return new Promise(resolve => resolve(response))
}

export const getOrderHistory = async (id) => {
  let path = `${API}/history/get/order_id/${id}`
  let options = generateOptions('GET')
  let response = await request(path, options)
  return new Promise(resolve => resolve(response))
}

export const getPharmaciesList = async () => {
  let path = `${API}/pharmacy/get/list`
  let options = generateOptions('GET')
  let response = await request(path, options)
  return new Promise(resolve => resolve(response))
}

export const getPharmacyInfo = async (id) => {
  let path = `${API}/pharmacy/get/pharmacy_id/${id}`
  let options = generateOptions('GET')
  let response = await request(path, options)
  // console.log(response)
  return new Promise(resolve => resolve(response))
}

export const updatePharmacyInfo = async (id, params) => {
  let path = `${API}/pharmacy/patch/pharmacy_id/${id}`
  let options = generateOptions('PATCH', params)
  let response = await request(path, options)
  return new Promise(resolve => resolve(response))
}

export const postNewPharmacy = async (params) => {
  let path = `${API}/pharmacy/post`
  let options = generateOptions('POST', params)
  let response = await request(path, options)
  return new Promise(resolve => resolve(response))
}

export const getOuterSystemsList = async () => {
  let path = `${API}/outer_system/get/list`
  let options = generateOptions('GET')
  let response = await request(path, options)
  return new Promise(resolve => resolve(response))
}

export const logInUser = async (params) => {
  let path = `${API}/authorization/login`
  let options = generateOptions('POST', params, undefined)
  let response = await request(path, options)
  return new Promise(resolve => resolve(response))
}

export const getSettingsList = async () => {
  let path = `${API}/user_setting/get/data`
  let options = generateOptions('GET')
  let response = await request(path, options)
  return new Promise(resolve => resolve(response))
}

export const getUserData = async (token) => {
  let path = `${API}/user_setting/get/personal`
  let options = generateOptions('GET', undefined, token)
  let response = await request(path, options)
  return new Promise(resolve => resolve(response))
}

export const updateUserSettings = async (params) => {
  let path = `${API}/user_setting/patch/personal`
  let options = generateOptions('PATCH', params)
  let response = await request(path, options)
  return new Promise(resolve => resolve(response))
}

/** SITE DATA REQUESTS */

export const getProductsByFilter = async (params) => {
  // artCode: int, name: str
  let url = `${API}/remains/get/by/filter`
  url = generateURL(url, params)
  let options = generateOptions('GET')
  let response = await request(url, options)
  return new Promise(resolve => resolve(response))
}

export const getProductsByCodesArray = async (params) => {
  // artCodes: arr
  let url = `${API}/product/get/by/art_codes`
  let options = generateOptions('POST', params)
  let response = await request(url, options)
  return new Promise(resolve => resolve(response))
}

export const getProductByPharmaciesArray = async (params) => {
  // pharmacies: arr
  let url = `${API}/product/get/by/art_codes`
  let options = generateOptions('POST', params)
  let response = await request(url, options)
  return new Promise(resolve => resolve(response))
}



/** BUNDLE (ART CODE COMPARE) */

export const fetchMorione = async (params) => {
  let url = `${API}/checkMorione`
  let options = generateOptions('POST', params)
  let response = await request(url, options)
  if (response?.error) return
  return new Promise(resolve => resolve(response))
}

export const fetchGetBarcode = async (params) => {
  let url = `${API}/checkBarcode`
  let options = generateOptions('POST', params)
  let response = await request(url, options)
  if (response?.error) return
  return new Promise(resolve => resolve(response))
}

export const fetchOptima = async (params) => {
  let url = `${API}/checkOptima`
  let options = generateOptions('POST', params)
  let response = await request(url, options)
  if (response?.error) return
  return new Promise(resolve => resolve(response))
}

export const fetchBadm = async (params) => {
  let url = `${API}/checkBadm`
  let options = generateOptions('POST', params)
  let response = await request(url, options)
  if (response?.error) return
  return new Promise(resolve => resolve(response))
}

export const insertForManual = async (params) => {
  let url = `${API}/insertForManual`
  let options = generateOptions('POST', params)
  let response = await request(url, options)
  if (response?.error) return
  return new Promise(resolve => resolve(response))
}

export const getAllForCheck = async (params) => {
  let url = `${API}/getForCheck`
  let options = generateOptions('POST', params)
  let response = await request(url, options)
  if (response?.error) return
  return new Promise(resolve => resolve(response))
}

export const truncateManual = async (params) => {
  let url = `${API}/truncateManual`
  let options = generateOptions('POST', params)
  let response = await request(url, options)
  if (response?.error) return
  return new Promise(resolve => resolve(response))
}

export const truncateCheck = async (params) => {
  let url = `${API}/truncateCheck`
  let options = generateOptions('POST', params)
  let response = await request(url, options)
  if (response?.error) return
  return new Promise(resolve => resolve(response))
}

export const fetchSearchExtProducts = async (params) => {
  let url = `${API}/searchUndock`
  let options = generateOptions('POST', params)
  let response = await request(url, options)
  if (response?.error) return
  return new Promise(resolve => resolve(response))
}

export const deleteExtProducts = async (params) => {
  let url = `${API}/undocking`
  let options = generateOptions('POST', params)
  let response = await request(url, options)
  if (response?.error) return
  return new Promise(resolve => resolve(response))
}

export const transferDataFromManual = async (params) => {
  let url = `${API}/transferData`
  let options = generateOptions('POST', params)
  let response = await request(url, options)
  if (response?.error) return
  return new Promise(resolve => resolve(response))
}

export const transferDataPartnerFromManual = async (params) => {
  // console.log(params)
  let url = `${API}/transferDataPartner`
  let options = generateOptions('POST', params)
  let response = await request(url, options)
  if (response?.error) return
  return new Promise(resolve => resolve(response))
}

export const updateManualSetHideRow = async (params) => {
  let url = `${API}/updateHideRow`
  let options = generateOptions('POST', params)
  let response = await request(url, options)
  if (response?.error) return
  return new Promise(resolve => resolve(response))
}

export const getRemains = async (params) => {
  let url = `${API}/getRemains`
  let options = generateOptions('POST', params)
  let response = await request(url, options)
  if (response?.error) return
  return new Promise(resolve => resolve(response))
}

export const transferDataFromProducts = async (params) => {
  let url = `${API}/transferDataProd`
  let options = generateOptions('POST', params)
  let response = await request(url, options)
  if (response?.error) return
  return new Promise(resolve => resolve(response))
}

export const isExistsProduct = async (params) => {
  let url = `${API}/isExistsProduct`
  let options = generateOptions('POST', params)
  let response = await request(url, options)
  if (response?.error) return
  return new Promise(resolve => resolve(response))
}

export const pushNewProduct = async (params) => {
  let url = `${API}/forMainTable`
  // console.log('Кудадада? => ', url)
  let options = generateOptions('POST', params)
  let response = await request(url, options)
  if (response?.error) return
  // console.log('создали запись: ', response)
  return new Promise(resolve => resolve(response))
}

/** удаление записей с временной таблицы по ID*/
export const deleteFromCheckTable = async (params) => {
  let url = `${API}/forCheckDelete`
  let options = generateOptions('POST', params)
  let response = await request(url, options)
  if (response?.error) return
  return new Promise(resolve => resolve(response))
}

export const fetchArtRequest = async (params) => {
  let url = `${API}/checkArtcode`
  let options = generateOptions('POST', params)
  let response = await request(url, options)
  if (response) {
    response = response.map(item => {
      return {
        Name: item.nameSKU911,
        Name_t: item.name_t,
        Artcode: item.artCode,
        Artcode_t: item.artCode_t,
        Producer: item.Producer,
        Producer_t: item.Producer_t,
        Brand: item.Brand,
        kratnost: item.kratnost,
        amount_upak: item.amount_upak,
        morione: item.morione,
        BarCode: item.BarCode,
        optima: item.kod_postav_optima,
        badm: item.kod_postav_badm,
        cena: item.cena,
        // ProducerID: item.ProducerID,
        isSelectedArt: false,
      }
      // let parsed = {}
      // parsed.Name = item.nameSKU911
      // parsed.Artcode = item.artCode
      // parsed.Producer = item.Producer
      // parsed.Brand = item.Brand
      // parsed.kratnost = item.kratnost
      // parsed.amount_upak = item.amount_upak
      // parsed.morione = item.morione
      // parsed.BarCode = item.BarCode
      // parsed.optima = item.kod_postav_optima
      // parsed.badm = item.kod_postav_badm
      // parsed.cena = item.cena
      // // parsed.ProducerID = item.ProducerID
      // parsed.isSelectedArt = false
      // return parsed
    })
  }
  return new Promise(resolve => resolve(response))
}

export const fetchNameRequest = async (params) => {
  let url = `${API}/getListName`
  let options = generateOptions('POST', params)
  let response = await request(url, options)
  if (!response?.error) {
    response = response.map(item => {
      return {
        id: item.id,
        Partner_ID: item.Partner_ID,
        SKU_ID_Partner: item.SKU_ID_Partner,
        SKU_Name: item.SKU_Name,
        producer: item.producer,
        vat: item.vat,
        SKU_ID_Morion: item.SKU_ID_Morion,
        SKU_ID_Optima: item.SKU_ID_Optima,
        SKU_ID_Badm: item.SKU_ID_Badm,
        Apteka_ID: item.Apteka_ID,
        unit_koef: item.unit_koef,
        quantity: item.quantity,
        price: item.price,
        barcode: item.barcode,
        dontNeedToMatch: item.dontNeedToMatch,
        partibilityFactor: item.partibilityFactor,
        isSelectedName: false,
        isFilter: true,
        isFilterProduct: true,
      }
      // let parsed = {}
      // parsed.id = item.id
      // parsed.Partner_ID = item.Partner_ID
      // parsed.SKU_ID_Partner = item.SKU_ID_Partner
      // parsed.SKU_Name = item.SKU_Name
      // parsed.producer = item.producer
      // parsed.vat = item.vat
      // parsed.SKU_ID_Morion = item.SKU_ID_Morion
      // parsed.SKU_ID_Optima = item.SKU_ID_Optima
      // parsed.SKU_ID_Badm = item.SKU_ID_Badm
      // parsed.Apteka_ID = item.Apteka_ID
      // parsed.unit_koef = item.unit_koef
      // parsed.quantity = item.quantity
      // parsed.price = item.price
      // parsed.barcode = item.barcode
      // parsed.dontNeedToMatch = item.dontNeedToMatch
      // parsed.partibilityFactor = item.partibilityFactor
      // parsed.isSelectedName = false
      // parsed.isFilter = true
      // parsed.isFilterProduct = true
      // return parsed
    })
  }
  return new Promise(resolve => resolve(response))
}

export const fetchProductRequest = async (params) => {
  let url = `${API}/forCheck`
  let options = generateOptions('POST', params)
  let response = await request(url, options)
  return new Promise(resolve => resolve(response))
}

export const getImportColumnSortRequest = async (params) => {
  let url = `${API}/importColumnSort`
  let options = generateOptions('POST', params)
  let response = await request(url, options)
  return new Promise(resolve => resolve(response))
}

export const updateColumnSortRequest = async (params) => {
  let url = `${API}/updateColumnSort`
  let options = generateOptions('POST', params)
  let response = await request(url, options)
  return new Promise(resolve => resolve(response))
}

export const getBrands = async () => {
  let url = `${API}/brand/get`
  let options = generateOptions('GET')
  let response = await request(url, options)
  return new Promise(resolve => resolve(response))
}

export const postBrand = async (params) => {
  let url = `${API}/brand/post`
  let options = generateOptions('POST', params)
  let response = await request(url, options)
  return new Promise(resolve => resolve(response))
}

export const patchBrand = async (id, params) => {
  let url = `${API}/brand/${id}/patch`
  let options = generateOptions('PATCH', params)
  let response = await request(url, options)
  return new Promise(resolve => resolve(response))
}

export const getCities = async () => {
  let url = `${API}/town/get`
  let options = generateOptions('GET')
  let response = await request(url, options)
  return new Promise(resolve => resolve(response))
}

