import React from "react"
import { connect } from "react-redux"
import OrderDetails from "./OrderDetails/OrderDetails";
import OrderHistory from "./OrderHistory/OrderHistory";

const mapStateToProps = (state) => ({
  orderInfoMode: state.main.orderInfoMode
})

const mapDispatchToProps = ({

})

const OrderInfo$ = (props) => {

  const mode = props.orderInfoMode

  return(
    <>
      { mode === 'details' && <OrderDetails /> }
      { mode === 'history' && <OrderHistory /> }
    </>
  )
}

const OrderInfo = connect(mapStateToProps, mapDispatchToProps)(OrderInfo$)

export default OrderInfo