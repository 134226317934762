import React from "react"
import { connect } from "react-redux"
import './RadioButton.css'

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = ({

})

const RadioButton$ = (props) => {

  const getButtonClass = () => {
    let className = "radioButtonContainer"
    if (props.status) className += " radioButtonOn"
    else className += " radioButtonOff"
    return className
  }

  return(
    <div className={getButtonClass()}>
      <div className="radioButtonTumbler" />
    </div>
  )
}

const RadioButton = connect(mapStateToProps, mapDispatchToProps)(RadioButton$)

export default RadioButton