import React, {useState} from "react"
import { connect } from "react-redux"
import { setFilter } from "../../../../../store/main/mainActions";

const mapStateToProps = (state) => ({
  filters: state.main.filters
})

const mapDispatchToProps = ({
  setFilter
})

const FilterSelect$ = (props) => {

  // console.log(props.list)

  const filterValue = props.filters[props.filterKey]
  const [selectFilter, setSelectFilter] = useState('')
  const [isSelectContainer, toggleSelectContainer] = useState(false)

  const selectFocusHandler = () => {
    toggleSelectContainer(true)
  }

  const selectBlurHandler = () => {
    setTimeout( () => { toggleSelectContainer(false) }, 100 )
  }

  const selectFilterHandler = (e) => {
    setSelectFilter(e.target.value)
  }

  const setSelectHandler = (item) => {
    return () => {
      props.setFilter(props.filterKey, item.id.toString())
      setSelectFilter('')
    }
  }

  const getSelectName = () => {
    return props.list.find( x => +x.id === +filterValue )?.[props.listItemName]
  }

  const getFilteredList = () => {
    let newList = []
    props.list.forEach( item => {
      if (item?.[props.listItemName]?.toLowerCase()?.includes(selectFilter.toLowerCase())) {
        newList.push(item)
      }
    } )
    return newList
  }

  return(
    <>
      <input
        value={filterValue ? getSelectName() : selectFilter}
        disabled={filterValue}
        onChange={selectFilterHandler}
        onFocus={selectFocusHandler}
        onBlur={selectBlurHandler}
        className="filterInput filterInputDisabled"
        type="text"
      />
      { isSelectContainer &&
      <div
        className="filtersSelectContainer d-flex fd-column"
        style={{top: `${props.height}px`, minWidth: `${props.width}px`}}
      >
        { props.list && getFilteredList().map( item => (
          <p
            className="filtersSelectItem hover"
            onClick={setSelectHandler(item)}
            key={item.id}
          >{item[props.listItemName]}</p>
        ) ) }
      </div>
      }
    </>
  )
}

const FilterSelect = connect(mapStateToProps, mapDispatchToProps)(FilterSelect$)

export default FilterSelect