import React, {useEffect} from 'react'
import {connect} from "react-redux"
import {fetchName, setImportColumnSort, getProductList, setIsMoving,
  setContainerPos, fetchNameReceived, logInFailed} from "../../store/bundle/bundleActions"
import {fetchNameRequest, getAllForCheck, getImportColumnSortRequest,
  transferDataPartnerFromManual} from "../../store/requests"
import SelectColumnImport from "./SelectColumnImport"
import Lens from "./Lens"
import ContentHeader from "../Content/ContentHeader/ContentHeader"
import ArtCodePartner from "./ArtCodePartner"
import CheckArt from "./CheckArt"
import ProductsforCheck from "./ProductsforCheck"
import './style/art.css'
import './style/table.css'
import './style/reset.css'
// import Loader from "./Loader"

const mapStateToProps = (state) => ({
  showSetColumnImport: state.bundle.showSetColumnImport,
  isMoving: state.bundle.isMoving,
  containerPos: state.bundle.containerPos,
  lensArtCodePartner: state.bundle.lensArtCodePartner,
  idPartner: state.bundle.idPartner,
  lang: state.main.lang,
  langBase: state.main.langBase,
})

const mapDispatchToProps = ({
  fetchName, setImportColumnSort, getProductList, setIsMoving, setContainerPos,
  fetchNameReceived, logInFailed
})

const Bundle$ = (props) => {

  const s = (key) => {
    return props.langBase[key][props.lang]
  }

  const getStartData = async () => {
    let dataForCheck = await getAllForCheck({partner_id: props.idPartner}) //все записи из Check
    props.getProductList(dataForCheck)

    props.fetchName() //все записи из Manual
    let result = await fetchNameRequest({partner_id: props.idPartner})
    if (result.error) {
      props.logInFailed(false)
      return
    }
    props.fetchNameReceived(result)

    let icsResult = await getImportColumnSortRequest({partner_id: props.idPartner})
    if (result?.error) {
      props.logInFailed(false)
      return
    }
    // console.log(icsResult)
    props.setImportColumnSort(icsResult)
  }

  const mouseUpHandler = () => {
    props.setIsMoving(false)
  }

  const mouseMoveHandler = (e) => {
    if (!props.isMoving) return
    let x = props.containerPos.x + e.movementX
    let y = props.containerPos.y + e.movementY
    props.setContainerPos({x, y})
  }

  const onLoad = async () => {
    let param1 = {
      idPartner: props.idPartner,
      showBlocked: 0
    }
    //перенесем из Manual в ManualImport позиции партнера. Возвращает к-во записей
    await transferDataPartnerFromManual(param1);
    await getStartData().then(null);
    // let idPartners = await getPartnerId();
    // console.log('idPartners : ', idPartners);
    // props.setListPartners(idPartners);
  }

  useEffect(() => {
    onLoad().then(null)
  }, [])  // eslint-disable-line

  // return (
  //   <div>
  //     <Loader />
  //   </div>
  // )

  return (
    <div className="wrapper d-flex fd-column">
      <ContentHeader>
        {s('Bundle')}
      </ContentHeader>
      <div
        className='wrapper_main'
        onMouseMove={props.isMoving ? mouseMoveHandler : null}
        onMouseUp={mouseUpHandler}
      >
        {props.showSetColumnImport && <SelectColumnImport/>}
        {props.lensArtCodePartner && <Lens/>}
        <div className='wrapper_block'>
          <div className="wrapper_check_art_main">
            <CheckArt/>
          </div>
          <div className="wrapper_partner_art_main">
            <ArtCodePartner/>
          </div>
        </div>
        <ProductsforCheck/>
      </div>
    </div>
  )

}

const Bundle = connect(mapStateToProps, mapDispatchToProps)(Bundle$)

export default Bundle