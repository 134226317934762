import React from "react"
import { connect } from "react-redux"
import './NavBar.css'

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = ({

})

const NavBar$ = (props) => {
  return(
    <div className={`navBar w-100p d-flex ${props.className || ''}`}>
      { props.children }
    </div>
  )
}

const NavBar = connect(mapStateToProps, mapDispatchToProps)(NavBar$)

export default NavBar