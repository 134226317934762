import React, {useState} from "react"
import {connect} from "react-redux"
import {setPage} from "../../../../store/main/mainActions";

const mapStateToProps = (state) => ({
  currentPage: state.main.page,
  pagesMap: state.main.pagesMap,
  lang: state.main.lang,
  langBase: state.main.langBase
})

const mapDispatchToProps = ({
  setPage
})

const SideBarButton$ = (props) => {

  const s = (key) => {
    return props.langBase[key][props.lang]
  }

  const [isDeployed, toggleDeployed] = useState(false)

  const subMenu = props.pagesMap[props.page]
  const isSubMenu = !!subMenu.length

  const selectHandler = (argPage) => {
    return () => {
      let page = props.page
      if (isSubMenu) page = argPage
      props.setPage(page)
    }
  }

  const getArrowClassName = () => {
    let className = 'sideBarButtonArrow'
    if (isDeployed) className += ' tf-rotate--45'
    // if (props.page === props.currentPage) className += ' sideBarButtonActive'
    return className
  }

  const buttonHandler = () => {
    if (!isSubMenu) selectHandler()()
    else toggleDeployed(!isDeployed)
  }

  return (
    <>
      <div onClick={buttonHandler} className={`sideBarButton ${props.page}Icon`}>
        {props.title}
        { isSubMenu &&
        <div className={getArrowClassName()} />
        }
      </div>
      { isDeployed &&
      <div className="sideBarSubContainer d-flex fd-column">
        {subMenu.map( (item) => (
          <p onClick={selectHandler(item.page)} key={item.key}>{s(item.key)}</p>
        ) )}
      </div>
      }
    </>
  )
}

const SideBarButton = connect(mapStateToProps, mapDispatchToProps)(SideBarButton$)

export default SideBarButton