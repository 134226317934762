import {
  FETCH_ART,
  FETCH_ART_RECEIVED,
  FETCH_NAME,
  FETCH_NAME_RECEIVED,
  SET_FILTER,
  SET_FILTER_ID,
  RESET_FILTER,
  SET_MODAL_MESSAGE,
  LOG_IN_FAILED,
  SET_SELECTED_NAME,
  SET_SELECTED_ART,
  FETCH_PRODUCT,
  FETCH_PRODUCT_RECEIVED,
  CLOSE_MODAL_PAGE,
  OPEN_MODAL_PAGE,
  CLEAR_CHECKED_PRODUCT,
  CLEAR_ALL_CHECKED_PRODUCT,
  SET_SHOW_IMPORT_PAGE,
  SET_DATA_IMPORT,
  SET_IMPORT_COLUMN_SORT,
  GET_PRODUCT_LIST,
  ART_CLEAR,
  SET_SHOW_SET_COLUMN_IMPORT,
  SET_IS_MOVING,
  SET_CONTAINER_POS,
  SET_SHOW_UNDOCKING,
  SET_EXT_PRODUCTS,
  SET_CLEAR_ART,
  SET_CLEAR_PARTNER,
  SET_CLEAR_CHECK,
  SET_CLEAR_END,
  SET_ID_PARTNER,
  SET_NAME_PRODUCT,
  SET_NAME_PRODUCT_TWO,
  SET_NAME_PARTNER,
  SET_NO_SHOW_BLOCKED_POSITION,
  SET_LENS_ARTCODE_PARTNER,
  SET_SELECT_AND_OR,
  SET_UPDATE_PARTNER,
  TOGGLE_LOADING,
} from "./bundleConstants";

let message

const initialState = {
  newArts: [],
  NewNames: [],
  NewProduct: [],
  ExtProducts: [],
  mainProduct: null,
  modalMessage: '',
  loading: null,
  error: null,
  selectName: null,
  selectArt: null,
  modalPage: false,
  showImportPage: false,
  dataImport: [],
  importColumnSort: [],
  showSetColumnImport: false,
  showUndocking: false,
  isMoving: false,
  containerPos: {x: 0, y: 0},
  clearArt: false,                   //очистить верхнюю треть страницы
  clearPartner: false,               //очистить среднюю часть
  clearCheck: false,                 //очистить нижнюю часть
  clearEnd: false,                   //признак окончания очистки
  updatePartner: false,              //обновить среднюю часть(список партнеров и данные таблицы)
  idPartner: 202,                    //TODO: при запуске программы присвоить переменной ID партнера или поменять везде в коде название переменной
  namePartner: '',                   //выбран ID партнера для выборки записей из таблицы [GATEWAY ].[prt].[ext_Products_forManual]
  nameProduct: '',                   //название продукта для выборки товаров из [GATEWAY ].[prt].[ext_Products_forManual]
  nameProductTwo: '',                //название продукта для выборки товаров из [GATEWAY ].[prt].[ext_Products_forManual]
  noShowBlockedPosition: true,       //не показывать заблокированные позиции по-умолчанию
  lensArtCodePartner: false,         //Развернуть среднюю часть на все окно
  selectAndOr: 'and',                //при поиске товаров связка искомых слов
}

const reducer = (state = initialState, action) => {
  switch (action.type) {

    case CLEAR_CHECKED_PRODUCT:
      return {...state, selectArt: null, selectName: null}

    case CLEAR_ALL_CHECKED_PRODUCT:
      return {...state, selectArt: null, selectName: null}

    case SET_MODAL_MESSAGE:
      return {...state, modalMessage: action.message}

    case FETCH_ART:
      return {...state, loading: true}

    case FETCH_ART_RECEIVED:
      // console.log(action.data)
      return {...state, newArts: action.data ? action.data : [], loading: false, modalMessage: message}

    case FETCH_NAME:
      return {...state, loading: true}

    case FETCH_NAME_RECEIVED:
      return {...state, NewNames: action.data || [], loading: false}

    case FETCH_PRODUCT:
      return {...state}

    case FETCH_PRODUCT_RECEIVED:
      return {...state, NewProduct: action.data}

    case GET_PRODUCT_LIST:
      return {...state, NewProduct: action.data || []}

    case CLOSE_MODAL_PAGE:
      return {...state, modalPage: false, modalMessage: message}

    case OPEN_MODAL_PAGE:
      return {...state, modalPage: true}

    case SET_SELECTED_NAME: {
      return {...state, selectName: action.id}
    }

    case SET_SELECTED_ART:
      return {...state, selectArt: action.id}

    case SET_FILTER:
      let stText = action.value
      let stOnline = state.NewNames.slice()
      for (let i = 0; i < stOnline.length; i++) {
        let result = false
        stOnline[i].isFilter = false
        if (stOnline[i].SKU_Name.toLowerCase().includes(stText.toLowerCase())) {
          result = true
        }
        if (result) {
          stOnline[i].isFilter = true
        }
      }
      return {...state, NewNames: stOnline}

    case SET_FILTER_ID:
      let stTextId = action.value
      let stOnlineId = state.NewNames.slice()
      for (let y = 0; y < stOnlineId.length; y++) {
        let result = false
        stOnlineId[y].isFilterProduct = false
        if (stOnlineId[y].Partner_ID.toString().includes(stTextId)) {
          result = true
        }
        if (result) {
          stOnlineId[y].isFilterProduct = true
        }
      }
      return {...state, NewNames: stOnlineId}

    case RESET_FILTER:
      let filterList = state.NewNames.slice()
      for (let i = 0; i < filterList.length; i++) {
        filterList[i].isFilter = true
        filterList[i].isFilterProduct = true
      }
      return {...state, NewNames: filterList}

    case LOG_IN_FAILED:
      return {
        ...state, error: action.data,
        modalMessage: 'Ошибка передачи данных', loading: false
      }

    case SET_SHOW_IMPORT_PAGE:
      return {...state, showImportPage: action.flag}

    case SET_DATA_IMPORT:
      return {...state, dataImport: action.data ? action.data : []}

    case SET_IMPORT_COLUMN_SORT:
      return {...state, importColumnSort: action.data ? action.data : []}

    case ART_CLEAR:
      return {...state, newArts: []}

    case SET_SHOW_SET_COLUMN_IMPORT:
      return {...state, showSetColumnImport: action.flag}

    case SET_SHOW_UNDOCKING:
      return {...state, showUndocking: action.flag}

    case SET_IS_MOVING:
      return {...state, isMoving: action.flag}

    case SET_CONTAINER_POS:
      return {...state, containerPos: action.value}

    case SET_EXT_PRODUCTS:
      // console.log(action.data)
      return {...state, ExtProducts: action.data ? action.data : []}

    case SET_CLEAR_ART:
      return {...state, clearArt: action.flag}

    case SET_CLEAR_END:
      return {...state, clearEnd: action.flag}

    case SET_CLEAR_PARTNER:
      return {...state, clearPartner: action.flag}

    case SET_CLEAR_CHECK:
      return {...state, clearCheck: action.flag}

    case SET_ID_PARTNER:
      return {...state, idPartner: action.value}

    case SET_NAME_PARTNER:
      return {...state, namePartner: action.value}

    case SET_NAME_PRODUCT:
      return {...state, nameProduct: action.value}

    case SET_NAME_PRODUCT_TWO:
      return {...state, nameProductTwo: action.value}

    case SET_NO_SHOW_BLOCKED_POSITION:
      return {...state, noShowBlockedPosition: action.flag}

    case SET_LENS_ARTCODE_PARTNER:
      return {...state, lensArtCodePartner: action.flag}

    case SET_SELECT_AND_OR:
      return {...state, selectAndOr: action.value}

    case SET_UPDATE_PARTNER:
      return {...state, updatePartner: action.value}

    case TOGGLE_LOADING: {
      return { ...state, loading: action.state }
    }

    default:
      return state
  }
}

export default reducer