import {
  LOG_IN_FAILED,
  FETCH_ART,
  FETCH_ART_RECEIVED,
  FETCH_NAME,
  FETCH_NAME_RECEIVED,
  SET_FILTER,
  RESET_FILTER,
  SET_MODAL_MESSAGE,
  SET_SELECTED_NAME,
  SET_SELECTED_ART,
  FETCH_PRODUCT_RECEIVED,
  CLOSE_MODAL_PAGE,
  CLEAR_CHECKED_PRODUCT,
  CLEAR_ALL_CHECKED_PRODUCT,
  SET_SHOW_IMPORT_PAGE,
  SET_DATA_IMPORT,
  SET_IMPORT_COLUMN_SORT,
  GET_PRODUCT_LIST,
  ART_CLEAR,
  SET_SHOW_SET_COLUMN_IMPORT,
  SET_IS_MOVING,
  SET_CONTAINER_POS,
  SET_SHOW_UNDOCKING,
  SET_EXT_PRODUCTS,
  SET_CLEAR_ART,
  SET_CLEAR_PARTNER,
  SET_CLEAR_CHECK,
  SET_CLEAR_END,
  SET_NAME_PRODUCT,
  SET_NAME_PRODUCT_TWO,
  SET_NAME_PARTNER,
  SET_NO_SHOW_BLOCKED_POSITION,
  SET_LENS_ARTCODE_PARTNER,
  SET_SELECT_AND_OR,
  SET_UPDATE_PARTNER,
  TOGGLE_LOADING,
} from "./bundleConstants";

export const logInFailed = (data) => ({
  type: LOG_IN_FAILED, data
})
export const setFilter = (value) => ({
  type: SET_FILTER, value
})
export const resetFilter = () => ({
  type: RESET_FILTER
})
export const fetchArt = (value) => ({
  type: FETCH_ART,value
})
export const fetchArtReceived = (data) => ({
  type: FETCH_ART_RECEIVED, data
})
export const fetchProductReceived = (data) => ({
  type: FETCH_PRODUCT_RECEIVED, data
})
export const getProductList = (data) => ({
  type: GET_PRODUCT_LIST, data
})
export const fetchName = (value) => ({
  type: FETCH_NAME,value
})
export const fetchNameReceived = (data) => ({
  type: FETCH_NAME_RECEIVED, data
})
export const setModalMessage = (message) => ({
  type: SET_MODAL_MESSAGE, message
})
export const setSelectedName = (id) => ({
  type: SET_SELECTED_NAME, id
})
export const setSelectedArt = (id) => ({
  type: SET_SELECTED_ART, id
})
export const closeModal = (value) => ({
  type: CLOSE_MODAL_PAGE, value
})
export const clearCheckedProduct = () => ({
  type: CLEAR_CHECKED_PRODUCT
})
export const clearALLCheckedProduct = () => ({
  type: CLEAR_ALL_CHECKED_PRODUCT
})
export const setShowImportPage = (flag) => ({
  type: SET_SHOW_IMPORT_PAGE, flag
})
export const setShowUndocking = (flag) => ({
  type: SET_SHOW_UNDOCKING, flag
})
export const setDataImport = (data) => ({
  type: SET_DATA_IMPORT, data
})
export const setImportColumnSort = (data) => ({
  type: SET_IMPORT_COLUMN_SORT, data
})
export const artClear = () => ({
  type: ART_CLEAR
})
export const setShowSetColumnImport = (flag) => ({
  type: SET_SHOW_SET_COLUMN_IMPORT, flag
})
export const setIsMoving = (flag) => ({
  type: SET_IS_MOVING, flag
})
export const setContainerPos = (value) => ({
  type: SET_CONTAINER_POS, value
})
export const setExtProducts = (data) => ({
  type: SET_EXT_PRODUCTS, data
})
export const setClearArt = (flag) => ({
  type: SET_CLEAR_ART, flag
})
export const setClearPartner = (flag) => ({
  type: SET_CLEAR_PARTNER, flag
})
export const setClearCheck = (flag) => ({
  type: SET_CLEAR_CHECK, flag
})
export const setClearEnd = (flag) => ({
  type: SET_CLEAR_END, flag
})
export const setNameProduct = (value) => ({
  type: SET_NAME_PRODUCT, value
})
export const setNameProductTwo = (value) => ({
  type: SET_NAME_PRODUCT_TWO, value
})
export const setNamePartner = (value) => ({
  type: SET_NAME_PARTNER, value
})
export const setNoShowBlockedPosition = (flag) => ({
  type: SET_NO_SHOW_BLOCKED_POSITION, flag
})
export const setLensArtCodePartner = (flag) => ({
  type: SET_LENS_ARTCODE_PARTNER, flag
})
export const setSelectAndOr = (value) => ({
  type: SET_SELECT_AND_OR, value
})
export const setUpdatePartner = (value) => ({
  type: SET_UPDATE_PARTNER, value
})

export const toggleLoading = (state) => ({
  type: TOGGLE_LOADING, state
})



