export const pharmacyKeys = {
  _default: {
    inputType: 'text',
    title: '',
    isRequired: false,
  },
  id: {
    isRequired: false,
    inputType: 'number',
    title: 'ID в системі ЛІКИ'
  },
  // partnerId: {
  //   inputType: 'number',
  //   title: 'ID Партнера',
  //   isRequired: false,
  // },
  pharmacyId: {
    inputType: 'text',
    title: 'ID Аптеки Партнера в системі ЛІКИ',
    isRequired: false,
  },
  pointCode: {
    inputType: 'text',
    title: 'ID Аптеки партнера',
    isRequired: false,
  },
  pharmacyNameRU: {
    inputType: 'text',
    title: 'Назва аптеки російскою',
    isRequired: true,
  },
  pharmacyNameUA: {
    inputType: 'text',
    title: 'Назва аптеки українською',
    isRequired: true,
  },
  aliasName: {
    inputType: 'text',
    title: 'Custom pharmacy name',
    isRequired: false,
  },
  brand: {
    inputType: 'brand',
    title: 'Бренд',
    isRequired: false
  },
  regionId: {
    inputType: 'city',
    title: 'Address (city)',
    isRequired: false,
  },
  addressStreetUa: {
    inputType: 'text',
    title: 'Вулиця (укр)',
    isRequired: false,
  },
  addressStreetRu: {
    inputType: 'text',
    title: 'Вулиця (рос)',
    isRequired: false,
  },
  // brandNameRU: {
  //   inputType: 'text',
  //   title: 'Назва бренду російскою',
  //   isRequired: false,
  // },
  // brandNameUA: {
  //   inputType: 'text',
  //   title: 'Назва бренду україньською',
  //   isRequired: false,
  // },
  addressRU: {
    inputType: 'text',
    title: 'Адреса аптеки російською',
    isRequired: true,
  },
  addressUA: {
    inputType: 'text',
    title: 'Адреса аптеки українською',
    isRequired: true,
  },
  lat: {
    inputType: 'number',
    title: 'Coordinates: latitude',
    isRequired: false,
  },
  lng: {
    inputType: 'number',
    title: 'Coordinates: longitude',
    isRequired: false,
  },
  // regionId: {
  //   inputType: 'number',
  //   title: 'Код міста',
  //   isRequired: false,
  // },
  operatingMode: {
    inputType: 'text',
    title: 'Schedule (JSON)',
    isRequired: false,
  },
  descr: {
    inputType: 'text',
    title: 'Коментар',
    isRequired: false,
  },
  orgId: {
    inputType: 'text',
    title: 'Organization ID',
    isRequired: false,
  },
  postCode: {
    inputType: 'number',
    title: 'Postcode',
    isRequired: false,
  },
  addressText: {
    inputType: 'text',
    title: 'Address (text)',
    isRequired: false,
  },
  addressRegion: {
    inputType: 'text',
    title: 'Address (area)',
    isRequired: false,
  },
  addressDistrict: {
    inputType: 'text',
    title: 'Address (district)',
    isRequired: false,
  },
  addressHouse: {
    inputType: 'text',
    title: 'Address (house)',
    isRequired: false,
  },
  addressStreet: {
    inputType: 'text',
    title: 'Address (street)',
    isRequired: false,
  },
  phones: {
    inputType: 'number',
    title: 'Pharmacy phone number',
    isRequired: false,
  },
  siteLikiId: {
    inputType: 'number',
    title: 'Site LIKI ID',
    isRequired: false,
  },
  userId: {
    inputType: 'number',
    title: 'User ID',
    isRequired: false,
  },
  workMode: {
    inputType: 'text',
    title: 'Режим роботи строкою',
    isRequired: false,
  },
  isActive: {
    inputType: 'select',
    title: 'Активувати аптеку',
    isRequired: false,
  },
  isBikeParking: {
    inputType: 'select',
    title: 'Bike parking',
    isRequired: false,
  },
  isDeliveryCourier: {
    inputType: 'select',
    title: 'Courier delivery',
    isRequired: false,
  },
  isDeliveryMeest: {
    inputType: 'select',
    title: 'Post delivery (Meest)',
    isRequired: false,
  },
  isDeliveryNP: {
    inputType: 'select',
    title: 'Post delivery (Nova Poshta)',
    isRequired: false,
  },
  isDeliveryUP: {
    inputType: 'select',
    title: 'Post delivery (Urk Poshta)',
    isRequired: false,
  },
  isOrderAvailable: {
    inputType: 'select',
    title: 'Order available',
    isRequired: false,
  },
  isParking: {
    inputType: 'select',
    title: 'Car parking',
    isRequired: false,
  },
  isPostmate: {
    inputType: 'select',
    title: 'Postmate',
    isRequired: false,
  },
  isTakeOrders: {
    inputType: 'select',
    title: 'Можливість приймати закази',
    isRequired: false,
  },
  remainsUpdatedAt: {
    inputType: 'date',
    title: 'Remains update date',
    isRequired: false,
  },
  insDate: {
    inputType: 'date',
    title: 'Insert date',
    isRequired: false,
  },
  updDate: {
    inputType: 'date',
    title: 'Update date',
    isRequired: false,
  },
  mon1: {
    inputType: 'time',
    title: 'Режим роботи: відкриття - понеділок',
    isRequired: false,
  },
  mon2: {
    inputType: 'time',
    title: 'Режим роботи: закриття - понеділок',
    isRequired: false,
  },
  tue1: {
    inputType: 'time',
    title: 'Режим роботи: відкриття - вівторок',
    isRequired: false,
  },
  tue2: {
    inputType: 'time',
    title: 'Режим роботи: закриття - вівторок',
    isRequired: false,
  },
  wen1: {
    inputType: 'time',
    title: 'Режим роботи: відкриття - середа',
    isRequired: false,
  },
  wen2: {
    inputType: 'time',
    title: 'Режим роботи: закриття - середа',
    isRequired: false,
  },
  thu1: {
    inputType: 'time',
    title: 'Режим роботи: відкриття - четвер',
    isRequired: false,
  },
  thu2: {
    inputType: 'time',
    title: 'Режим роботи: закриття - четвер',
    isRequired: false,
  },
  fri1: {
    inputType: 'time',
    title: 'Режим роботи: відкриття - п`ятниця',
    isRequired: false,
  },
  fri2: {
    inputType: 'time',
    title: 'Режим роботи: закриття - п`ятниця',
    isRequired: false,
  },
  sat1: {
    inputType: 'time',
    title: 'Режим роботи: відкриття - субота',
    isRequired: false,
  },
  sat2: {
    inputType: 'time',
    title: 'Режим роботи: закриття - субота',
    isRequired: false,
  },
  sun1: {
    inputType: 'time',
    title: 'Режим роботи: відкриття - неділя',
    isRequired: false,
  },
  sun2: {
    inputType: 'time',
    title: 'Режим роботи: закриття - неділя',
    isRequired: false,
  },


  // addressCityId: {
  //   inputType: 'text',
  //   title: 'Address (city id)',
  //   isRequired: false,
  // },
  // addressDistrictId: {
  //   inputType: 'text',
  //   title: 'Address (district id)',
  //   isRequired: false,
  // },
  // addressStreetId: {
  //   inputType: 'text',
  //   title: 'Address (street id)',
  //   isRequired: false,
  // },
  // addressAreaId: {
  //   inputType: 'text',
  //   title: 'Address (area id)',
  //   isRequired: false,
  // },
}