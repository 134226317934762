import React from "react"
import {connect} from "react-redux"
import { setActiveOrder } from "../../../store/main/mainActions";
import { orderTitles } from "../../../store/dictionaries";

const mapStateToProps = (state) => ({
  ordersList: state.main.ordersList,
  lang: state.main.lang,
  langBase: state.main.langBase
})

const mapDispatchToProps = ({
  setActiveOrder
})

const OrdersTable$ = (props) => {

  const s = (key) => {
    return props.langBase[key][props.lang]
  }

  const list = props.ordersList

  const orderInfoHandler = (order) => {
    return async () => {
      props.setActiveOrder(order.orderId)
    }
  }

  if (!list || list?.length === 0) return (
    <div className="tableWrapper bb-1 pl-12 pt-12">
      {
        list === undefined
        ?
        <p>{s('No entries selected')}</p>
        :
        list === null
        ?
        <p>{s('Loading')}</p>
        :
        list?.length === 0
        ?
        <p>{s('No records found for the specified filters')}</p>
        :
        <p>{s('API error')}</p>
      }
    </div>
  )

  return (
    <div className="tableWrapper bb-1">
      <table className="table">
        <thead>
        <tr>
          {orderTitles.map(title => (
            <th key={title.key}>{title.name || title.key}</th>
          ))}
        </tr>
        </thead>
        <tbody>
        {list.map(order => (
          <tr key={order.orderId} className="hover" onClick={orderInfoHandler(order)}>
            {orderTitles.map(title => (
              <td key={title.key}>{order[title.key]}</td>
            ))}
          </tr>
        ))}
        </tbody>
      </table>
    </div>
  )
}

const OrdersTable = connect(mapStateToProps, mapDispatchToProps)(OrdersTable$)

export default OrdersTable