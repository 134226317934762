import React, {useEffect, useRef} from 'react'
import {connect} from "react-redux"
import {
  setImportColumnSort, setShowSetColumnImport, setIsMoving, setContainerPos,
  logInFailed
} from "../../store/bundle/bundleActions"
import {updateColumnSortRequest} from "../../store/requests";


const mapStateToProps = (state) => ({
  importColumnSort: state.bundle.importColumnSort,
  showSetColumnImport: state.bundle.showSetColumnImport,
  isMoving: state.bundle.isMoving,
  containerPos: state.bundle.containerPos,
  idPartner: state.bundle.idPartner,
})

const mapDispatchToProps = ({
  setImportColumnSort, setShowSetColumnImport, setIsMoving, setContainerPos,
  logInFailed
})

const $SelectColumnImport = (props) => {

  const dataCellBlockImport = useRef(null)

  useEffect(() => {
    let outerHeight = window.outerHeight;       //общая высота окна
    let outerWidth = window.outerWidth;         //общая ширина окна
    props.setContainerPos({x: outerWidth / 2 - 100, y: outerHeight / 2 - 220});
  }, [])  // eslint-disable-line

  const changeNameColumn = async (e, index) => {
    const select = e.target;
    const selectElement = select.options[select.selectedIndex]
    let result = await updateColumnSortRequest({
      updateSort: index + 1, sortTo: selectElement.id, partner_id: props.idPartner
    })
    if (result?.error) {
      props.logInFailed(false)
      return
    }
    props.setImportColumnSort(result)
    // props.setIsValue(0);
  }
  const setVisibleSelect = () => {
    props.setShowSetColumnImport(false);
  }

  const mouseDownHandler = () => {
    props.setIsMoving(true)
  }

  // const mouseUpHandler = () => {
  //     props.setIsMoving(false)
  // }
  //
  // const mouseMoveHandler = (e) => {
  //     if (!props.isMoving) return
  //     let x = containerPos.x + e.movementX
  //     let y = containerPos.y + e.movementY
  //     console.log(x, y)
  //     setContainerPos({x, y})
  // }

  const generateStyle = () => {
    let actualTop = `${props.containerPos.y}px`
    let actualLeft = `${props.containerPos.x}px`
    return {top: actualTop, left: actualLeft}
  }

  return (
    <div
      ref={dataCellBlockImport}
      tabIndex={0}
      className="dataCellBlockImport"
      id="dataCellBlockImport"
      style={generateStyle()}
    >
      <div
        className="moveHeader"

        onMouseDown={mouseDownHandler}
      ><p>Нажми и перетяни</p></div>
      {props.importColumnSort.map((item1, index) => (
        <div className="rowOptionsSort" key={index}>
          <span className="spanNumberColumn">{index + 1}</span>
          <select className="selectedColumn" name="nameColumn1"
                  value={props.importColumnSort[index].name_column}
                  onChange={(e) => changeNameColumn(e, index)}
          >
            {props.importColumnSort.map((item1, index) => (
              <option key={index} id={item1.sort} itemID={item1.sort}
                      value={item1.name_column}>{item1.name_column}</option>
            ))}
          </select>
        </div>
      ))}
      <button className='nice_button' onClick={setVisibleSelect}>Выход</button>
    </div>
  );
};

const SelectColumnImport = connect(mapStateToProps, mapDispatchToProps)($SelectColumnImport)

export default SelectColumnImport;