import React, { useCallback, useState } from "react"
import { connect } from "react-redux"
import './FiltersBlock.css'
import { setFilter } from "../../../../store/main/mainActions";
import FilterInput from "./FilterInput/FilterInput";
import FilterSelect from "./FilterSelect/FilterSelect";

const mapStateToProps = (state) => ({
  filters: state.main.filters
})

const mapDispatchToProps = ({
  setFilter
})

const FiltersBlock$ = (props) => {

  const filters = props.filters

  const [height, setHeight] = useState(0)
  const [width, setWidth] = useState(0)

  const resetSelectHandler = () => {
    props.setFilter(props.filterKey, '')
  }

  const div = useCallback(node => {
    if (node !== null) {
      setHeight(node.getBoundingClientRect().height)
      setWidth(node.getBoundingClientRect().width)
    }
  }, [] )

  return(
    <div ref={div} className="filtersBlock d-flex fd-column ml-5">
      <p>{props.title}</p>
      { (filters[props.filterKey] && props.inputType !== 'date') &&
      <div onClick={resetSelectHandler} className="filtersSelectReset iconReset" />
      }
      { props.type === 'input' &&
      <FilterInput
        inputType={props.inputType}
        filterKey={props.filterKey}
      />
      }
      { props.type === 'select' &&
      <FilterSelect
        filterKey={props.filterKey}
        list={props.list}
        height={height}
        width={width}
        listItemName={props.listItemName}
      />
      }
    </div>
  )
}

const FiltersBlock = connect(mapStateToProps, mapDispatchToProps)(FiltersBlock$)

export default FiltersBlock