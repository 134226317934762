import React, {useState} from "react"
import './SearchInput.css'

const SearchInput = (props) => {

  const [input, setInput] = useState('')

  const inputHandler = (e) => {
    setInput(e.target.value)
  }

  const list = props.list.filter?.(item => {
    return item[props.itemKey].toLowerCase().includes(input.toLowerCase())
  })

  if (!props.selected) return (
    <div className="searchInputBlock d-flex mt-5">
      <input value={input} onChange={inputHandler} className="input" type="text"/>
      <div className="searchInputContainer d-flex fd-column">
        {list.map((item, index) => {
          if (index < 10) return (
            <p onClick={props.itemSetter(item)} key={item.pharmacyId}>{item[props.itemKey]}</p>
          )
          return null
        })}
      </div>
    </div>
  )

  return (
    <div className="d-flex mt-5">
      <div className="searchInputBlock input d-flex">
        <p onClick={props.itemSetter(null)} className="cursor-pointer">X</p>
        <p className="ml-12">{props.selected[props.itemKey]}</p>
      </div>
    </div>
  )
}

export default SearchInput