import React from "react"
import {connect} from "react-redux"
import NavBar from "../NavBar/NavBar";
import FiltersBlock from "./FiltersBlock/FiltersBlock";

const mapStateToProps = (state) => ({
  outerSystemsList: state.main.outerSystemsList,
  pharmaciesList: state.main.pharmaciesList,
  lang: state.main.lang,
  langBase: state.main.langBase
})

const mapDispatchToProps = ({})

const FiltersBar$ = (props) => {

  const s = (key) => {
    return props.langBase[key][props.lang]
  }

  return (
    <NavBar className="pv-2 min-h-ft">
      <FiltersBlock
        title={s('Reservation order')}
        type="input"
        filterKey="extId"
        inputType="number"
      />
      {/*<FiltersBlock*/}
      {/*  title={s('Phone')}*/}
      {/*  type="input"*/}
      {/*  filterKey="orderPhone"*/}
      {/*  inputType="number"*/}
      {/*/>*/}
      <FiltersBlock
        title={s('Period from')}
        type="input"
        filterKey="dtBegin"
        inputType="date"
      />
      <FiltersBlock
        title={s('Period by')}
        type="input"
        filterKey="dtEnd"
        inputType="date"
      />
      <FiltersBlock
        title={s('City')}
        type="input"
        filterKey="orderShipCity"
        inputType="text"
      />
      {/*<FiltersBlock*/}
      {/*  title={s('Order Status BP Stage')}*/}
      {/*  type="input"*/}
      {/*  inputType="text"*/}
      {/*/>*/}
      {/*<FiltersBlock*/}
      {/*  title={s('Art code')}*/}
      {/*  type="input"*/}
      {/*  inputType="number"*/}
      {/*/>*/}
      {/*<FiltersBlock*/}
      {/*  title={s('State')}*/}
      {/*  type="input"*/}
      {/*  inputType="text"*/}
      {/*/>*/}
      {/*<FiltersBlock*/}
      {/*  title={s('Pharmacy warehouse')}*/}
      {/*  type="select"*/}
      {/*  filterKey="pharmacyName"*/}
      {/*  list={props.pharmaciesList}*/}
      {/*  listItemName="pharmacyNameRU"*/}
      {/*/>*/}
      {/*<FiltersBlock*/}
      {/*  title={s('Delivery type')}*/}
      {/*  type="select"*/}
      {/*  filterKey="outerSystem"*/}
      {/*  list={props.outerSystemsList}*/}
      {/*  listItemName="name"*/}
      {/*/>*/}
      {/*<FiltersBlock*/}
      {/*  title={s('Client')}*/}
      {/*  type="input"*/}
      {/*  inputType="text"*/}
      {/*/>*/}
      {/*<FiltersBlock*/}
      {/*  title={s('Address')}*/}
      {/*  type="input"*/}
      {/*  inputType="text"*/}
      {/*/>*/}
    </NavBar>
  )
}

const FiltersBar = connect(mapStateToProps, mapDispatchToProps)(FiltersBar$)

export default FiltersBar