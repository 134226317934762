import React from "react"
import {connect} from "react-redux"
import { setProductToCompare } from "../../../store/main/mainActions";

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = ({
  setProductToCompare
})

const ProductsModal$ = (props) => {

  const closeHandler = () => {
    props.setProductToCompare(null)
  }

  return (
    <div onMouseDown={closeHandler} className="modalWrapper">
      <div onMouseDown={e => e.stopPropagation()} className="modalContainer">
        s
      </div>
    </div>
  )
}

const ProductsModal = connect(mapStateToProps, mapDispatchToProps)(ProductsModal$)

export default ProductsModal